  <div class="w-full min-h-screen">

    <div class="mt-2" *ngIf="!loading && campaign" @fadeSlide>
      <!-- Header Section -->
     
      <!-- Status Badge -->
      <div class="mb-8">
        <span class="inline-flex items-center px-4 py-2 rounded-full text-sm font-medium" 
              [ngClass]="{
                'bg-yellow-100 text-yellow-700': campaign.status === 'pending',
                'bg-green-100 text-green-700': campaign.status === 'approved',
                'bg-red-100 text-red-700': campaign.status === 'rejected'
              }">
          <i class="ri-checkbox-circle-line mr-2" *ngIf="campaign.status === 'approved'"></i>
          <i class="ri-time-line mr-2" *ngIf="campaign.status === 'pending'"></i>
          <i class="ri-close-circle-line mr-2" *ngIf="campaign.status === 'rejected'"></i>
          {{campaign.status | titlecase}}
          <span *ngIf="campaign.status === 'rejected'" class="ml-2 text-xs">
            - {{campaign.rejectionReason}}
          </span>
        </span>
      </div>

      <!-- Media Preview Card -->
      <div class="bg-white rounded-2xl shadow-sm overflow-hidden mb-8 group">
        <div class="relative aspect-video">
          <img *ngIf="campaign.mediaType === 'image'" 
               [src]="campaign.mediaUrl" 
               [alt]="campaign.campaignName"
               class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
               appImageNotFound>
          <video *ngIf="campaign.mediaType === 'video'" 
                 [src]="campaign.mediaUrl" 
                 class="w-full h-full object-cover"
                 controls></video>
          
          <div class="absolute top-4 left-4 px-4 py-2 rounded-full text-sm font-medium bg-black/50 text-white backdrop-blur-sm flex items-center gap-2">
            <i [class]="campaign.mediaType === 'image' ? 'ri-image-line' : 'ri-video-line'"></i>
            {{campaign.mediaType | titlecase}}
          </div>
        </div>
      </div>

      <!-- Info Grid -->
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        <!-- Campaign Details -->
        <div class="bg-white p-6 rounded-2xl shadow-sm hover:shadow-md transition-all duration-300">
          <div class="flex items-center gap-3 mb-6">
            <div class="w-10 h-10 rounded-full bg-[#eb7641]/10 flex items-center justify-center">
              <i class="ri-advertisement-line text-[#eb7641] text-xl"></i>
            </div>
            <h3 class="text-lg font-semibold">Campaign Info</h3>
          </div>
          
          <div class="space-y-4">
            <div class="p-4 bg-gray-50 rounded-xl">
              <p class="text-sm text-gray-500 mb-1">Campaign Name</p>
              <p class="font-medium text-gray-800">{{campaign.campaignName}}</p>
            </div>
            <div class="p-4 bg-gray-50 rounded-xl">
              <p class="text-sm text-gray-500 mb-1">Duration</p>
              <div class="flex items-center gap-2 text-gray-800">
                <i class="ri-calendar-line text-[#eb7641]"></i>
                <p class="font-medium">{{campaign.startDateTime | date}} - {{campaign.endDateTime | date}}</p>
              </div>
            </div>
            <div class="p-4 bg-gray-50 rounded-xl">
              <p class="text-sm text-gray-500 mb-1">Run Cycle Count</p>
              <p class="font-medium text-gray-800">{{campaign.runCycleCount}}</p>
            </div>
          </div>
        </div>

        <!-- Campaign Statistics -->
        <div class="bg-white p-6 rounded-2xl shadow-sm hover:shadow-md transition-all duration-300">
          <div class="flex items-center gap-3 mb-6">
            <div class="w-10 h-10 rounded-full bg-[#eb7641]/10 flex items-center justify-center">
              <i class="ri-bar-chart-line text-[#eb7641] text-xl"></i>
            </div>
            <h3 class="text-lg font-semibold">Campaign Statistics</h3>
          </div>

          <div class="space-y-4">
            <div class="p-4 bg-gray-50 rounded-xl">
              <p class="text-sm text-gray-500 mb-1">Total Views</p>
              <p class="font-medium text-gray-800">{{campaign.statistics?.views || 0}}</p>
            </div>
            <div class="p-4 bg-gray-50 rounded-xl">
              <p class="text-sm text-gray-500 mb-1">Total Impressions</p>
              <p class="font-medium text-gray-800">{{campaign.statistics?.impressions || 0}}</p>
            </div>
            <div class="p-4 bg-gray-50 rounded-xl">
              <p class="text-sm text-gray-500 mb-1">Campaign Performance</p>
              <div class="flex items-center gap-2">
                <span class="w-2 h-2 rounded-full" 
                      [ngClass]="{'bg-green-400': campaign.statistics?.performance > 70, 
                                'bg-yellow-400': campaign.statistics?.performance > 30 && campaign.statistics?.performance <= 70,
                                'bg-red-400': campaign.statistics?.performance <= 30}">
                </span>
                <p class="font-medium text-gray-800">{{campaign.statistics?.performance || 0}}%</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Location Information -->
        <div class="bg-white p-6 rounded-2xl shadow-sm hover:shadow-md transition-all duration-300">
          <div class="flex items-center gap-3 mb-6">
            <div class="w-10 h-10 rounded-full bg-[#eb7641]/10 flex items-center justify-center">
              <i class="ri-map-pin-line text-[#eb7641] text-xl"></i>
            </div>
            <h3 class="text-lg font-semibold">Location Details</h3>
          </div>

          <div class="space-y-4">
            <div *ngFor="let location of campaign.selectedLocations" 
                 class="p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors">
              <div class="flex items-center justify-between mb-3">
                <p class="font-medium text-gray-800">{{location.locationName}}</p>
                <span class="text-sm text-[#eb7641] font-medium">{{location.state}}</span>
              </div>
              <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
                <div class="text-sm">
                  <p class="text-gray-500">Latitude</p>
                  <p class="font-medium text-gray-700 break-all">{{location.latitude}}</p>
                </div>
                <div class="text-sm">
                  <p class="text-gray-500">Longitude</p>
                  <p class="font-medium text-gray-700 break-all">{{location.longitude}}</p>
                </div>
                <div class="text-sm">
                  <p class="text-gray-500">Radius</p>
                  <p class="font-medium text-gray-700">{{location.radius}}m</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Campaign Locations Map -->
      <div class="bg-white p-6 rounded-2xl shadow-sm mb-8">
        <div class="flex items-center justify-between mb-6">
          <div class="flex items-center gap-3">
            <div class="w-10 h-10 rounded-full bg-[#eb7641]/10 flex items-center justify-center">
              <i class="ri-map-2-line text-[#eb7641] text-xl"></i>
            </div>
            <h3 class="text-lg font-semibold">Campaign Location Map</h3>
          </div>
          <div class="flex items-center gap-4 text-sm">
            <div class="flex items-center gap-2">
              <span class="w-3 h-3 rounded-full bg-[#eb7641]"></span>
              <span>Selected Location</span>
            </div>
            <div class="flex items-center gap-2">
              <span class="w-3 h-3 rounded-full bg-black"></span>
              <span>Played Location</span>
            </div>
          </div>
        </div>
        
        <div class="w-full h-[400px] rounded-xl overflow-hidden">
          <google-map
            [center]="center"
            [zoom]="zoom"
            [options]="mapOptions"
            height="100%"
            width="100%"
            [@pulseAnimation]="locationUpdateCount">
            <!-- Selected Locations -->
            <map-marker
              *ngFor="let location of campaign.selectedLocations"
              [position]="getPosition(location)"
              [options]="selectedLocationMarkerOptions"
              [title]="location.locationName">
            </map-marker>
            
            <!-- Played Locations -->
            <map-marker
              *ngFor="let location of campaign.playedLocations"
              [@markerAnimation]
              [position]="{lat: location.latitude, lng: location.longitude}"
              [options]="getPlayedLocationMarkerOptions(location)"
              [title]="'Played at: ' + formatPlayedAt(location.playedAt)">
            </map-marker>
          </google-map>
        </div>
      </div>

      <!-- Add a floating notification for new locations -->
      <div *ngIf="recentlyUpdatedLocations.length > 0"
           class="fixed bottom-8 right-8 bg-[#eb7641] text-white px-6 py-4 rounded-lg shadow-lg"
           @fadeSlide>
        <div class="flex items-center gap-3">
          <i class="ri-map-pin-line text-xl"></i>
          <div>
            <p class="font-medium">New Location Update!</p>
            <p class="text-sm opacity-90">
              {{recentlyUpdatedLocations.length}} new location{{recentlyUpdatedLocations.length > 1 ? 's' : ''}} added
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>
