<div class="h-screen flex justify-center bg-white p-4 sm:p-8 md:p-16 lg:px-24">
    <div class="max-w-12xl w-full overflow-hidden flex">
        <div class="w-full lg:w-1/2 p-4 sm:p-6 md:p-8 lg:p-12 lg:px-24 flex flex-col justify-center">
            <div class="space-y-4 sm:space-y-6">
                <div>
                    <div onclick="window.location.href = '/'" class="mb-4 sm:mb-8">
                        <img src="assets/images/logo/logo.png" alt="Show Your Adz Logo" class="h-12 sm:h-15" appImageNotFound>
                    </div>
                    
                    <p class="mt-2 text-gray-600 text-sm sm:text-base">Welcome back! Please login to your account.</p>
                </div>

                <form class="space-y-4 sm:space-y-6" [formGroup]="loginForm">
                    <div class="space-y-4">
                        <div>
                            <div class="relative">
                                <input type="email" formControlName="email"
                                    class="w-full px-4 py-3 rounded-lg border border-gray-200 focus:border-[#eb7641] focus:ring-2 focus:ring-orange-200 transition-all duration-200 bg-gray-50"
                                    placeholder="Email address">
                                <div *ngIf="loginForm.get('email')?.invalid && (loginForm.get('email')?.touched || loginForm.get('email')?.dirty)"
                                    class="text-rose-500 text-sm mt-1 ml-1">
                                    Please enter a valid email address
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="relative">
                                <input [type]="showPassword ? 'text' : 'password'" formControlName="password"
                                    class="w-full px-4 py-3 rounded-lg border border-gray-200 focus:border-[#eb7641] focus:ring-2 focus:ring-orange-200 transition-all duration-200 bg-gray-50"
                                    placeholder="Password">
                                <button type="button" 
                                    (click)="togglePasswordVisibility()" 
                                    class="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700">
                                    <svg *ngIf="!showPassword" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                    <svg *ngIf="showPassword" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                    </svg>
                                </button>
                            </div>
                            <div *ngIf="loginForm.get('password')?.invalid && (loginForm.get('password')?.touched || loginForm.get('password')?.dirty)"
                                class="text-rose-500 text-sm mt-1 ml-1">
                                Password is required
                            </div>
                        </div>
                    </div>

                    <div class="flex items-center justify-between">
                        <label class="flex items-center space-x-2 cursor-pointer">
                            <input type="checkbox" class="w-4 h-4 rounded border-gray-300 text-[#eb7641] focus:ring-[#eb7641]">
                            <span class="text-sm text-gray-600">Remember me</span>
                        </label>
                        <a routerLink="/forgot-password" class="text-sm text-[#eb7641] hover:text-orange-800 transition-colors">
                            Forgot password?
                        </a>
                    </div>

                    
                    <button type="submit"
                    (click)="onSubmit()"
                    class="px-6 sm:px-8 py-2 bg-[#eb7641] text-white font1 text-base sm:text-lg tracking-wider
                    transition-all duration-300 shadow-lg hover:shadow-xl
                    hover:bg-transparent hover:text-[#eb7641] hover:scale-105 
                    border-2 border-[#eb7641] hover:border-[#eb7641] w-full"
                    [disabled]="loading">
                    <ng-container *ngIf="loading; else buttonText">
                        <app-spinner></app-spinner>
                    </ng-container>
                    <ng-template #buttonText>
                        Sign In
                    </ng-template>
                </button>

                    <div class="text-center">
                        <p class="text-gray-600">
                            Don't have an account? 
                            <a routerLink="/signup" class="text-[#eb7641] hover:text-orange-800 font-medium">Create account</a>
                        </p>
                    </div>

                    <div class="relative">
                        <div class="absolute inset-0 flex items-center">
                            <div class="w-full border-t border-gray-200"></div>
                        </div>
                        <div class="relative flex justify-center text-sm">
                            <span class="px-4 text-gray-500 bg-white">Or continue with</span>
                        </div>
                    </div>

                    <div class="flex justify-center">
                        <button (click)="loginWithGoogle()" class="flex items-center justify-center px-4 sm:px-6 py-2 sm:py-3 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors duration-200">
                            <img src="assets/images/google-icon.png" alt="Google" class="w-4 sm:w-5 h-4 sm:h-5 mr-2" appImageNotFound>
                            <span class="text-gray-600 text-sm sm:text-base">Google</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <div class="hidden lg:block bg-gradient-to-br from-orange-50 to-gray-50 w-1/2 relative">
            <img src="assets/images/login-bg.png" 
                 alt="ContactUs tailwind section" 
                 class="w-full h-full object-cover object-center"
                 appImageNotFound>
        </div>
    </div>

    <!-- Ban Modal -->
    <div *ngIf="showBanModal" class="fixed inset-0 bg-gray-900 bg-opacity-50 z-50 flex items-center justify-center">
        <div class="bg-white rounded-2xl shadow-xl max-w-md w-full mx-4 p-8 transform transition-all"
             [class.scale-100]="showBanModal"
             [class.opacity-100]="showBanModal">
            <div class="flex flex-col items-center text-center">
                <div class="w-16 h-16 bg-red-100 rounded-full flex items-center justify-center mb-6">
                    <i class="ri-forbid-line text-3xl text-red-600"></i>
                </div>
                
                <h3 class="text-2xl font-semibold text-gray-900 mb-4">
                    Account Banned
                </h3>
                
                <p class="text-gray-600 mb-6">
                    Your account has been banned from accessing the platform.
                    <ng-container *ngIf="banDetails?.reason">
                        <br><br>
                        <span class="font-medium">Reason:</span><br>
                        {{banDetails?.reason}}
                    </ng-container>
                    <ng-container *ngIf="banDetails?.bannedAt">
                        <br><br>
                        <span class="font-medium">Banned on:</span><br>
                        {{banDetails?.bannedAt | date:'medium'}}
                    </ng-container>
                </p>

                <button (click)="closeBanModal()" 
                        class="w-full px-6 py-3 bg-gray-100 text-gray-700 rounded-xl hover:bg-gray-200 transition-all duration-300">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>