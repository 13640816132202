    <!-- Main Chat Container -->
    <div class="flex h-[calc(100vh-150px)] bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100">
        <!-- Chat Threads Sidebar - hide on mobile by default -->
        <div class="w-full lg:w-[320px] bg-white border-r border-gray-100 flex flex-col"
             [ngClass]="{'hidden lg:flex': selectedThread}">
            <!-- Search Header -->
            <div class="px-4 sm:px-6 py-4 border-b border-gray-200">
                <div class="relative">
                    <input 
                        type="text" 
                        [(ngModel)]="searchTerm"
                        (input)="onSearchInput($any($event.target).value)"
                        placeholder="Search by name or email"
                        class="w-full px-3 sm:px-4 py-2 sm:py-2.5 bg-gray-50 rounded-xl pr-10 focus:outline-none focus:ring-2 focus:ring-[#eb7641]/20 text-sm"
                    >
                    <i class="ri-search-line absolute right-3 top-1/2 -translate-y-1/2 text-gray-400"></i>
                </div>
            </div>

            <!-- Thread List -->
            <div class="flex-1 overflow-y-auto thread-list">
                <!-- Threads Loading State -->
                <div *ngIf="loading && threads.length === 0" class="flex flex-col items-center justify-center h-full p-4 sm:p-6 text-center">
                    <div class="relative">
                        <div class="w-16 h-16 bg-gradient-to-br from-[#eb7641] to-[#d66937] rounded-xl flex items-center justify-center shadow-xl animate-pulse">
                            <i class="ri-chat-3-line text-white text-2xl"></i>
                        </div>
                        <div class="absolute -top-2 -right-2 w-6 h-6 bg-white rounded-full flex items-center justify-center shadow-lg animate-spin">
                            <div class="w-4 h-4 border-3 border-[#eb7641] border-t-transparent rounded-full"></div>
                        </div>
                    </div>
                    <div class="mt-4 space-y-2">
                        <h3 class="text-base font-bold text-gray-800">Loading conversations</h3>
                        <div class="flex justify-center space-x-2">
                            <div class="w-2 h-2 bg-[#eb7641] rounded-full animate-bounce"></div>
                            <div class="w-2 h-2 bg-[#eb7641] rounded-full animate-bounce" style="animation-delay: 0.2s"></div>
                            <div class="w-2 h-2 bg-[#eb7641] rounded-full animate-bounce" style="animation-delay: 0.4s"></div>
                        </div>
                    </div>
                </div>

                <!-- Empty State -->
                <div *ngIf="!loading && threads.length === 0" class="flex flex-col items-center justify-center h-full p-4 sm:p-6 text-center">
                    <div class="w-20 sm:w-24 h-20 sm:h-24 bg-gradient-to-br from-[#eb7641]/10 to-[#d66937]/10 rounded-full flex items-center justify-center mb-4">
                        <i class="ri-chat-3-line text-[#eb7641] text-2xl sm:text-3xl"></i>
                    </div>
                    <h3 class="text-base sm:text-lg font-semibold text-gray-900 mb-2">No Conversations Yet</h3>
                    <p class="text-xs sm:text-sm text-gray-500">There are no active support conversations at the moment.</p>
                </div>

                <!-- Thread Items -->
                <div class="space-y-1 p-3 sm:p-4">
                    <div *ngFor="let thread of threads"
                         (click)="reloadThread(thread)"
                         [ngClass]="{'bg-gradient-to-r from-[#eb7641]/20 to-[#d66937]/10': selectedThread?._id === thread._id}"
                         class="p-3 sm:p-4 rounded-xl hover:bg-gradient-to-r hover:from-gray-50 hover:to-gray-100 cursor-pointer transition-all duration-300 group">
                        <!-- Thread Content -->
                        <div class="flex items-center space-x-3 sm:space-x-4">
                            <!-- User Avatar -->
                            <div class="relative">
                                <div class="w-10 sm:w-12 h-10 sm:h-12 rounded-xl bg-gradient-to-br from-[#eb7641]/10 to-[#d66937]/10 flex items-center justify-center shadow-lg transform group-hover:scale-105 transition-all duration-300">
                                    <span class="text-[#eb7641] font-semibold text-sm sm:text-base">
                                        {{thread.userId.firstName.charAt(0).toUpperCase() + thread.userId.lastName.charAt(0).toUpperCase()}}
                                    </span>
                                </div>
                                <span *ngIf="thread.userStatus === 'online'" 
                                      class="absolute -bottom-1 -right-1 w-3 sm:w-4 h-3 sm:h-4 bg-green border-2 border-white rounded-full animate-pulse">
                                </span>
                            </div>

                            <!-- Thread Info -->
                            <div class="flex-1 min-w-0">
                                <div class="flex items-center justify-between">
                                    <h3 class="font-semibold text-gray-900 truncate group-hover:text-[#eb7641] transition-colors text-sm sm:text-base">
                                        {{thread.userId.firstName + ' ' + thread.userId.lastName}}
                                    </h3>
                                    <div class="flex items-center space-x-2">
                                        <span *ngIf="thread.unreadCount !== 0" 
                                              class="bg-gradient-to-r from-[#eb7641] to-[#d66937] text-white px-2 sm:px-2.5 py-0.5 sm:py-1 rounded-full text-xs font-medium shadow-sm">
                                            {{thread.unreadCount}}
                                        </span>
                                        <span class="text-xs font-medium text-gray-400 group-hover:text-[#eb7641]/70">
                                            {{thread.lastActivity | date:'shortTime'}}
                                        </span>
                                    </div>
                                </div>
                                <p class="text-xs sm:text-sm text-gray-500 truncate mt-1 sm:mt-1.5 group-hover:text-gray-600">
                                    {{thread.lastMessage?.message}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Load More Button -->
                <div *ngIf="hasMore" class="p-3 sm:p-4 text-center">
                    <button (click)="loadThreads(true)"
                            [disabled]="loading"
                            class="text-[#eb7641] hover:text-[#d66937] text-xs sm:text-sm font-medium">
                        Load More
                    </button>
                </div>
            </div>
        </div>

        <!-- Chat Area - show back button on mobile -->
        <div class="flex-1 flex flex-col bg-gray-50" [ngClass]="{'hidden lg:flex': !selectedThread}">
            <!-- Back button for mobile -->
            <button *ngIf="selectedThread" 
                    class="flex lg:hidden px-3 py-2 text-gray-600 hover:text-gray-900 flex items-center space-x-2 bg-white border-b"
                    (click)="selectedThread = null">
                <i class="ri-arrow-left-line"></i>
                <span class="text-sm">Back to conversations</span>
            </button>

            <!-- Enhanced Selected Thread Header -->
            <div *ngIf="selectedThread" class="px-3 sm:px-6 py-3 sm:py-4 bg-white border-b border-gray-100 shadow-sm">
                <div class="flex items-center justify-between">
                    <div class="flex items-center space-x-2 sm:space-x-4">
                        <div class="relative">
                            <div class="w-10 sm:w-12 h-10 sm:h-12 rounded-xl bg-gradient-to-br from-[#eb7641]/10 to-[#d66937]/10 flex items-center justify-center shadow-md">
                                <span class="text-[#eb7641] font-semibold text-sm sm:text-base">
                                    {{selectedThread.userId.firstName.charAt(0).toUpperCase() + selectedThread.userId.lastName.charAt(0).toUpperCase()}}
                                </span>
                            </div>
                            <span *ngIf="selectedThread.userStatus === 'online'" 
                                  class="absolute -bottom-0.5 -right-0.5 w-2.5 sm:w-3 h-2.5 sm:h-3 bg-green border-2 border-white rounded-full">
                            </span>
                        </div>
                        <div>
                            <h2 class="font-semibold text-gray-900 text-sm sm:text-base">
                                {{selectedThread.userId.firstName + ' ' + selectedThread.userId.lastName}}
                            </h2>
                            <p class="text-xs sm:text-sm text-gray-500 truncate max-w-[150px] sm:max-w-none">{{selectedThread.userId.email}}</p>
                        </div>
                    </div>

                    <!-- Status Badge and Actions -->
                    <div class="flex items-center space-x-2 sm:space-x-4">
                        <span [ngClass]="{
                            'bg-green-100 text-green border-green-200': selectedThread.userStatus === 'online',
                            'bg-gray-100 text-gray border-gray-200': selectedThread.userStatus === 'offline'
                        }" class="hidden sm:inline-block px-2 sm:px-3 py-1 sm:py-1.5 rounded-full text-xs font-medium border">
                            {{selectedThread.userStatus | titlecase}}
                        </span>
                        
                        <div class="flex items-center space-x-1 sm:space-x-2">
                            <button class="p-2 sm:p-2.5 hover:bg-gray-100 rounded-xl transition-all duration-300">
                                <i class="ri-user-line text-gray-500 text-lg sm:text-xl"></i>
                            </button>
                            <button class="p-2 sm:p-2.5 hover:bg-gray-100 rounded-xl transition-all duration-300">
                                <i class="ri-more-2-fill text-gray-500 text-lg sm:text-xl"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Empty Chat State - When no thread is selected -->
            <div *ngIf="!selectedThread && !loading" class="flex-1 flex flex-col items-center justify-center p-6 text-center">
                <div class="relative mb-6">
                    <div class="w-24 h-24 bg-gradient-to-br from-[#eb7641] to-[#d66937] rounded-2xl flex items-center justify-center shadow-xl transform hover:rotate-6 transition-all duration-300">
                        <i class="ri-customer-service-2-line text-white text-4xl"></i>
                    </div>
                    <div class="absolute -right-2 -bottom-2 w-8 h-8 bg-green-400 rounded-xl flex items-center justify-center shadow-lg">
                        <i class="ri-chat-smile-2-line text-white"></i>
                    </div>
                </div>
                <h3 class="text-2xl font-bold text-gray-800 mb-3">Select a conversation</h3>
                <p class="text-gray-500 max-w-md mb-6">Choose a conversation from the list to view messages and respond to customer inquiries.</p>
                <div class="flex items-center justify-center">
                    <div class="bg-[#eb7641]/10 p-4 rounded-xl flex items-center space-x-3">
                        <i class="ri-arrow-left-line text-[#eb7641] text-xl"></i>
                        <p class="text-gray-700">Select a thread to start chatting</p>
                    </div>
                </div>
            </div>

            <!-- Messages Area -->
            <div #messageContainer *ngIf="selectedThread" class="flex-1 overflow-y-auto p-3 sm:p-6 space-y-4 sm:space-y-6 message-container">
                <!-- Messages Loading State -->
                <div *ngIf="loading" class="flex flex-col items-center justify-center h-full space-y-8">
                    <div class="relative">
                        <div class="w-20 h-20 bg-gradient-to-br from-[#eb7641] to-[#d66937] rounded-2xl flex items-center justify-center shadow-xl animate-pulse">
                            <i class="ri-chat-3-line text-white text-3xl"></i>
                        </div>
                        <div class="absolute -top-3 -right-3 w-8 h-8 bg-white rounded-full flex items-center justify-center shadow-lg animate-spin">
                            <div class="w-5 h-5 border-4 border-[#eb7641] border-t-transparent rounded-full"></div>
                        </div>
                    </div>
                    <div class="text-center space-y-3">
                        <h3 class="text-xl font-bold text-gray-800">Loading conversation</h3>
                        <div class="flex justify-center space-x-2">
                            <div class="w-3 h-3 bg-[#eb7641] rounded-full animate-bounce"></div>
                            <div class="w-3 h-3 bg-[#eb7641] rounded-full animate-bounce" style="animation-delay: 0.2s"></div>
                            <div class="w-3 h-3 bg-[#eb7641] rounded-full animate-bounce" style="animation-delay: 0.4s"></div>
                        </div>
                    </div>
                </div>

                <!-- Empty Messages State -->
                <div *ngIf="!loading && selectedThread && messages.length === 0" class="flex flex-col items-center justify-center h-full space-y-6">
                    <div class="w-20 h-20 bg-gradient-to-br from-[#eb7641]/20 to-[#d66937]/20 rounded-full flex items-center justify-center">
                        <i class="ri-chat-new-line text-[#eb7641] text-3xl"></i>
                    </div>
                    <div class="text-center">
                        <h3 class="text-xl font-bold text-gray-800 mb-2">No messages yet</h3>
                        <p class="text-gray-500 max-w-md">Start the conversation by sending a message to this customer.</p>
                    </div>
                </div>

                <!-- Message bubbles -->
                <ng-container *ngIf="!loading && selectedThread && messages.length > 0">
                    <ng-container *ngFor="let message of messages">
                        <div class="flex" [ngClass]="{'justify-end': message.isAdmin}">
                            <div class="flex flex-col max-w-[85%] sm:max-w-[70%] space-y-1 sm:space-y-2">
                                <div class="flex items-start space-x-2 sm:space-x-3" 
                                     [ngClass]="{'flex-row-reverse': message.isAdmin, 'space-x-reverse': message.isAdmin}">
                                    <div [ngClass]="{
                                        'bg-gradient-to-br from-[#eb7641] to-[#d66937] text-white shadow-lg': message.isAdmin,
                                        'bg-white text-gray-800 shadow-md border border-gray-100': !message.isAdmin
                                    }" class="p-3 sm:p-4 rounded-2xl text-sm sm:text-base">
                                        <p class="leading-relaxed">{{message.message}}</p>
                                    </div>
                                </div>
                                <div class="flex items-center space-x-2" [ngClass]="{'justify-end': message.isAdmin}">
                                    <span class="text-[10px] sm:text-xs text-gray-400">{{message.createdAt | date:'shortTime'}}</span>
                                    <ng-container *ngIf="message.isAdmin">
                                        <!-- Failed Status -->
                                        <i *ngIf="message.status === 'failed'" 
                                           class="ri-error-warning-line text-red-500 text-xs sm:text-sm" 
                                           title="Failed to send"></i>
                                        <!-- Sent Status (Single Tick) -->
                                        <i *ngIf="message.status === 'sent'" 
                                           class="ri-check-line text-gray-400 text-xs sm:text-sm"></i>
                                        <!-- Delivered Status (Double Tick) -->
                                        <i *ngIf="message.status === 'delivered'" 
                                           class="ri-check-double-line text-gray-400 text-xs sm:text-sm"></i>
                                        <!-- Read Status (Colored Double Tick) -->
                                        <i *ngIf="message.status === 'read'" 
                                           class="ri-check-double-line text-black text-xs sm:text-sm"></i>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <!-- Message Input -->
            <div *ngIf="selectedThread" class="px-3 sm:px-6 py-3 sm:py-4 bg-white border-t border-gray-100">
                <div class="flex items-end space-x-2 sm:space-x-4">
                    <div class="flex-1 bg-gray-50 rounded-xl border border-gray-200 hover:border-[#eb7641]/30 transition-all duration-300">
                        <textarea
                            [(ngModel)]="newMessage"
                            (keydown.enter)="$event.preventDefault(); sendMessage()"
                            placeholder="Type your message..."
                            rows="1"
                            class="w-full bg-transparent px-3 sm:px-4 py-2 sm:py-3 focus:outline-none resize-none rounded-xl text-sm"
                        ></textarea>
                    </div>
                    
                    <button (click)="sendMessage()"
                            [disabled]="!newMessage.trim() || loading"
                            class="p-2.5 sm:p-3 bg-gradient-to-r from-[#eb7641] to-[#d66937] text-white rounded-xl hover:shadow-lg transition-all duration-300 disabled:opacity-50 disabled:hover:shadow-none">
                        <i class="ri-send-plane-fill text-base sm:text-lg"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>