<!-- Right Area -->
  <div class="w-full min-h-screen">
   
    <!-- Loading Spinner -->
    <app-spinner *ngIf="loading"></app-spinner>

    <div class="flex justify-end mb-6">
      <button 
        routerLink="/dashboard/create-campaign"
        class="px-6 py-3 bg-[#eb7641] text-white rounded-xl hover:bg-[#d66937] active:bg-[#c25e32] transition-all duration-200 flex items-center gap-2 shadow-lg shadow-[#eb7641]/20">
        <i class="ri-add-line text-lg"></i>
        Create Campaign
      </button>
    </div>
    <!-- Campaigns Grid -->
    <div *ngIf="!loading && filterCampaigns().length > 0" 
         class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <!-- Campaign Card -->
      <div *ngFor="let campaign of filterCampaigns()" 
           class="group bg-white rounded-2xl shadow-sm hover:shadow-xl transition-all duration-300 overflow-hidden">
        <!-- Media Preview with Type Indicator -->
        <div class="relative aspect-video">
          <img *ngIf="campaign.mediaType === 'image'" 
               [src]="campaign.mediaUrl" 
               [alt]="campaign.campaignName"
               class="w-full h-full object-cover"
               appImageNotFound>
          <video *ngIf="campaign.mediaType === 'video'" 
                 [src]="campaign.mediaUrl" 
                 class="w-full h-full object-cover"
                 controls></video>
          
          <!-- Media Type Badge -->
          <div class="absolute top-4 left-4 px-3 py-1.5 rounded-full text-xs font-medium bg-black/50 text-white backdrop-blur-sm flex items-center gap-1.5">
            <i [class.ri-image-line]="campaign.mediaType === 'image'"
               [class.ri-video-line]="campaign.mediaType === 'video'"></i>
            {{campaign.mediaType | titlecase}}
          </div>

          <!-- Status Badge -->
          <div [class]="'absolute top-4 right-4 px-3 py-1.5 rounded-full text-xs font-medium backdrop-blur-sm ' + getStatusClass(campaign.status)">
            {{campaign.status | titlecase}}
          </div>
        </div>

        <!-- Campaign Info -->
        <div class="p-5">
          <!-- Campaign Name -->
          <div class="flex items-start justify-between mb-4">
            <h3 class="font-bold text-lg text-gray-800 line-clamp-1">{{campaign.campaignName}}</h3>
            <span class="bg-[#eb7641]/10 text-[#eb7641] px-2 py-1 rounded-lg text-sm font-medium">
              <span class="inline-block" [@pulseAnimation]="campaign.runCycleCount">
                {{campaign.runCycleCount || 0}}
              </span> Cycles
            </span>
          </div>

          <!-- Stats Grid -->
          <div class="grid grid-cols-2 gap-3 mb-4">
            <!-- Date Range -->
            <div class="bg-gray-50 p-3 rounded-xl">
              <div class="flex items-center gap-2 text-gray-500 mb-1.5 text-xs">
                <i class="ri-calendar-line"></i>
                <span>Campaign Period</span>
              </div>
              <div class="text-sm font-medium text-gray-800">
                <div>From: {{campaign.startDateTime | date:'MMM d, y'}}</div>
                <div>To: {{campaign.endDateTime | date:'MMM d, y'}}</div>
              </div>
            </div>

            <!-- Locations -->
            <div class="bg-gray-50 p-3 rounded-xl">
              <div class="flex items-center gap-2 text-gray-500 mb-1.5 text-xs">
                <i class="ri-map-pin-line"></i>
                <span>Locations</span>
              </div>
              <div class="text-sm font-medium text-gray-800">
                <span>{{campaign.selectedLocations.length}}</span>
                <span class="text-sm ml-1">total</span>
              </div>
              <div class="text-xs  mt-1">
                {{campaign.selectedLocations | slice:0:2 | map:'state' | join:', '}}
              </div>
            </div>
          </div>

          <!-- Action Buttons -->
          <div class="flex justify-between items-center pt-4 border-t border-gray-100">
            <button 
              [routerLink]="['/dashboard/campaign-details', campaign._id]"
              class="px-4 py-2 bg-[#eb7641]/10 text-[#eb7641] rounded-lg hover:bg-[#eb7641] hover:text-white transition-all duration-300 text-sm font-medium">
              <i class="ri-eye-line mr-1.5"></i>View Details
            </button>
            <div class="flex items-center gap-2">
              <button 
                *ngIf="campaign.status === 'pending' || campaign.status === 'rejected'"
                [routerLink]="['/dashboard/update-campaign', campaign._id]"
                class="p-2 hover:bg-gray-100 rounded-lg transition-colors">
                <i class="ri-edit-line text-gray-600 hover:text-[#eb7641]"></i>
              </button>
              <button 
                *ngIf="campaign.status === 'pending' || campaign.status === 'rejected'"
                (click)="deleteCampaign(campaign._id)"
                class="p-2 hover:bg-red-50 rounded-lg transition-colors">
                <i class="ri-delete-bin-line text-gray-600 hover:text-red-500"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Empty State -->
    <div *ngIf="!loading && campaigns.length === 0" 
         class="bg-white rounded-2xl shadow-sm p-12 text-center">
      <div class="max-w-md mx-auto">
        <div class="w-20 h-20 bg-[#eb7641]/10 rounded-2xl flex items-center justify-center mx-auto mb-6">
          <i class="ri-advertisement-line text-[#eb7641] text-3xl"></i>
        </div>
        <h3 class="text-2xl font-bold text-gray-800 mb-3">No Campaigns Yet</h3>
        <p class="text-gray-500 mb-8">Create your first campaign to start reaching your audience.</p>
        <button 
          routerLink="/dashboard/create-campaign"
          class="px-6 py-3 bg-[#eb7641] text-white rounded-xl hover:bg-[#d66937] transition-all duration-200 flex items-center gap-2 mx-auto">
          <i class="ri-add-line"></i>
          Create Your First Campaign
        </button>
      </div>
    </div>
  </div>
