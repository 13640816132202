<!-- Left Area -->

<aside *ngIf="isSidebarOpen" class="fixed top-0 left-0 z-40 w-64 h-screen transition-transform sm:translate-x-0" [ngClass]="{'translate-x-0': isSidebarOpen, '-translate-x-full': !isSidebarOpen}">
  <div class="h-full flex flex-col justify-between px-3 sm:px-4 py-4 sm:py-5 overflow-y-auto bg-white">
      <ul class="space-y-2 font-normal text-xs sm:text-sm">
          <div class="flex justify-center items-center w-full pb-4 sm:pb-6">
              <img class="h-10 sm:h-12 md:h-15"
      src="assets/images/logo/logo.png"
      alt=""  appImageNotFound/>
          </div>
          <!-- user meenus -->
          <li *ngxPermissionsOnly="['user']">
              <a routerLink="/dashboard" [ngClass]="{'text-primary bg-[#eb764121]': currentRoute=='/dashboard'}"
                  class="flex items-center p-2 sm:p-3 rounded-lg hover:bg-[#eb764121] group">
                  <svg [ngClass]=" currentRoute=='/dashboard'?'text-primary':'text-gray-500'"
                      class="w-4 h-4 transition duration-75 group-hover:text-primary"
                      aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
                      <path
                          d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                      <path
                          d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                  </svg>
                  <span [ngClass]="currentRoute=='/dashboard'?'text-primary':'text-gray-500'"
                      class="ms-3 group-hover:text-primary group-hover:translate-x-1 transition-all duration-300">Dashboard</span>
              </a>
          </li>
        
          <!-- Subscription Link -->
          <li *ngxPermissionsOnly="['user']">
              <a routerLink="/dashboard/subscriptions"
                  [ngClass]="{'text-primary bg-[#eb764121]': currentRoute === '/dashboard/subscriptions'}"
                  class="flex items-center p-2 sm:p-3 rounded-lg hover:bg-[#eb764121] group">
                  <svg [ngClass]="currentRoute=='/dashboard/subscriptions'?'text-primary':'text-gray-500'"
                      class="w-4 h-4 transition duration-75 group-hover:text-primary"
                      aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                      <!-- Updated SVG for subscriptions -->
                      <path d="M12 1v22M1 12h22" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                      <path d="M17 6h6v6h-6zM1 6h6v6H1zM1 15h6v6H1zM17 15h6v6h-6z" stroke="currentColor"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <span [ngClass]="currentRoute=='/dashboard/subscriptions'?'text-primary':'text-gray-500'"
                      class="ms-3 transition-all duration-300  group-hover:text-primary">Subscriptions</span>
              </a>
          </li>
          <li *ngxPermissionsOnly="['user']">
            <a routerLink="/dashboard/invoices"
                [ngClass]="{'text-primary bg-[#eb764121]': currentRoute === '/dashboard/invoices'}"
                class="flex items-center p-2 sm:p-3 rounded-lg hover:bg-[#eb764121] group">
                <svg [ngClass]="currentRoute=='/dashboard/invoices'?'text-primary':'text-gray-500'"
                    class="w-4 h-4 transition duration-75 group-hover:text-primary"
                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M6 2a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8l-6-6H6zm0 2h7v5h5v11H6V4zm2 8v2h8v-2H8zm0 4v2h8v-2H8z"/>
                </svg>
                <span [ngClass]="currentRoute=='/dashboard/invoices'?'text-primary':'text-gray-500'"
                    class="ms-3 transition-all duration-300  group-hover:text-primary">Invoices</span>
            </a>
        </li>
        <li *ngxPermissionsOnly="['user']">
            <button type="button"
                class="flex items-center w-full p-2 sm:p-3 text-base text-[#64748B] rounded-lg group hover:bg-[#eb764121]"
                (click)="selectItem('manage-locations')">
                <svg [ngClass]="currentRoute === '/location' ? 'text-primary' : 'text-gray-500'"
                    class="w-4 h-4 transition duration-75 group-hover:text-primary"
                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path d="M3 4c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2v7c0 2.5-2 4-4 4H7c-2 0-4-1.5-4-4V4z"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M3 14v7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1v-7" stroke="currentColor" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9 22V12h6v10" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
                <span
                    class="flex-1 ms-3 text-left text-xs sm:text-sm whitespace-nowrap group-hover:text-primary group-hover:translate-x-1 transition-all duration-300">Manage
                    Locations</span>
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m1 1 4 4 4-4" />
                </svg>
            </button>
            <ul class="py-1 sm:py-2 space-y-1 sm:space-y-2" [ngClass]="{'hidden': selectedItem !== 'manage-locations'}">
                <li *ngxPermissionsOnly="['user']">
                    <a routerLink="/dashboard/location"
                        class="flex items-center w-full p-2 sm:p-3 text-[#64748B] rounded-lg pl-11 group hover:bg-[#eb764121]">View
                        Locations</a>
                </li>
                <li *ngxPermissionsOnly="['user']">
                    <a routerLink="/dashboard/addlocation"
                        class="flex items-center w-full p-2 sm:p-3 text-[#64748B] rounded-lg pl-11 group hover:bg-[#eb764121]">Add
                        Locations</a>
                </li>
            </ul>
        </li>

        <li *ngxPermissionsOnly="['user']">
            <a routerLink="/dashboard/chat"
                [ngClass]="{'text-primary bg-[#eb764121]': currentRoute === '/dashboard/chat'}"
                class="flex items-center p-2 sm:p-3 rounded-lg hover:bg-[#eb764121] group">
                <svg [ngClass]="currentRoute=='/dashboard/chat'?'text-primary':'text-gray-500'"
                    class="w-4 h-4 transition duration-75 group-hover:text-primary"
                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2z"/>
                </svg>
                <span [ngClass]="currentRoute=='/dashboard/chat'?'text-primary':'text-gray-500'"
                    class="ms-3 transition-all duration-300 group-hover:text-primary">Chat</span>
            </a>
        </li>

        <li *ngxPermissionsOnly="['user']">
            <a routerLink="/dashboard/reviews"
                [ngClass]="{'text-primary bg-[#eb764121]': currentRoute === '/dashboard/reviews'}"
                class="flex items-center p-2 sm:p-3 rounded-lg hover:bg-[#eb764121] group">
                <svg [ngClass]="currentRoute=='/dashboard/reviews'?'text-primary':'text-gray-500'"
                    class="w-4 h-4 transition duration-75 group-hover:text-primary"
                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"/>
                </svg>
                <span [ngClass]="currentRoute=='/dashboard/reviews'?'text-primary':'text-gray-500'"
                    class="ms-3 transition-all duration-300 group-hover:text-primary">Reviews & Feedback</span>
            </a>
        </li>

        <li *ngxPermissionsOnly="['user']">
            <button type="button"
                class="flex items-center w-full p-2 sm:p-3 text-base text-[#64748B] rounded-lg group hover:bg-[#eb764121]"
                (click)="selectItem('manage-campaigns')">
                <svg [ngClass]="currentRoute === '/campaign' ? 'text-primary' : 'text-gray-500'"
                    class="w-4 h-4 transition duration-75 group-hover:text-primary"
                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path d="M20 4v12a2 2 0 01-2 2H6a2 2 0 01-2-2V4m16 0l-8 6-8-6m16 0a2 2 0 00-2-2H6a2 2 0 00-2 2m16 0v12a2 2 0 01-2 2H6a2 2 0 01-2-2V4" 
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 10l4 4m0 0l-4 4m4-4H3" 
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span
                    class="flex-1 ms-3 text-left text-xs sm:text-sm whitespace-nowrap group-hover:text-primary group-hover:translate-x-1 transition-all duration-300">
                    Campaigns</span>
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m1 1 4 4 4-4" />
                </svg>
            </button>
            <ul class="py-1 sm:py-2 space-y-1 sm:space-y-2" [ngClass]="{'hidden': selectedItem !== 'manage-campaigns'}">
                <li *ngxPermissionsOnly="['user']">
                    <a routerLink="/dashboard/campaigns"
                        class="flex items-center w-full p-2 sm:p-3 text-[#64748B] rounded-lg pl-11 group hover:bg-[#eb764121]">View
                        Campaigns</a>
                </li>
                <li *ngxPermissionsOnly="['user']">
                    <a routerLink="/dashboard/create-campaign"
                        class="flex items-center w-full p-2 sm:p-3 text-[#64748B] rounded-lg pl-11 group hover:bg-[#eb764121]">Create
                        Campaign</a>
                </li>
            </ul>
        </li>
       
        <!-- admin menus -->
        <li *ngxPermissionsOnly="['admin']">
            <a routerLink="/dashboard/admin" [ngClass]="{'text-primary bg-[#eb764121]': currentRoute=='/dashboard/admin'}"
                class="flex items-center p-2 sm:p-3 rounded-lg hover:bg-[#eb764121] group">
                <svg [ngClass]=" currentRoute=='/dashboard/admin'?'text-primary':'text-gray-500'"
                    class="w-4 h-4 transition duration-75 group-hover:text-primary"
                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
                    <path
                        d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                    <path
                        d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                </svg>
                <span [ngClass]="currentRoute=='/dashboard/admin'?'text-primary':'text-gray-500'"
                    class="ms-3 group-hover:text-primary group-hover:translate-x-1 transition-all duration-300">Dashboard</span>
            </a>
        </li>
        <li *ngxPermissionsOnly="['admin']">
            <a routerLink="/dashboard/users"
                [ngClass]="{'text-primary bg-[#eb764121]': currentRoute === '/dashboard/users'}"
                class="flex items-center p-2 sm:p-3 rounded-lg hover:bg-[#eb764121] group">
                <svg [ngClass]="currentRoute=='/dashboard/users'?'text-primary':'text-gray-500'"
                    class="w-4 h-4 transition duration-75 group-hover:text-primary"
                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
                </svg>
                <span [ngClass]="currentRoute=='/dashboard/users'?'text-primary':'text-gray-500'"
                    class="ms-3 transition-all duration-300 group-hover:text-primary">Users Management</span>
            </a>
        </li>
          <li *ngxPermissionsOnly="['admin']">
              <a routerLink="/dashboard/chat-admin"
                  [ngClass]="{'text-primary bg-[#eb764121]': currentRoute === '/dashboard/chat-admin'}"
                  class="flex items-center p-2 sm:p-3 rounded-lg hover:bg-[#eb764121] group">
                  <svg [ngClass]="currentRoute=='/dashboard/chat-admin'?'text-primary':'text-gray-500'"
                      class="w-4 h-4 transition duration-75 group-hover:text-primary"
                      aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"/>
                  </svg>
                  <span [ngClass]="currentRoute=='/dashboard/chat-admin'?'text-primary':'text-gray-500'"
                      class="ms-3 transition-all duration-300 group-hover:text-primary">Chat Admin</span>
              </a>
          </li>
          <li *ngxPermissionsOnly="['admin']">
              <a routerLink="/dashboard/available-locations"
                  [ngClass]="{'text-primary bg-[#eb764121]': currentRoute === '/dashboard/available-locations'}"
                  class="flex items-center p-2 sm:p-3 rounded-lg hover:bg-[#eb764121] group">
                  <svg [ngClass]="currentRoute=='/dashboard/available-locations'?'text-primary':'text-gray-500'"
                      class="w-4 h-4 transition duration-75 group-hover:text-primary"
                      aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
                  </svg>
                  <span [ngClass]="currentRoute=='/dashboard/available-locations'?'text-primary':'text-gray-500'"
                      class="ms-3 transition-all duration-300 group-hover:text-primary">Available Locations</span>
              </a>
          </li>
          <li *ngxPermissionsOnly="['admin']">
            <a routerLink="/dashboard/admin-subscriptions"
                [ngClass]="{'text-primary bg-[#eb764121]': currentRoute === '/dashboard/admin-subscriptions'}"
                class="flex items-center p-2 rounded-lg dark:text-white hover:bg-[#eb764121] dark:hover:bg-gray-700 group">
                <svg [ngClass]="currentRoute=='/dashboard/admin-subscriptions'?'text-primary':'text-gray-500'"
                    class="w-4 h-4 font-normal  transition duration-75 dark:text-gray-400 group-hover:text-primary dark:group-hover:text-white"
                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <!-- Updated SVG for admin-subscriptions -->
                    <path d="M12 1v22M1 12h22" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path d="M17 6h6v6h-6zM1 6h6v6H1zM1 15h6v6H1zM17 15h6v6h-6z" stroke="currentColor"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span [ngClass]="currentRoute=='/dashboard/admin-subscriptions'?'text-primary':'text-gray-500'"
                    class="ms-3 transition-all duration-300  group-hover:text-primary">Subscriptions</span>
            </a>
        </li>
          <li *ngxPermissionsOnly="['admin']">
            <button type="button"
                class="flex items-center w-full p-2 sm:p-3 text-base text-[#64748B] rounded-lg group hover:bg-[#eb764121]"
                (click)="selectItem('manage-customer-campaigns')">
                <svg [ngClass]="currentRoute === '/campaign' ? 'text-primary' : 'text-gray-500'"
                    class="w-4 h-4 transition duration-75 group-hover:text-primary"
                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path d="M20 4v12a2 2 0 01-2 2H6a2 2 0 01-2-2V4m16 0l-8 6-8-6m16 0a2 2 0 00-2-2H6a2 2 0 00-2 2m16 0v12a2 2 0 01-2 2H6a2 2 0 01-2-2V4" 
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 10l4 4m0 0l-4 4m4-4H3" 
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span
                    class="flex-1 ms-3 text-left text-xs sm:text-sm whitespace-nowrap group-hover:text-primary group-hover:translate-x-1 transition-all duration-300">
                    Campaigns</span>
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m1 1 4 4 4-4" />
                </svg>
            </button>
            <ul class="py-1 sm:py-2 space-y-1 sm:space-y-2" [ngClass]="{'hidden': selectedItem !== 'manage-customer-campaigns'}">
                <li *ngxPermissionsOnly="['admin']">
                    <a routerLink="/dashboard/customer-campaigns"
                        class="flex items-center w-full p-2 sm:p-3 text-[#64748B] rounded-lg pl-11 group hover:bg-[#eb764121]">Customer Campaigns</a>
                </li>
               
            </ul>
        </li>
          <li *ngxPermissionsOnly="['admin']">
              <a routerLink="/dashboard/admin-reviews"
                  [ngClass]="{'text-primary bg-[#eb764121]': currentRoute === '/dashboard/admin-reviews'}"
                  class="flex items-center p-2 sm:p-3 rounded-lg hover:bg-[#eb764121] group">
                  <svg [ngClass]="currentRoute=='/dashboard/admin-reviews'?'text-primary':'text-gray-500'"
                      class="w-4 h-4 transition duration-75 group-hover:text-primary"
                      aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"/>
                  </svg>
                  <span [ngClass]="currentRoute=='/dashboard/admin-reviews'?'text-primary':'text-gray-500'"
                      class="ms-3 transition-all duration-300 group-hover:text-primary">Reviews & Feedback</span>
              </a>
          </li>
      </ul>
      <div class="mt-auto">
          <hr class="mt-6 sm:mt-10">
          <a class="font-light flex justify-between items-center pt-4 sm:pt-5 cursor-pointer" routerLink="/dashboard/profile">
              <div class="image-area">
                  <img [src]="userData.profileImage" class="w-8 sm:w-10 h-8 sm:h-10 rounded-full" appImageNotFound>
              </div>
              <div class="text-xs sm:text-sm">
                  <h4 class="font-normal">Welcome Back 👋</h4>
                  <p>{{userData.firstName}} {{userData.lastName}}</p>
              </div>
              <div>
                  <i class="ri-arrow-right-s-line text-lg sm:text-xl cursor-pointer"></i>
              </div>
          </a>
      </div>
  </div>
</aside>

<!-- Overlay for mobile -->
<!-- <div *ngIf="isSidebarOpen" 
    class="fixed inset-0 z-30 bg-gray-900 bg-opacity-50 lg:hidden"
    (click)="toggleSidebar()">
</div> -->
