import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CampaignService } from '../../../shared/service/campaign/campaign.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { NavService } from 'src/app/shared/service/navbar/nav.service';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { environment } from 'src/environments/environment';
import { SocketService } from 'src/app/shared/service/socket/socket.service';
import { LoggingService } from 'src/app/shared/service/logging.service';
@Component({
  selector: 'app-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.scss'],
  animations: [
    trigger('fadeSlide', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('0.3s ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ]),
    trigger('markerAnimation', [
      transition(':enter', [
        style({ transform: 'scale(0)', opacity: 0 }),
        animate('0.5s cubic-bezier(0.34, 1.56, 0.64, 1)', 
          style({ transform: 'scale(1)', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('0.3s ease-out', 
          style({ transform: 'scale(0)', opacity: 0 }))
      ])
    ]),
    trigger('pulseAnimation', [
      transition(':increment', [
        style({ transform: 'scale(1)' }),
        animate('0.3s ease-in-out', style({ transform: 'scale(1.2)' })),
        animate('0.3s ease-in-out', style({ transform: 'scale(1)' }))
      ])
    ])
  ]
})
export class CampaignDetailsComponent implements OnInit {
  campaign: any;
  loading: boolean = true;
  private readonly SOURCE = 'CampaignDetailsComponent';

  @ViewChild(GoogleMap) map!: GoogleMap;
  
  // Google Maps properties
  center: google.maps.LatLngLiteral = { lat: 39.8283, lng: -98.5795 }; // Center of USA
  zoom = 4;
  selectedLocationMarkerOptions: google.maps.MarkerOptions = {
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 8,
      fillColor: '#eb7641',
      fillOpacity: 1,
      strokeColor: '#ffffff',
      strokeWeight: 2,
    }
  };
  playedLocationMarkerOptions: google.maps.MarkerOptions = {
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 6,
      fillColor: '#000000',
      fillOpacity: 1,
      strokeColor: '#ffffff',
      strokeWeight: 2,
    }
  };
  mapOptions: google.maps.MapOptions = {
    styles: [
      {
        featureType: 'all',
        elementType: 'all',
        stylers: [{ saturation: -20 }]
      }
    ]
  };

  // Sample campaign locations data
  sampleLocations = [
    {
      locationName: "Times Square",
      latitude: 40.7580,
      longitude: -73.9855,
      state: "New York",
      radius: 100
    },
    {
      locationName: "Hollywood Walk of Fame",
      latitude: 34.1016,
      longitude: -118.3267,
      state: "California",
      radius: 150
    },
    {
      locationName: "Navy Pier",
      latitude: 41.8919,
      longitude: -87.6051,
      state: "Illinois",
      radius: 120
    },
    {
      locationName: "Fisherman's Wharf",
      latitude: 37.8080,
      longitude: -122.4177,
      state: "California",
      radius: 200
    },
    {
      locationName: "South Beach",
      latitude: 25.7827,
      longitude: -80.1340,
      state: "Florida",
      radius: 180
    },
    {
      locationName: "French Quarter",
      latitude: 29.9584,
      longitude: -90.0644,
      state: "Louisiana",
      radius: 160
    },
    {
      locationName: "Pike Place Market",
      latitude: 47.6097,
      longitude: -122.3422,
      state: "Washington",
      radius: 140
    },
    {
      locationName: "Las Vegas Strip",
      latitude: 36.1147,
      longitude: -115.1728,
      state: "Nevada",
      radius: 250
    }
  ];

  // Add new properties for animation triggers
  locationUpdateCount = 0;
  recentlyUpdatedLocations: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private campaignService: CampaignService,
    private navService: NavService,
    private socketService: SocketService,
    private loggingService: LoggingService
  ) {}

  ngOnInit(): void {
    this.loggingService.log(this.SOURCE, 'CampaignDetailsComponent initialized');
    this.navService.setTitle('Campaign Details');
    
    // Get campaign ID first
    const campaignId = this.route.snapshot.paramMap.get('id');
    if (!campaignId) {
        this.loggingService.log(this.SOURCE, '❌ No campaign ID provided');
        return;
    }

    // Setup socket subscription before loading campaign
    this.setupSocketSubscription(campaignId);
    
    // Then load campaign details
    this.loadCampaignDetails(campaignId);
  }

  private setupSocketSubscription(campaignId: string): void {
    this.socketService.getConnectionStatus().subscribe((isConnected: boolean) => {
        this.loggingService.log(this.SOURCE, `Socket connection status: ${isConnected}`);
        
        if (isConnected) {
            this.socketService.getPlayedLocationsUpdates().subscribe({
                next: (data: any) => {
                    this.loggingService.log(this.SOURCE, 'Received played locations update:', {
                        receivedCampaignId: data.campaignId,
                        expectedCampaignId: campaignId,
                        locationsCount: data.playedLocations?.length
                    });

                    if (data.campaignId === campaignId) {
                        // Store the previous count to identify new locations
                        const previousCount = this.campaign.playedLocations?.length || 0;
                        
                        // Update the locations
                        this.campaign.playedLocations = data.playedLocations;
                        
                        // Trigger animations for new locations
                        if (data.playedLocations.length > previousCount) {
                            // Get the new locations
                            const newLocations = data.playedLocations.slice(previousCount);
                            
                            // Update animation triggers
                            this.locationUpdateCount++;
                            this.recentlyUpdatedLocations = newLocations.map((loc: { latitude: number; longitude: number }) => 
                                `${loc.latitude},${loc.longitude}`
                            );
                            
                            // Clear the recently updated locations after animation
                            setTimeout(() => {
                                this.recentlyUpdatedLocations = [];
                            }, 3000);
                        }
                        
                        this.loggingService.log(this.SOURCE, '✅ Updated campaign played locations');
                    } else {
                        this.loggingService.log(this.SOURCE, '❌ Campaign ID mismatch');
                    }
                },
                error: (error) => {
                    this.loggingService.log(this.SOURCE, '❌ Error in played locations subscription:', error);
                }
            });
        }
    });
  }

  private loadCampaignDetails(campaignId: string): void {
    this.loading = true;
    this.campaignService.getCampaignById(campaignId).subscribe({
      next: (response: any) => {
        this.campaign = response.data;
        
        // Center map on the first selected location if available
        if (this.campaign.selectedLocations?.length > 0) {
          this.center = {
            lat: Number(this.campaign.selectedLocations[0].latitude),
            lng: Number(this.campaign.selectedLocations[0].longitude)
          };
          this.zoom = 12; // Closer zoom to show the area better
        }
        
        this.loading = false;
      },
      error: (error) => {
        console.error('Error loading campaign:', error);
        this.loading = false;
      }
    });
  }

  // Add this method to handle coordinate parsing
  getPosition(location: any): google.maps.LatLngLiteral {
    return {
      lat: Number(location.latitude),
      lng: Number(location.longitude)
    };
  }

  // Helper method to format the played date
  formatPlayedAt(playedAt: string): string {
    return new Date(playedAt).toLocaleString();
  }

  // Add helper method to check if a location was recently updated
  isRecentlyUpdated(location: any): boolean {
    return this.recentlyUpdatedLocations.includes(`${location.latitude},${location.longitude}`);
  }

  // Add new method to get marker options
  getPlayedLocationMarkerOptions(location: any): google.maps.MarkerOptions {
    if (this.isRecentlyUpdated(location)) {
      return {
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 8,
          fillColor: '#4CAF50',
          fillOpacity: 1,
          strokeColor: '#45a049',
          strokeWeight: 3,
        }
      };
    }
    return this.playedLocationMarkerOptions;
  }
}