
  <div class="flex gap-10 items-center flex-wrap mt-10">
    <div class="rounded-full border-primary border-4">
      <img
        src="{{ userData.profileImage || 'assets/images/default.png' }}"
        class="rounded-full"
        width="200"
        height="200"
        (click)="openFileSelect()"
        alt="profile-image"
        srcset=""
        appImageNotFound
      />
    </div>
    <div class="flex justify-start flex-col">
      <h3 class="text-2xl md:text-5xl font-bold text-[#083A50] mb-3">
        {{ userData.firstName + " " + userData.lastName }}
      </h3>
      <p class="text=[#083A50] text-small md:text-xl font-medium">
        Your account is ready, you can now use it.
      </p>
    </div>
  </div>

  <div class="flex gap-5 lg-gap-0 justify-between items-center flex-wrap mt-10">
    <div>
      <h4 class="text-2xl font-semibold pb-3">Edit Profile</h4>
    </div>
    <div class="flex justify-between items-center">
      <p class="text-grey font-medium">last update August 1</p>
    </div>
  </div>

  <!-- Inputs Fields -->

  <form [formGroup]="profileForm" (ngSubmit)="onSave()">
    <input
      type="file"
      id="profileImage"
      class="hidden"
      (change)="onFileChange($event)"
      formControlName="profileImage"
      name="profileImage"
    />
    <div class="grid gap-6 mb-6 md:grid-cols-2">
      <div>
        <label
          for="firstName"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >First name</label
        >
        <input
          type="text"
          id="firstName"
          formControlName="firstName"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="John"
          required
        />
      </div>
      <div>
        <label
          for="lastName"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Last Name</label
        >
        <input
          type="text"
          id="lastName"
          formControlName="lastName"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Doe"
        />
      </div>
      <div class="mb-6">
        <label
          for="email"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Email address</label
        >
        <input
          type="email"
          id="email"
          formControlName="email"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="john.doe@company.com"
        />
      </div>
      <div class="mb-6">
        <label
          for="Dob"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Date of Birth</label
        >
        <input
          type="date"
          id="Dob"
          formControlName="Dob"
          name="Dob"
          [value]="profileForm.get('Dob')?.value"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
        />
      </div>
      <div>
        <div>
          <label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 -translate-y-4 dark:text-white"
          >
            Phone Number</label
          >
          <div class="flex items-center -translate-y-4">
            <button
              id="dropdown-phone-button"
              data-dropdown-toggle="dropdown-phone"
              class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
              type="button"
            >
              <svg
                fill="none"
                aria-hidden="true"
                class="h-4 w-4 me-2"
                viewBox="0 0 20 15"
              >
                <rect width="19.6" height="14" y=".5" fill="#fff" rx="2" />
                <mask
                  id="a"
                  style="mask-type: luminance"
                  width="20"
                  height="15"
                  x="0"
                  y="0"
                  maskUnits="userSpaceOnUse"
                >
                  <rect width="19.6" height="14" y=".5" fill="#fff" rx="2" />
                </mask>
                <g mask="url(#a)">
                  <path
                    fill="#D02F44"
                    fill-rule="evenodd"
                    d="M19.6.5H0v.933h19.6V.5zm0 1.867H0V3.3h19.6v-.933zM0 4.233h19.6v.934H0v-.934zM19.6 6.1H0v.933h19.6V6.1zM0 7.967h19.6V8.9H0v-.933zm19.6 1.866H0v.934h19.6v-.934zM0 11.7h19.6v.933H0V11.7zm19.6 1.867H0v.933h19.6v-.933z"
                    clip-rule="evenodd"
                  />
                  <path fill="#46467F" d="M0 .5h8.4v6.533H0z" />
                  <g filter="url(#filter0_d_343_121520)">
                    <path
                      fill="url(#paint0_linear_343_121520)"
                      fill-rule="evenodd"
                      d="M1.867 1.9a.467.467 0 11-.934 0 .467.467 0 01.934 0zm1.866 0a.467.467 0 11-.933 0 .467.467 0 01.933 0zm1.4.467a.467.467 0 100-.934.467.467 0 000 .934zM7.467 1.9a.467.467 0 11-.934 0 .467.467 0 01.934 0zM2.333 3.3a.467.467 0 100-.933.467.467 0 000 .933zm2.334-.467a.467.467 0 11-.934 0 .467.467 0 01.934 0zm1.4.467a.467.467 0 100-.933.467.467 0 000 .933zm1.4.467a.467.467 0 11-.934 0 .467.467 0 01.934 0zm-2.334.466a.467.467 0 100-.933.467.467 0 000 .933zm-1.4-.466a.467.467 0 11-.933 0 .467.467 0 01.933 0zM1.4 4.233a.467.467 0 100-.933.467.467 0 000 .933zm1.4.467a.467.467 0 11-.933 0 .467.467 0 01.933 0zm1.4.467a.467.467 0 100-.934.467.467 0 000 .934zM6.533 4.7a.467.467 0 11-.933 0 .467.467 0 01.933 0zM7 6.1a.467.467 0 100-.933.467.467 0 000 .933zm-1.4-.467a.467.467 0 11-.933 0 .467.467 0 01.933 0zM3.267 6.1a.467.467 0 100-.933.467.467 0 000 .933zm-1.4-.467a.467.467 0 11-.934 0 .467.467 0 01.934 0z"
                      clip-rule="evenodd"
                    />
                  </g>
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_343_121520"
                    x1=".933"
                    x2=".933"
                    y1="1.433"
                    y2="6.1"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#fff" />
                    <stop offset="1" stop-color="#F0F0F0" />
                  </linearGradient>
                  <filter
                    id="filter0_d_343_121520"
                    width="6.533"
                    height="5.667"
                    x=".933"
                    y="1.433"
                    color-interpolation-filters="sRGB"
                    filterUnits="userSpaceOnUse"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      result="hardAlpha"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="1" />
                    <feColorMatrix
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
                    />
                    <feBlend
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_343_121520"
                    />
                    <feBlend
                      in="SourceGraphic"
                      in2="effect1_dropShadow_343_121520"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
              +1
              <svg
                class="w-2.5 h-2.5 ms-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>
            <div
              id="dropdown-phone"
              class="z-[999] hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-52 dark:bg-gray-700"
            >
              <ul
                class="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdown-phone-button"
              >
                <li>
                  <button
                    type="button"
                    class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
                    role="menuitem"
                  >
                    <div class="inline-flex items-center">
                      <svg
                        fill="none"
                        aria-hidden="true"
                        class="h-4 w-4 me-2"
                        viewBox="0 0 20 15"
                      >
                        <rect
                          width="19.6"
                          height="14"
                          y=".5"
                          fill="#fff"
                          rx="2"
                        />
                        <mask
                          id="a"
                          style="mask-type: luminance"
                          width="20"
                          height="15"
                          x="0"
                          y="0"
                          maskUnits="userSpaceOnUse"
                        >
                          <rect
                            width="19.6"
                            height="14"
                            y=".5"
                            fill="#fff"
                            rx="2"
                          />
                        </mask>
                        <g mask="url(#a)">
                          <path
                            fill="#D02F44"
                            fill-rule="evenodd"
                            d="M19.6.5H0v.933h19.6V.5zm0 1.867H0V3.3h19.6v-.933zM0 4.233h19.6v.934H0v-.934zM19.6 6.1H0v.933h19.6V6.1zM0 7.967h19.6V8.9H0v-.933zm19.6 1.866H0v.934h19.6v-.934zM0 11.7h19.6v.933H0V11.7zm19.6 1.867H0v.933h19.6v-.933z"
                            clip-rule="evenodd"
                          />
                          <path fill="#46467F" d="M0 .5h8.4v6.533H0z" />
                          <g filter="url(#filter0_d_343_121520)">
                            <path
                              fill="url(#paint0_linear_343_121520)"
                              fill-rule="evenodd"
                              d="M1.867 1.9a.467.467 0 11-.934 0 .467.467 0 01.934 0zm1.866 0a.467.467 0 11-.933 0 .467.467 0 01.933 0zm1.4.467a.467.467 0 100-.934.467.467 0 000 .934zM7.467 1.9a.467.467 0 11-.934 0 .467.467 0 01.934 0zM2.333 3.3a.467.467 0 100-.933.467.467 0 000 .933zm2.334-.467a.467.467 0 11-.934 0 .467.467 0 01.934 0zm1.4.467a.467.467 0 100-.933.467.467 0 000 .933zm1.4.467a.467.467 0 11-.934 0 .467.467 0 01.934 0zm-2.334.466a.467.467 0 100-.933.467.467 0 000 .933zm-1.4-.466a.467.467 0 11-.933 0 .467.467 0 01.933 0zM1.4 4.233a.467.467 0 100-.933.467.467 0 000 .933zm1.4.467a.467.467 0 11-.933 0 .467.467 0 01.933 0zm1.4.467a.467.467 0 100-.934.467.467 0 000 .934zM6.533 4.7a.467.467 0 11-.933 0 .467.467 0 01.933 0zM7 6.1a.467.467 0 100-.933.467.467 0 000 .933zm-1.4-.467a.467.467 0 11-.933 0 .467.467 0 01.933 0zM3.267 6.1a.467.467 0 100-.933.467.467 0 000 .933zm-1.4-.467a.467.467 0 11-.934 0 .467.467 0 01.934 0z"
                              clip-rule="evenodd"
                            />
                          </g>
                        </g>
                        <defs>
                          <linearGradient
                            id="paint0_linear_343_121520"
                            x1=".933"
                            x2=".933"
                            y1="1.433"
                            y2="6.1"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#fff" />
                            <stop offset="1" stop-color="#F0F0F0" />
                          </linearGradient>
                          <filter
                            id="filter0_d_343_121520"
                            width="6.533"
                            height="5.667"
                            x=".933"
                            y="1.433"
                            color-interpolation-filters="sRGB"
                            filterUnits="userSpaceOnUse"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              result="hardAlpha"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            />
                            <feOffset dy="1" />
                            <feColorMatrix
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
                            />
                            <feBlend
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_343_121520"
                            />
                            <feBlend
                              in="SourceGraphic"
                              in2="effect1_dropShadow_343_121520"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                      United States (+1)
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
                    role="menuitem"
                  >
                    <div class="inline-flex items-center">
                      <svg class="h-4 w-4 me-2" fill="none" viewBox="0 0 20 15">
                        <rect
                          width="19.6"
                          height="14"
                          y=".5"
                          fill="#fff"
                          rx="2"
                        />
                        <mask
                          id="a"
                          style="mask-type: luminance"
                          width="20"
                          height="15"
                          x="0"
                          y="0"
                          maskUnits="userSpaceOnUse"
                        >
                          <rect
                            width="19.6"
                            height="14"
                            y=".5"
                            fill="#fff"
                            rx="2"
                          />
                        </mask>
                        <g mask="url(#a)">
                          <path fill="#0A17A7" d="M0 .5h19.6v14H0z" />
                          <path
                            fill="#fff"
                            fill-rule="evenodd"
                            d="M-.898-.842L7.467 4.8V-.433h4.667V4.8l8.364-5.642L21.542.706l-6.614 4.46H19.6v4.667h-4.672l6.614 4.46-1.044 1.549-8.365-5.642v5.233H7.467V10.2l-8.365 5.642-1.043-1.548 6.613-4.46H0V5.166h4.672L-1.941.706-.898-.842z"
                            clip-rule="evenodd"
                          />
                          <path
                            stroke="#DB1F35"
                            stroke-linecap="round"
                            stroke-width=".667"
                            d="M13.067 4.933L21.933-.9M14.009 10.088l7.947 5.357M5.604 4.917L-2.686-.67M6.503 10.024l-9.189 6.093"
                          />
                          <path
                            fill="#E6273E"
                            fill-rule="evenodd"
                            d="M0 8.9h8.4v5.6h2.8V8.9h8.4V6.1h-8.4V.5H8.4v5.6H0v2.8z"
                            clip-rule="evenodd"
                          />
                        </g>
                      </svg>
                      United Kingdom (+44)
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
                    role="menuitem"
                  >
                    <div class="inline-flex items-center">
                      <svg
                        class="h-4 w-4 me-2"
                        fill="none"
                        viewBox="0 0 20 15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="19.6"
                          height="14"
                          y=".5"
                          fill="#fff"
                          rx="2"
                        />
                        <mask
                          id="a"
                          style="mask-type: luminance"
                          width="20"
                          height="15"
                          x="0"
                          y="0"
                          maskUnits="userSpaceOnUse"
                        >
                          <rect
                            width="19.6"
                            height="14"
                            y=".5"
                            fill="#fff"
                            rx="2"
                          />
                        </mask>
                        <g mask="url(#a)">
                          <path fill="#0A17A7" d="M0 .5h19.6v14H0z" />
                          <path
                            fill="#fff"
                            stroke="#fff"
                            stroke-width=".667"
                            d="M0 .167h-.901l.684.586 3.15 2.7v.609L-.194 6.295l-.14.1v1.24l.51-.319L3.83 5.033h.73L7.7 7.276a.488.488 0 00.601-.767L5.467 4.08v-.608l2.987-2.134a.667.667 0 00.28-.543V-.1l-.51.318L4.57 2.5h-.73L.66.229.572.167H0z"
                          />
                          <path
                            fill="url(#paint0_linear_374_135177)"
                            fill-rule="evenodd"
                            d="M0 2.833V4.7h3.267v2.133c0 .369.298.667.666.667h.534a.667.667 0 00.666-.667V4.7H8.2a.667.667 0 00.667-.667V3.5a.667.667 0 00-.667-.667H5.133V.5H3.267v2.333H0z"
                            clip-rule="evenodd"
                          />
                          <path
                            fill="url(#paint1_linear_374_135177)"
                            fill-rule="evenodd"
                            d="M0 3.3h3.733V.5h.934v2.8H8.4v.933H4.667v2.8h-.934v-2.8H0V3.3z"
                            clip-rule="evenodd"
                          />
                          <path
                            fill="#fff"
                            fill-rule="evenodd"
                            d="M4.2 11.933l-.823.433.157-.916-.666-.65.92-.133.412-.834.411.834.92.134-.665.649.157.916-.823-.433zm9.8.7l-.66.194.194-.66-.194-.66.66.193.66-.193-.193.66.193.66-.66-.194zm0-8.866l-.66.193.194-.66-.194-.66.66.193.66-.193-.193.66.193.66-.66-.193zm2.8 2.8l-.66.193.193-.66-.193-.66.66.193.66-.193-.193.66.193.66-.66-.193zm-5.6.933l-.66.193.193-.66-.193-.66.66.194.66-.194-.193.66.193.66-.66-.193zm4.2 1.167l-.33.096.096-.33-.096-.33.33.097.33-.097-.097.33.097.33-.33-.096z"
                            clip-rule="evenodd"
                          />
                        </g>
                        <defs>
                          <linearGradient
                            id="paint0_linear_374_135177"
                            x1="0"
                            x2="0"
                            y1=".5"
                            y2="7.5"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#fff" />
                            <stop offset="1" stop-color="#F0F0F0" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_374_135177"
                            x1="0"
                            x2="0"
                            y1=".5"
                            y2="7.033"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#FF2E3B" />
                            <stop offset="1" stop-color="#FC0D1B" />
                          </linearGradient>
                        </defs>
                      </svg>
                      Australia (+61)
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
                    role="menuitem"
                  >
                    <div class="inline-flex items-center">
                      <svg class="w-4 h-4 me-2" fill="none" viewBox="0 0 20 15">
                        <rect
                          width="19.6"
                          height="14"
                          y=".5"
                          fill="#fff"
                          rx="2"
                        />
                        <mask
                          id="a"
                          style="mask-type: luminance"
                          width="20"
                          height="15"
                          x="0"
                          y="0"
                          maskUnits="userSpaceOnUse"
                        >
                          <rect
                            width="19.6"
                            height="14"
                            y=".5"
                            fill="#fff"
                            rx="2"
                          />
                        </mask>
                        <g mask="url(#a)">
                          <path
                            fill="#262626"
                            fill-rule="evenodd"
                            d="M0 5.167h19.6V.5H0v4.667z"
                            clip-rule="evenodd"
                          />
                          <g filter="url(#filter0_d_374_135180)">
                            <path
                              fill="#F01515"
                              fill-rule="evenodd"
                              d="M0 9.833h19.6V5.167H0v4.666z"
                              clip-rule="evenodd"
                            />
                          </g>
                          <g filter="url(#filter1_d_374_135180)">
                            <path
                              fill="#FFD521"
                              fill-rule="evenodd"
                              d="M0 14.5h19.6V9.833H0V14.5z"
                              clip-rule="evenodd"
                            />
                          </g>
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_374_135180"
                            width="19.6"
                            height="4.667"
                            x="0"
                            y="5.167"
                            color-interpolation-filters="sRGB"
                            filterUnits="userSpaceOnUse"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              result="hardAlpha"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            />
                            <feOffset />
                            <feColorMatrix
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
                            />
                            <feBlend
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_374_135180"
                            />
                            <feBlend
                              in="SourceGraphic"
                              in2="effect1_dropShadow_374_135180"
                              result="shape"
                            />
                          </filter>
                          <filter
                            id="filter1_d_374_135180"
                            width="19.6"
                            height="4.667"
                            x="0"
                            y="9.833"
                            color-interpolation-filters="sRGB"
                            filterUnits="userSpaceOnUse"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              result="hardAlpha"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            />
                            <feOffset />
                            <feColorMatrix
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
                            />
                            <feBlend
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_374_135180"
                            />
                            <feBlend
                              in="SourceGraphic"
                              in2="effect1_dropShadow_374_135180"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                      Germany (+49)
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
                    role="menuitem"
                  >
                    <div class="inline-flex items-center">
                      <svg class="w-4 h-4 me-2" fill="none" viewBox="0 0 20 15">
                        <rect
                          width="19.1"
                          height="13.5"
                          x=".25"
                          y=".75"
                          fill="#fff"
                          stroke="#F5F5F5"
                          stroke-width=".5"
                          rx="1.75"
                        />
                        <mask
                          id="a"
                          style="mask-type: luminance"
                          width="20"
                          height="15"
                          x="0"
                          y="0"
                          maskUnits="userSpaceOnUse"
                        >
                          <rect
                            width="19.1"
                            height="13.5"
                            x=".25"
                            y=".75"
                            fill="#fff"
                            stroke="#fff"
                            stroke-width=".5"
                            rx="1.75"
                          />
                        </mask>
                        <g mask="url(#a)">
                          <path fill="#F44653" d="M13.067.5H19.6v14h-6.533z" />
                          <path
                            fill="#1035BB"
                            fill-rule="evenodd"
                            d="M0 14.5h6.533V.5H0v14z"
                            clip-rule="evenodd"
                          />
                        </g>
                      </svg>
                      France (+33)
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
                    role="menuitem"
                  >
                    <div class="inline-flex items-center">
                      <svg class="w-4 h-4 me-2" fill="none" viewBox="0 0 20 15">
                        <rect
                          width="19.6"
                          height="14"
                          y=".5"
                          fill="#fff"
                          rx="2"
                        />
                        <mask
                          id="a"
                          style="mask-type: luminance"
                          width="20"
                          height="15"
                          x="0"
                          y="0"
                          maskUnits="userSpaceOnUse"
                        >
                          <rect
                            width="19.6"
                            height="14"
                            y=".5"
                            fill="#fff"
                            rx="2"
                          />
                        </mask>
                        <g mask="url(#a)">
                          <path
                            fill="#262626"
                            fill-rule="evenodd"
                            d="M0 5.167h19.6V.5H0v4.667z"
                            clip-rule="evenodd"
                          />
                          <g filter="url(#filter0_d_374_135180)">
                            <path
                              fill="#F01515"
                              fill-rule="evenodd"
                              d="M0 9.833h19.6V5.167H0v4.666z"
                              clip-rule="evenodd"
                            />
                          </g>
                          <g filter="url(#filter1_d_374_135180)">
                            <path
                              fill="#FFD521"
                              fill-rule="evenodd"
                              d="M0 14.5h19.6V9.833H0V14.5z"
                              clip-rule="evenodd"
                            />
                          </g>
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_374_135180"
                            width="19.6"
                            height="4.667"
                            x="0"
                            y="5.167"
                            color-interpolation-filters="sRGB"
                            filterUnits="userSpaceOnUse"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              result="hardAlpha"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            />
                            <feOffset />
                            <feColorMatrix
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
                            />
                            <feBlend
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_374_135180"
                            />
                            <feBlend
                              in="SourceGraphic"
                              in2="effect1_dropShadow_374_135180"
                              result="shape"
                            />
                          </filter>
                          <filter
                            id="filter1_d_374_135180"
                            width="19.6"
                            height="4.667"
                            x="0"
                            y="9.833"
                            color-interpolation-filters="sRGB"
                            filterUnits="userSpaceOnUse"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              result="hardAlpha"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            />
                            <feOffset />
                            <feColorMatrix
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
                            />
                            <feBlend
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_374_135180"
                            />
                            <feBlend
                              in="SourceGraphic"
                              in2="effect1_dropShadow_374_135180"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                      Germany (+49)
                    </div>
                  </button>
                </li>
              </ul>
            </div>
            <label
              for="phoneNumber"
              class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >Phone number:</label
            >
            <div class="relative w-full">
              <input
                type="phone"
                id="phoneNumber"
                formControlName="phoneNumber"
                class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                placeholder="123-456-7890"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <button type="submit" class="purple-btn-own">
      <ng-container *ngIf="loading; else buttonText">
        <app-spinner></app-spinner>
      </ng-container>
      <ng-template #buttonText> Save </ng-template>
    </button>
    <button type="button" class="ml-2 purple-btn-own" (click)="openChangePasswordModal()">
      Change Password
    </button>
    <button type="button" class="ml-2 purple-btn-own" (click)="logout()">
      Logout
    </button>
    <button type="button" class="ml-2 bg-[#EF4444] hover:bg-[#DC2626] text-white font-bold py-2 px-4 rounded" (click)="openDeleteAccountModal()">
      Delete Account
    </button>
  </form>

  <!-- Change Password Modal -->
  <div *ngIf="showPasswordModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
    <div class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-lg font-semibold">Change Password</h3>
        <button type="button" class="text-gray-400 hover:text-gray-600" (click)="closeChangePasswordModal()">
          <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>
      
      <form [formGroup]="passwordForm" (ngSubmit)="onChangePassword()">
        <div class="mb-4">
          <label for="currentPassword" class="block mb-2 text-sm font-medium text-gray-900">Current Password</label>
          <input
            type="password"
            id="currentPassword"
            formControlName="currentPassword"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            required
          />
        </div>
        <div class="mb-4">
          <label for="newPassword" class="block mb-2 text-sm font-medium text-gray-900">New Password</label>
          <input
            type="password"
            id="newPassword"
            formControlName="newPassword"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            required
          />
        </div>
        <div class="mb-4">
          <label for="confirmPassword" class="block mb-2 text-sm font-medium text-gray-900">Confirm New Password</label>
          <input
            type="password"
            id="confirmPassword"
            formControlName="confirmPassword"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            required
          />
          <div *ngIf="passwordForm.hasError('mismatch')" class="text-red text-sm mt-1">
            Passwords do not match
          </div>
        </div>
        <div class="flex justify-end gap-3">
          <button 
            type="button" 
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300"
            (click)="closeChangePasswordModal()"
          >
            Cancel
          </button>
          <button 
            type="submit" 
            [disabled]="!passwordForm.valid || loading"
            class="purple-btn-own"
          >
            <ng-container *ngIf="loading; else changePasswordText">
              <app-spinner></app-spinner>
            </ng-container>
            <ng-template #changePasswordText>
              Change Password
            </ng-template>
          </button>
        </div>
      </form>
    </div>
  </div>

  <!-- Delete Account Confirmation Modal -->
  <div *ngIf="showDeleteModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
    <div class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-lg font-semibold text-red-600">Delete Account</h3>
        <button type="button" class="text-gray-400 hover:text-gray-600" (click)="closeDeleteAccountModal()">
          <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>
      
      <div class="mb-6">
        <p class="text-gray-700 mb-4">Are you sure you want to delete your account? This action cannot be undone.</p>
        <p class="text-gray-700 mb-4">All your data will be permanently deleted.</p>
      </div>

      <div class="flex justify-end gap-3">
        <button 
          type="button" 
          class="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300"
          (click)="closeDeleteAccountModal()"
        >
          Cancel
        </button>
        <button 
          type="button" 
          [disabled]="loading"
          class="px-4 py-2 bg-[#EF4444] hover:bg-[#DC2626] text-white rounded-lg"
          (click)="deleteAccount()"
        >
          <ng-container *ngIf="loading; else deleteText">
            <app-spinner></app-spinner>
          </ng-container>
          <ng-template #deleteText>
            Delete Account
          </ng-template>
        </button>
      </div>
    </div>
  </div>
