import { Component } from '@angular/core';
import { Route } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NavService } from 'src/app/shared/service/navbar/nav.service';
import { SubscriptionsService } from 'src/app/shared/service/subscriptions/subscriptions.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrl: './invoices.component.scss'
})
export class InvoicesComponent {
  invoices: any;
  constructor(
    private subscriptionService: SubscriptionsService,
    private toastr: ToastrService,
    private navService: NavService
  ) { }
  async ngOnInit() {
    this.getAllInvoices()
    this.navService.setTitle('Invoices Overview');
    this.navService.setSubtitle('Manage your ShowYourAdz invoices');
  }
  getAllInvoices(){
    this.subscriptionService.getInvoices().subscribe({
      next: ((res:any) => {
        this.invoices = res.data;
      })
    })
  }
}
