<nav class="w-full px-5 py-4 flex justify-between items-center max-w-[1400px] mx-auto translate-y-[-100%] mainNav">
  <!-- Left side - Navigation links (visible on large screens) -->
  <div class="hidden lg:flex items-center space-x-8 flex-1">
    <a routerLink="/" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" class=" text-black hover:text-[#eb7641] transition-colors ">HOME</a>
    <a routerLink="/pricing" [routerLinkActive]="['active']" class=" text-black hover:text-[#eb7641] transition-colors ">PRICING</a>
    <a routerLink="/contact" [routerLinkActive]="['active']" class=" text-black hover:text-[#eb7641] transition-colors ">CONTACT</a>
  </div>
  
  <!-- Center - Logo -->
  <div onclick="window.location.href = '/'" class="flex  justify-start lg:items-center lg:justify-center mx-auto lg:mx-0 flex-1">
    <img src="assets/images/logo/logo.png" alt="Show Your Adz Logo" class="h-15" appImageNotFound>
  </div>
  
  <!-- Right side - Login and Menu -->
  <div class="flex items-center justify-end space-x-6 flex-1" >
    <div class="hidden lg:flex items-center space-x-2 text-white cursor-pointer hover:opacity-80 transition-opacity" >
      <ng-container *ngIf="userData; else loginButton"> 
        <button (click)="navigateToDashboard()" class="px-8 py-2 bg-[#eb7641] text-white font1 text-lg tracking-wider
        transition-all duration-300 shadow-lg hover:shadow-xl
        hover:bg-transparent hover:text-[#eb7641] hover:scale-105 
        border-2 border-[#eb7641] hover:border-[#eb7641]">
        Dashboard
      </button>
      </ng-container>
      <ng-template #loginButton>
        <button routerLink="/login" class="px-8 py-2 bg-[#eb7641] text-white font1 text-lg tracking-wider
              transition-all duration-300 shadow-lg hover:shadow-xl
              hover:bg-transparent hover:text-[#eb7641] hover:scale-105 
              border-2 border-[#eb7641] hover:border-[#eb7641]">
        Login
      </button>
      <button routerLink="/signup" class="px-8 py-2 bg-[#eb7641] text-white font1 text-lg tracking-wider
      transition-all duration-300 shadow-lg hover:shadow-xl
      hover:bg-transparent hover:text-[#eb7641] hover:scale-105 
      border-2 border-[#eb7641] hover:border-[#eb7641]">
      Register
      </button>
    </ng-template>
     
    </div>

    <!-- Menu Toggle Button (visible on medium and smaller screens) -->
    <button id="toggle"
      class="lg:hidden flex flex-col justify-center items-center w-8 h-8 space-y-1.5 cursor-pointer group">
      <span class="w-8 h-0.5 bg-black rounded-full transition-all duration-300 group-hover:w-6"></span>
      <span class="w-6 h-0.5 bg-black rounded-full transition-all duration-300 group-hover:w-8"></span>
      <span class="w-8 h-0.5 bg-black rounded-full transition-all duration-300 group-hover:w-6"></span>
    </button>
  </div>
</nav>
<!-- SideMenu (visible on medium and smaller screens) -->
<div id="full"
  class="w-full md:w-[40%] h-screen fixed top-0 right-0 bg-white/30 backdrop-blur-[10px] px-[60px] py-[150px] z-50 lg:hidden">
  <a routerLink="/" [routerLinkActive]="['active']"  [routerLinkActiveOptions]="{exact: true}" 
    class="text-[30px] sm:text-[40px] md:text-[50px] lg:text-[60px] font-[500] block hover:text-[#eb7641] transition-colors">HOME</a>
  <a routerLink="/pricing" [routerLinkActive]="['active']"
    class="text-[30px] sm:text-[40px] md:text-[50px] lg:text-[60px] font-[500] block hover:text-[#eb7641] transition-colors">PRICING</a>
  <a routerLink="/contact" [routerLinkActive]="['active']"
    class="text-[30px] sm:text-[40px] md:text-[50px] lg:text-[60px] font-[500] block hover:text-[#eb7641] transition-colors">CONTACT</a>

  <ng-container *ngIf="userData; else mobileLoginButtons">
    <a (click)="navigateToDashboard()" class="text-[30px] sm:text-[40px] md:text-[50px] lg:text-[60px] font-[500] block hover:text-[#eb7641] transition-colors">
      Dashboard
    </a>
  </ng-container>
  <ng-template #mobileLoginButtons>
    <a routerLink="/login" class="text-[30px] sm:text-[40px] md:text-[50px] lg:text-[60px] font-[500] block hover:text-[#eb7641] transition-colors">
      Login
    </a>
    <a routerLink="/signup" class="text-[30px] sm:text-[40px] md:text-[50px] lg:text-[60px] font-[500] block hover:text-[#eb7641] transition-colors">
      Register
    </a>
  </ng-template>

  <div id="close"
    class="absolute top-[5%] right-[10%] cursor-pointer bg-[#eb7641] rounded-full w-10 h-10 flex justify-center items-center">
    <i class="ri-close-line text-2xl text-white"></i>
  </div>
</div>