<!-- Right Area -->
<div class=" px-5 md:px-10 py-5  sm:ml-64">
  <div class="dashboard-header flex justify-start items-center">
    <div class="font-bold">
      Settings/roles
    </div>

  </div>

  <!-- Search  -->
  <div class="flex gap-5 lg-gap-0 lg:justify-between items-center flex-wrap mt-10">
    <div class="flex-1">
      <form class="max-w-md ">
        <label for="default-search"
          class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
        <div class="relative">
          <div class="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>
          <input type="search" id="default-search"
            class="block w-full py-2.5 px-3 ps-5 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search..." required />
        </div>
      </form>
    </div>

    <button class="purple-btn-own" (click)="openModal()"><span><i class="ri-add-circle-line mr-2"></i></span>Add
      Role</button>
    <button class="purple-btn-own" (click)="applyChanges()">
      <span><i class="ri-pencil-line mr-2"></i></span>Apply Changes
    </button>
  </div>
  <div id="accordion-collapse" data-accordion="collapse">
    <div *ngFor="let item of roles; let i = index" class="my-5">
      <h2 (click)="selectRole(item._id)">
        <button type="button"
          class="flex bg-white items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-xl focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 gap-3"
          [attr.data-accordion-target]="'#accordion-collapse-body-' + i"
          [attr.aria-expanded]="i === 0 ? 'true' : 'false'" [attr.aria-controls]="'accordion-collapse-body-' + i">
          <span>{{item.name}}</span>
          <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M9 5 5 1 1 5" />
          </svg>
        </button>
      </h2>
      <div>
        <div [ngClass]="{'hidden': item._id !== selectedId}"
          class="p-5  border border-b-0 border-gray-200 dark:border-gray-700">
          <ul *ngFor="let permission of item.permissions"
            class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg flex dark:bg-gray-700 dark:border-gray-600 dark:text-white py-4 px-2 sm:p-5 my-3 hover:bg-purple-200">
            <li class="w-full border-gray-200 sm:border-b-0 dark:border-gray-600">
              <div class="flex items-center ps-3">
                <p>{{permission.resource.name}}</p>
              </div>
            </li>
            <li *ngFor="let action of ['view', 'add', 'edit', 'delete']"
              class="w-full border-gray-200 sm:border-b-0 dark:border-gray-600">
              <div class="flex items-center ps-3">
                <input [id]="item._id + '-' + permission.resource._id + '-' + action" type="checkbox"
                  [checked]="permission[action]"
                  (change)="updatePermission(item._id, permission.resource._id, action, $event)"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                <label [for]="item._id + '-' + permission.resource._id + '-' + action"
                  class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  {{action | titlecase}}
                </label>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div *ngIf="isVisible" class="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
  <div @modalAnimation class="relative w-full max-w-2xl mx-auto bg-white rounded-lg shadow dark:bg-gray-700">
    <!-- Modal Header -->
    <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
      <h3 class="text-xl font-medium text-gray-900 dark:text-white">
        Create New Role
      </h3>
      <button type="button"
        class="text-black bg-transparent border border-black rounded-full hover:bg-gray-200 hover:text-gray-900 text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
        (click)="closeModal()">
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#000" viewBox="0 0 14 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
        </svg>
        <span class="sr-only">Close modal</span>
      </button>
    </div>

    <!-- Modal Body -->
    <div class="p-4 md:p-5 space-y-4">
      <form [formGroup]="roleForm">
        <div class="space-y-4">
          <div>
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Role Name
            </label>
            <input type="text" id="name" formControlName="name"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter your Role Name" />
          </div>
          <div>
            <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Description
            </label>
            <textarea id="description" formControlName="description" rows="4"
              class="resize-none block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter description"></textarea>
          </div>
        </div>
      </form>
    </div>

    <!-- Modal Footer -->
    <div
      class="flex items-center justify-end p-4 md:p-5 space-x-3 rtl:space-x-reverse border-t border-gray-200 rounded-b dark:border-gray-600">
      <button type="button" (click)="closeModal()" class="purple-btn-light">Cancel</button>
      <button type="submit" (click)="onSubmit()" class="purple-btn-own"> <ng-container *ngIf="loading; else buttonText">
          <app-spinner></app-spinner>
        </ng-container>
        <ng-template #buttonText>
          Save
        </ng-template></button>
    </div>
  </div>
</div>