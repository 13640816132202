<div class="p-6">

    <!-- Loading State -->
    <div *ngIf="isLoading" class="text-center py-12">
        <svg class="animate-spin h-8 w-8 text-[#eb7641] mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <p class="mt-2 text-gray-600">Loading reviews...</p>
    </div>

    <!-- Reviews Grid -->
    <div *ngIf="!isLoading" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <!-- Review Card -->
        <div *ngFor="let review of reviews" class="bg-white p-6 rounded-lg shadow-lg relative flex flex-col">
            <!-- Quote Icon -->
            <div class="absolute -top-4 right-8">
                <svg class="w-8 h-8 text-[#eb7641]" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z"/>
                </svg>
            </div>

            <!-- Review Content -->
            <div class="flex-grow">
                <!-- User Info -->
                <div class="flex items-center mb-4">
                    <div class="w-12 h-12 rounded-full bg-[#eb7641] flex items-center justify-center text-white text-lg font-semibold">
                        {{ getInitials(review.name) }}
                    </div>
                    <div class="ml-4">
                        <h4 class="font-semibold text-gray-800">{{ truncateName(review.name) }}</h4>
                        <p class="text-sm text-gray-500">{{ review.duration }} • {{ formatDate(review.date) }}</p>
                    </div>
                </div>

                <!-- Rating and Status -->
                <div class="flex justify-between items-center mb-4">
                    <div class="flex text-[#eb7641] text-xl">
                        {{ getRatingStars(review.rating) }}
                    </div>
                    <span [class]="'px-3 py-1 rounded-full text-xs font-medium ' + getStatusColor(review.status)">
                        {{ formatStatus(review.status) }}
                    </span>
                </div>

                <!-- Review Text -->
                <p class="text-gray-600 text-sm mb-6">{{ review.comment }}</p>
            </div>

            <!-- Actions Container -->
            <div class="mt-auto">
                <!-- Pending Actions -->
                <div class="grid grid-cols-1 sm:grid-cols-2 gap-3" *ngIf="review.status === 'pending'">
                    <button 
                        (click)="updateReviewStatus(review, 'rejected')"
                        [disabled]="isUpdating && selectedReview?._id === review._id"
                        class="w-full px-4 py-3 bg-white border-2 border-gray-200 rounded-2xl hover:bg-gray-50 transition-all duration-300 flex items-center justify-center gap-2 font-medium disabled:opacity-50 disabled:cursor-not-allowed">
                        <i class="ri-close-line text-lg"></i>
                        <span>Reject</span>
                    </button>
                    <button 
                        (click)="updateReviewStatus(review, 'approved')"
                        [disabled]="isUpdating && selectedReview?._id === review._id"
                        class="w-full px-4 py-3 bg-[#eb7641] text-white rounded-2xl hover:bg-[#d66937] transition-all duration-300 flex items-center justify-center gap-2 font-medium disabled:opacity-50 disabled:cursor-not-allowed">
                        <i class="ri-check-line text-lg"></i>
                        <span>Approve</span>
                    </button>
                </div>

                <!-- Delete Button for Approved Reviews -->
                <div class="flex justify-end" *ngIf="review.status === 'approved'">
                    <button 
                        (click)="confirmDelete(review)"
                        class="p-2 text-gray-500 hover:text-red-600 transition-colors duration-300">
                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Empty State -->
    <div *ngIf="!isLoading && reviews.length === 0" class="text-center py-12">
        <svg class="w-16 h-16 text-gray-400 mx-auto mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M12 12h.01M12 12h.01M12 12h.01M12 12h.01M12 12h.01M12 12h.01M12 12h.01"></path>
        </svg>
        <h3 class="text-lg font-medium text-gray-900 mb-2">No Reviews Yet</h3>
        <p class="text-gray-500">There are no reviews to moderate at this time.</p>
    </div>

    <!-- Delete Confirmation Modal -->
    <div *ngIf="showDeleteConfirmation" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <!-- Modal Header -->
            <div class="mb-4">
                <h3 class="text-lg font-semibold text-gray-900">Delete Review</h3>
                <p class="text-gray-600 mt-2">Are you sure you want to delete this review? This action cannot be undone.</p>
            </div>
            
            <!-- Review Details -->
            <div *ngIf="selectedReview" class="mb-6 p-4 bg-gray-50 rounded-lg">
                <div class="text-sm text-gray-600">
                    <p class="mb-2">
                        <span class="font-medium">Name:</span> 
                        <span>{{ truncateName(selectedReview.name) }}</span>
                    </p>
                    <p class="mb-2">
                        <span class="font-medium">Rating:</span> 
                        <span class="text-[#eb7641]">{{ getRatingStars(selectedReview.rating) }}</span>
                    </p>
                    <p class="mb-2">
                        <span class="font-medium">Duration:</span> 
                        <span>{{ selectedReview.duration }}</span>
                    </p>
                    <p>
                        <span class="font-medium">Comment:</span> 
                        <span>{{ selectedReview.comment }}</span>
                    </p>
                </div>
            </div>

            <!-- Modal Actions -->
            <div class="flex justify-end gap-3">
                <button 
                    type="button"
                    (click)="cancelDelete()"
                    class="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors duration-300 font-medium">
                    Cancel
                </button>
                <button 
                    type="button"
                    (click)="deleteReview()"
                    [disabled]="isDeleting"
                    class="px-4 py-2 bg-[#eb7641] text-white rounded-lg hover:bg-[#d66937] transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed font-medium inline-flex items-center gap-2">
                    <svg *ngIf="isDeleting" class="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <span>{{ isDeleting ? 'Deleting...' : 'Delete Review' }}</span>
                </button>
            </div>
        </div>
    </div>
</div> 