export const environment = {
    BACKEND_URL:"https://api.haulads.com",
    APP_URL:"https://api.haulads.com",
    // BACKEND_URL:"http://localhost:3000",
    // APP_URL:"http://localhost:3000",

    STRIPE_PUBLIC_KEY:"pk_live_51PnmzRAR0x1O0G6Tgr0dx4h42xlIowkgordLCfx8EMC0WQ8YiY906nTQYlMFpsxluur8mHCgmgx0MHHuqqSpwyXt00GRZzOJS0",
    googleMapsApiKey: 'AIzaSyDVdGMShh30WI6Y1arqFOqxHFLPsXw4r84',
    TZ: 'America/Chicago'
};
// live stripe
// STRIPE_PUBLIC_KEY:"pk_live_51PnmzRAR0x1O0G6Tgr0dx4h42xlIowkgordLCfx8EMC0WQ8YiY906nTQYlMFpsxluur8mHCgmgx0MHHuqqSpwyXt00GRZzOJS0",
// hassan stripe
// STRIPE_PUBLIC_KEY:"pk_test_51PV893IvLqrQ4GV3ZWgFAeG8lP2sRLjobO7Zwmzc83FqBRmMfHVTZ3BOV7jNBFnvEODGUHcIDjamQiy1f5ygQzTg00ZSetkSoy",

// azeem stripe
// STRIPE_PUBLIC_KEY:"pk_test_51QyWWGGdvQ5s3sguPpoX0yEgos7mVZjgHvIFVaHP7Nh2mNfrtp1UtTu9MknFmvvNeKhD0fYc8ZygymGIROhWuqoA00KspFy7wT"
// TZ: 'America/New_York'
// TZ: 'Asia/Karachi'
// TZ: 'America/Chicago'
// TZ: 'America/Denver'
// TZ: 'America/Los_Angeles'
// TZ: 'America/Anchorage'
// TZ: 'Pacific/Honolulu'
// TZ: 'Europe/London'
// TZ: 'Europe/Paris'
// TZ: 'Asia/Tokyo'
// TZ: 'Australia/Sydney'
