  <!-- Invoices Table -->
  <div class="mt-8">
    <div class="bg-white rounded-xl shadow-sm overflow-hidden">
      <div class="overflow-x-auto">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                User Details
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Plan Info
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Amount
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Limits
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Duration
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr *ngFor="let invoice of invoices" 
                class="hover:bg-gray-50 transition-colors duration-200">
              <!-- User Details -->
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="flex items-center">
                  <div class="bg-[#eb764115] p-2 rounded-full mr-3">
                    <i class="ri-user-line text-[#eb7641]"></i>
                  </div>
                  <div>
                    <div class="text-sm font-medium text-gray-900">
                      {{invoice?.userDetails?.firstName}} {{invoice?.userDetails?.lastName}}
                    </div>
                    <div class="text-sm text-gray-500">
                      {{invoice?.userDetails?.email}}
                    </div>
                  </div>
                </div>
              </td>

              <!-- Plan Info -->
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm font-medium text-gray-900">
                  {{invoice?.subscriptionDetails?.planName}}
                </div>
              </td>

              <!-- Amount -->
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm font-semibold text-gray-900">
                  ${{invoice?.amount}}
                </div>
              </td>

              <!-- Status -->
              <td class="px-6 py-4 whitespace-nowrap">
                <span class="px-3 py-1 inline-flex text-sm leading-5 font-semibold rounded-full"
                      [ngClass]="{
                        'bg-green-100 text-green-800': invoice?.status === 'paid',
                        'bg-red-100 text-red-800': invoice?.status === 'failed'
                      }">
                  {{invoice?.status}}
                </span>
              </td>

              <!-- Limits -->
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-600">
                  <div class="flex items-center gap-2">
                    <i class="ri-advertisement-line"></i>
                    Campaign: {{invoice?.subscriptionDetails?.campaignLimit}}
                  </div>
                  <div class="flex items-center gap-2">
                    <i class="ri-map-pin-line"></i>
                    Location: {{invoice?.subscriptionDetails?.locationLimit}}
                  </div>
                  <div class="flex items-center gap-2">
                    <i class="ri-video-line"></i>
                    Video Time: {{invoice?.subscriptionDetails?.adVedioTimeLimit}}
                  </div>
                  <div class="flex items-center gap-2">
                    <i class="ri-timer-line"></i>
                    Campaign Time: {{invoice?.subscriptionDetails?.adCampaignTimeLimit}}
                  </div>
                  <div class="flex items-center gap-2">
                    <i class="ri-flag-line"></i>
                    Priority: {{invoice?.subscriptionDetails?.priority}}
                  </div>
                  <div class="flex items-center gap-2">
                    <i class="ri-radar-line"></i>
                    Allowed Radius: {{invoice?.subscriptionDetails?.allowedRadius}}
                  </div>
                  
                </div>
              </td>

              <!-- Duration -->
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="space-y-2 bg-gradient-to-r from-gray-50 to-white p-3 rounded-lg shadow-sm border border-gray-100">
                  <div class="flex items-center text-sm text-gray-700 transition-all hover:text-gray-900">
                    <i class="ri-calendar-line mr-2 text-[#eb7641]"></i>
                    <span class="font-medium">Start:</span>
                    <span class="ml-2">{{invoice?.startDate | date:'mediumDate'}}</span>
                  </div>
                  <div class="flex items-center text-sm text-gray-700 transition-all hover:text-gray-900">
                    <i class="ri-calendar-check-line mr-2 text-[#eb7641]"></i>
                    <span class="font-medium">End:</span>
                    <span class="ml-2">{{invoice?.endDate | date:'mediumDate'}}</span>
                  </div>
                  <div class="flex items-center mt-2">
                    <span class="px-3 py-1 text-sm font-semibold bg-orange-100 text-[#eb7641] rounded-full">
                      <i class="ri-time-line mr-1"></i>
                      {{invoice?.subscriptionDetails?.duration}} month
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  