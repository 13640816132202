import { Component, OnInit } from '@angular/core';
import { CampaignService } from 'src/app/shared/service/campaign/campaign.service';
import { ToastrService } from 'ngx-toastr';
import { animate, style, transition, trigger } from '@angular/animations';
import { NavService } from 'src/app/shared/service/navbar/nav.service';

@Component({
  selector: 'app-customer-campaigns',
  templateUrl: './customer-campaigns.component.html',
  styleUrls: ['./customer-campaigns.component.scss'],
  animations: [
    trigger('fadeSlide', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('0.3s ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ])
  ]
})
export class CustomerCampaignsComponent implements OnInit {
  campaigns: any[] = [];
  loading: boolean = false;
  searchTerm: string = '';
  filterStatus: string = 'pending';
  selectedCampaign: any = null;
  rejectionReason: string = '';
  showRejectionModal: boolean = false;
  campaignStats = {
    total: 0,
    pending: 0,
    active: 0,
    completed: 0,
    rejected: 0
  };
  approvingCampaignId: string | null = null;
  rejectingCampaign: boolean = false;

  constructor(
    private campaignService: CampaignService,
    private toastr: ToastrService,
    private navService: NavService
  ) {}

  ngOnInit() {
    this.getCampaigns();
    this.navService.setTitle('Campaign Review');
    this.navService.setSubtitle('Review and manage customer campaign submissions');
  }

  getCampaigns() {
    this.loading = true;
    this.campaignService.getCampaignsToReview(this.filterStatus).subscribe({
      next: (response) => {
        this.campaigns = response.data;
        this.updateCampaignStats();
        this.loading = false;
      },
      error: (error) => {
        this.toastr.error('Failed to load campaigns');
        this.loading = false;
      }
    });
  }

  updateCampaignStats() {
    this.campaignStats = {
      total: this.campaigns.length,
      pending: this.campaigns.filter(c => c.status === 'pending').length,
      active: this.campaigns.filter(c => c.status === 'active').length,
      completed: this.campaigns.filter(c => c.status === 'completed').length,
      rejected: this.campaigns.filter(c => c.status === 'rejected').length
    };
  }

  approveCampaign(campaignId: string) {
    this.approvingCampaignId = campaignId;
    this.campaignService.approveCampaign(campaignId).subscribe({
      next: () => {
        this.toastr.success('Campaign approved successfully');
        this.getCampaigns();
      },
      error: () => {
        this.toastr.error('Failed to approve campaign');
      },
      complete: () => {
        this.approvingCampaignId = null;
      }
    });
  }

  openRejectionModal(campaign: any) {
    this.selectedCampaign = campaign;
    this.showRejectionModal = true;
    console.log("selectedCampaign openRejectionModal:",this.selectedCampaign);
  }

  rejectCampaign() {
    if (!this.rejectionReason.trim()) {
      this.toastr.error('Please provide a rejection reason');
      return;
    }

    if (!this.selectedCampaign?._id) {
      this.toastr.error('Campaign ID is missing');
      return;
    }

    this.rejectingCampaign = true;
    this.campaignService.rejectCampaign(
      this.selectedCampaign._id,
      this.rejectionReason.trim()
    ).subscribe({
      next: (response) => {
        this.toastr.success('Campaign rejected successfully');
        this.closeRejectionModal();
        this.getCampaigns();
      },
      error: (error) => {
        console.error('Rejection error:', error);
        this.toastr.error(error.message || 'Failed to reject campaign');
      },
      complete: () => {
        this.rejectingCampaign = false;
      }
    });
  }

  closeRejectionModal() {
    this.showRejectionModal = false;
    this.selectedCampaign = null;
    this.rejectionReason = '';
  }

  getStatusClass(status: string): string {
    const classes: { [key: string]: string } = {
      pending: 'bg-yellow-100 text-yellow-800',
      active: 'bg-green-100 text-green-800',
      scheduled: 'bg-blue-100 text-blue-800',
      completed: 'bg-gray-100 text-gray-800',
      rejected: 'bg-red-100 text-red-800'
    };
    return classes[status] || 'bg-gray-100 text-gray-800';
  }

  refreshCampaigns() {
    this.loading = true;
    this.getCampaigns();
  }
}