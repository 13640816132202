<!-- Loading State -->
<div *ngIf="loading" class="flex items-center justify-center min-h-[400px]">
  <div class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#eb7641]"></div>
</div>

<!-- Edit Location Form -->
<div *ngIf="!loading" class="container mx-auto px-4 py-8">
  <!-- Step 1: Basic Information -->
  <div *ngIf="currentStep === 1">
    <div class="bg-white rounded-xl shadow-sm p-6">
      <h2 class="text-2xl font-semibold mb-6">Edit Location Details</h2>
      
      <form [formGroup]="editLocationForm" class="space-y-6">
        <!-- Location Name -->
        <div>
          <label for="locationName" class="block text-sm font-medium text-gray-700">Location Name</label>
          <input
            type="text"
            id="locationName"
            formControlName="locationName"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#eb7641] focus:ring-[#eb7641]"
            [ngClass]="{'border-red-500': editLocationForm.get('locationName')?.invalid && editLocationForm.get('locationName')?.touched}"
          >
          <p *ngIf="editLocationForm.get('locationName')?.invalid && editLocationForm.get('locationName')?.touched" class="mt-1 text-sm text-red-500">
            Location name is required and must be at least 5 characters
          </p>
        </div>

        <!-- Country -->
        <div>
          <label for="country" class="block text-sm font-medium text-gray-700">Country</label>
          <select
            id="country"
            formControlName="country"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#eb7641] focus:ring-[#eb7641]"
            [disabled]="true"
          >
            <option value="US">United States</option>
        </select>
        </div>

        <!-- State -->
        <div>
          <label for="state" class="block text-sm font-medium text-gray-700">State</label>
          <select
            id="state"
            formControlName="state"
            (change)="onStateChange($event)"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#eb7641] focus:ring-[#eb7641]"
          >
            <option value="">Select a state</option>
            <option *ngFor="let state of states" [value]="state.code">{{state.name}}</option>
          </select>
          <p *ngIf="editLocationForm.get('state')?.invalid && editLocationForm.get('state')?.touched" class="mt-1 text-sm text-red-500">
            Please select a state
          </p>
        </div>

        <!-- Navigation Buttons -->
        <div class="flex justify-between pt-4">
          <button
            type="button"
            (click)="router.navigate(['/dashboard/location'])"
            class="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="nextStep()"
            [disabled]="!editLocationForm.valid"
            class="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#eb7641] hover:bg-[#d66937] disabled:opacity-50"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  </div>

  <!-- Step 2: Map Location -->
  <div *ngIf="currentStep === 2">
    <div class="bg-white rounded-xl shadow-sm p-6">
      <h2 class="text-2xl font-semibold mb-6">Select Location on Map</h2>
      
      <!-- Map Container -->
      <div class="w-full h-[500px] rounded-lg overflow-hidden mb-6">
        <google-map
          height="100%"
          width="100%"
          [options]="mapOptions"
          [center]="center"
          (mapClick)="onMapClick($event)"
        >
          <map-marker
            *ngIf="markerPosition"
            [position]="markerPosition"
            [options]="{ draggable: true }"
          ></map-marker>
          <map-circle
            *ngIf="markerPosition"
            [center]="markerPosition"
            [options]="circleOptions"
          ></map-circle>
        </google-map>
      </div>

      <!-- Radius Control -->
      <div class="mb-6">
        <label for="radius" class="block text-sm font-medium text-gray-700">Coverage Radius (meters)</label>
        <input
          type="range"
          id="radius"
          [min]="minRadius"
          [max]="maxRadius"
          [value]="radiusValue"
          (input)="onRadiusInput($event)"
          class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
        >
        <div class="flex justify-between text-sm text-gray-500 mt-2">
          <span>{{minRadius}}m</span>
          <span>Current: {{radiusValue}}m</span>
          <span>{{maxRadius}}m</span>
        </div>
      </div>

      <!-- Navigation Buttons -->
      <div class="flex justify-between">
        <button
          type="button"
          (click)="previousStep()"
          class="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          type="button"
          (click)="onSubmit()"
          [disabled]="!selectedLocation"
          class="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#eb7641] hover:bg-[#d66937] disabled:opacity-50"
        >
          Update Location
        </button>
      </div>
    </div>
  </div>
</div>
