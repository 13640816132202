<div class="h-screen flex justify-center bg-gradient-to-br from-orange-50 to-gray-50 p-4">
    <div class="max-w-12xl w-full bg-white rounded-2xl shadow-2xl overflow-hidden flex">
        <div class="w-full lg:w-1/2 p-8 md:px-12 lg:p-12 lg:px-24 flex flex-col justify-center">
            <div class="space-y-6">
                <div>
                    <h2 class="text-3xl font-bold text-[#474452]">ShowYourAdz</h2>
                    <p class="mt-2 text-gray-600">Set a new password for your account</p>
                </div>

                <form class="space-y-6" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                    <div class="space-y-4">
                        <div>
                            <div class="relative">
                                <input [type]="showPassword ? 'text' : 'password'" formControlName="password"
                                    class="w-full px-4 py-3 rounded-lg border border-gray-200 focus:border-[#eb7641] focus:ring-2 focus:ring-orange-200 transition-all duration-200 bg-gray-50"
                                    placeholder="New Password">
                                <button type="button" 
                                    (click)="togglePasswordVisibility()" 
                                    class="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700">
                                    <svg *ngIf="!showPassword" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                    <svg *ngIf="showPassword" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                    </svg>
                                </button>
                              </div>
                              <div *ngIf="resetPasswordForm.get('password')?.invalid && (resetPasswordForm.get('password')?.touched || resetPasswordForm.get('password')?.dirty)"
                                  class="text-rose-500 text-sm mt-1 ml-1">
                                  Password is required
                              </div>
                        </div>

                        <div>
                            <div class="relative">
                                <input [type]="showConfirmPassword ? 'text' : 'password'" formControlName="confirmPassword"
                                    class="w-full px-4 py-3 rounded-lg border border-gray-200 focus:border-[#eb7641] focus:ring-2 focus:ring-orange-200 transition-all duration-200 bg-gray-50"
                                    placeholder="Confirm Password">
                                <button type="button" 
                                    (click)="toggleConfirmPasswordVisibility()" 
                                    class="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700">
                                    <svg *ngIf="!showConfirmPassword" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                    <svg *ngIf="showConfirmPassword" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                    </svg>
                                </button>
                              </div>
                              <div *ngIf="resetPasswordForm.hasError('mismatch') && (resetPasswordForm.get('confirmPassword')?.touched || resetPasswordForm.get('confirmPassword')?.dirty)"
                                  class="text-rose-500 text-sm mt-1 ml-1">
                                  Passwords must match
                              </div>
                        </div>
                    </div>

                    <button type="submit"
                        class="w-full py-3 px-4 bg-[#eb7641] text-white rounded-lg hover:bg-[#474452] transition-colors duration-200 flex items-center justify-center"
                        [disabled]="resetPasswordForm.invalid">
                        Set New Password
                    </button>

                    <div class="text-center">
                        <a routerLink="/login" class="inline-flex items-center text-[#eb7641] hover:text-orange-800">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                            </svg>
                            Back to Login
                        </a>
                    </div>
                </form>
            </div>
        </div>

        <div class="hidden lg:flex w-1/2 relative items-center justify-center">
          <img src="assets/images/setpassword-image.png" 
               alt="ContactUs tailwind section" 
               class="w-[70%] h-[70%] object-contain"
               appImageNotFound>
      </div>
    </div>
</div>