  <div class="w-full min-h-screen">
    <!-- Added Section -->
    <div class="flex flex-col sm:flex-row justify-end items-start sm:items-center gap-6 mt-8 mb-6">
      <div class="w-full sm:w-auto">
        <select 
          name="filterStatus"
          [ngModel]="filterStatus"
          (ngModelChange)="filterStatus = $event"
          (change)="getCampaigns()"
          class="w-full sm:w-auto px-4 py-2.5 border rounded-lg ring-2 ring-[#eb7641] border-transparent focus:ring-2 focus:ring-[#eb7641] focus:border-transparent">
          <option value="all">All Campaigns</option>
          <option value="pending">Pending</option>
          <option value="approved">Approved</option>
          <option value="completed">Completed</option>
          <option value="rejected">Rejected</option>
        </select>
      </div>
    </div>

    <!-- Quick Stats Bar -->
    <div class="flex flex-wrap sm:flex-nowrap gap-3 sm:gap-4 mb-8">
      <!-- Pending Stats -->
      <div [ngClass]="{'bg-[#eb7641] shadow-md': filterStatus === 'pending', 'bg-white/80': filterStatus !== 'pending'}" 
           class="flex-1 min-w-[150px] flex items-center gap-3 backdrop-blur-sm px-4 py-3 rounded-full shadow-sm hover:shadow-md transition-all duration-300 cursor-default">
        <div class="flex items-center gap-2.5">
          <div class="w-2.5 h-2.5 rounded-full bg-yellow-400 animate-pulse"></div>
          <span [ngClass]="{'text-white': filterStatus === 'pending', 'text-gray-700': filterStatus !== 'pending'}" 
                class="text-sm font-medium">Pending</span>
        </div>
        <div class="ml-auto">
          <span [ngClass]="{'text-white': filterStatus === 'pending', 'text-yellow-400': filterStatus !== 'pending'}" 
                class="text-base font-semibold">{{campaignStats.pending}}</span>
        </div>
      </div>
      
      <!-- Active Stats -->
      <div [ngClass]="{'bg-[#eb7641] shadow-md': filterStatus === 'approved', 'bg-white/80': filterStatus !== 'approved'}" 
           class="flex-1 min-w-[150px] flex items-center gap-3 backdrop-blur-sm px-4 py-3 rounded-full shadow-sm hover:shadow-md transition-all duration-300 cursor-default">
        <div class="flex items-center gap-2.5">
          <div class="w-2.5 h-2.5 rounded-full bg-green animate-pulse"></div>
          <span [ngClass]="{'text-white': filterStatus === 'approved', 'text-gray-700': filterStatus !== 'approved'}" 
                class="text-sm font-medium">Approved</span>
        </div>
        <div class="ml-auto">
          <span [ngClass]="{'text-white': filterStatus === 'approved', 'text-green': filterStatus !== 'approved'}" 
                class="text-base font-semibold">{{campaignStats.approved}}</span>
        </div>
      </div>
      
      <!-- Completed Stats -->
      <div [ngClass]="{'bg-[#eb7641] shadow-md': filterStatus === 'completed', 'bg-white/80': filterStatus !== 'completed'}" 
           class="flex-1 min-w-[150px] flex items-center gap-3 backdrop-blur-sm px-4 py-3 rounded-full shadow-sm hover:shadow-md transition-all duration-300 cursor-default">
        <div class="flex items-center gap-2.5">
          <div class="w-2.5 h-2.5 rounded-full bg-blue-400 animate-pulse"></div>
          <span [ngClass]="{'text-white': filterStatus === 'completed', 'text-gray-700': filterStatus !== 'completed'}" 
                class="text-sm font-medium">Completed</span>
        </div>
        <div class="ml-auto">
          <span [ngClass]="{'text-white': filterStatus === 'completed', 'text-blue-600': filterStatus !== 'completed'}" 
                class="text-base font-semibold">{{campaignStats.completed}}</span>
        </div>
      </div>
      
      <!-- Rejected Stats -->
      <div [ngClass]="{'bg-[#eb7641] shadow-md': filterStatus === 'rejected', 'bg-white/80': filterStatus !== 'rejected'}" 
           class="flex-1 min-w-[150px] flex items-center gap-3 backdrop-blur-sm px-4 py-3 rounded-full shadow-sm hover:shadow-md transition-all duration-300 cursor-default">
        <div class="flex items-center gap-2.5">
          <div class="w-2.5 h-2.5 rounded-full bg-red animate-pulse"></div>
          <span [ngClass]="{'text-white': filterStatus === 'rejected', 'text-gray-700': filterStatus !== 'rejected'}" 
                class="text-sm font-medium">Rejected</span>
        </div>
        <div class="ml-auto">
          <span [ngClass]="{'text-white': filterStatus === 'rejected', 'text-red': filterStatus !== 'rejected'}" 
                class="text-base font-semibold">{{campaignStats.rejected}}</span>
        </div>
      </div>
    </div>

    <!-- Loading Spinner -->
    <app-spinner *ngIf="loading"></app-spinner>

    <!-- Campaigns Grid -->
    <div *ngIf="!loading" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <div *ngFor="let campaign of campaigns" 
           class="group bg-white rounded-2xl shadow-sm hover:shadow-xl transition-all duration-300 overflow-hidden">
        <!-- Media Preview -->
        <div class="relative aspect-video">
          <img *ngIf="campaign.mediaType === 'image'" 
               [src]="campaign.mediaUrl" 
               [alt]="campaign.campaignName"
               class="w-full h-full object-cover"
               appImageNotFound>
          <video *ngIf="campaign.mediaType === 'video'" 
                 [src]="campaign.mediaUrl" 
                 class="w-full h-full object-cover"
                 controls></video>
          
          <!-- Media Type Badge -->
          <div class="absolute top-4 left-4 px-3 py-1.5 rounded-full text-xs font-medium bg-black/50 text-white backdrop-blur-sm flex items-center gap-1.5">
            <i [class.ri-image-line]="campaign.mediaType === 'image'"
               [class.ri-video-line]="campaign.mediaType === 'video'"></i>
            {{campaign.mediaType | titlecase}}
          </div>

          <!-- Status Badge -->
          <div [@statusChange]="campaign.status"
               [ngClass]="getStatusClass(campaign.status) + ' absolute top-4 right-4 px-3 py-1.5 rounded-full text-xs font-medium backdrop-blur-sm'">
            {{campaign.status | titlecase}}
          </div>
        </div>

        <!-- Campaign Info -->
        <div class="p-6">
          <!-- Campaign Header -->
          <div class="flex items-start justify-between mb-4">
            <div>
              <h3 class="text-lg font-bold text-gray-800 line-clamp-1">{{campaign.campaignName}}</h3>
              <p class="text-sm text-gray-500">ID: {{campaign._id | slice:0:8}}</p>
            </div>
            <span class="bg-[#eb7641]/10 text-[#eb7641] px-3 py-1 rounded-lg text-sm font-medium ml-auto">
              <span class="inline-block" [@pulseAnimation]="campaign.runCycleCount">
                {{campaign.runCycleCount || 0}}
              </span> Cycles
            </span>
          </div>

          <!-- User Info -->
          <div class="flex items-center gap-3 p-3 bg-gray-50 rounded-lg mb-4">
            <div class="w-10 h-10 bg-[#eb7641] rounded-full flex items-center justify-center text-white font-medium">
              {{campaign.userId?.firstName?.charAt(0)}}
            </div>
            <div>
              <p class="font-medium">{{campaign.userId?.firstName}} {{campaign.userId?.lastName}}</p>
              <p class="text-sm text-gray-500">User ID: {{campaign.userId?._id | slice:0:8}}</p>
            </div>
          </div>

          <!-- Campaign Details -->
          <div class="grid grid-cols-2 gap-3 mb-4">
            <!-- Date Range -->
            <div class="bg-gray-50 p-3 rounded-xl">
              <div class="flex items-center gap-2 text-gray-500 mb-1.5 text-xs">
                <i class="ri-calendar-line"></i>
                <span>Campaign Period</span>
              </div>
              <div class="text-sm font-medium text-gray-800">
                <div>From: {{campaign.startDateTime | date:'MMM d, y'}}</div>
                <div>To: {{campaign.endDateTime | date:'MMM d, y'}}</div>
              </div>
            </div>

            <!-- Locations -->
            <div class="bg-gray-50 p-3 rounded-xl">
              <div class="flex items-center gap-2 text-gray-500 mb-1.5 text-xs">
                <i class="ri-map-pin-line"></i>
                <span>Locations</span>
              </div>
              <div class="text-sm font-medium text-gray-800">
                <span>{{campaign.selectedLocations?.length}}</span>
                <span class="text-sm ml-1">total</span>
              </div>
              <div class="text-xs mt-1">
                {{campaign.selectedLocations | slice:0:2 | map:'state' | join:', '}}
              </div>
            </div>
          </div>

          <!-- Action Buttons -->
          <div class="flex flex-col gap-3">
            <button 
              [routerLink]="['/dashboard/admin-campaign-details', campaign._id]"
              class="w-full px-4 py-2.5 bg-[#eb7641]/10 text-[#eb7641] rounded-lg hover:bg-[#eb7641] hover:text-white transition-all duration-300 font-medium flex items-center justify-center gap-2">
              <i class="ri-eye-line"></i>
              View Details
            </button>
            
            <div class="flex gap-3" *ngIf="campaign.status === 'pending'">
              <button 
                (click)="approveCampaign(campaign._id)"
                [disabled]="approvingCampaignId === campaign._id"
                class="flex-1 px-4 py-2.5 bg-[#eb7641] text-white rounded-lg hover:bg-[#d66937] transition-colors flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed">
                <i class="ri-loader-4-line animate-spin" *ngIf="approvingCampaignId === campaign._id"></i>
                <i class="ri-check-line" *ngIf="approvingCampaignId !== campaign._id"></i>
                {{ approvingCampaignId === campaign._id ? 'Approving...' : 'Approve' }}
              </button>
              <button 
                (click)="openRejectionModal(campaign)"
                [disabled]="approvingCampaignId === campaign._id"
                class="flex-1 px-4 py-2.5 bg-[#eb7641]/10 text-[#eb7641] rounded-lg hover:bg-[#eb7641] hover:text-white transition-all duration-300 font-medium flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed">
                <i class="ri-close-line"></i>
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Empty State -->
    <div *ngIf="!loading && campaigns.length === 0" 
         class="bg-white rounded-2xl shadow-sm p-12 text-center">
      <div class="max-w-md mx-auto">
        <div class="w-20 h-20 bg-[#eb7641]/10 rounded-2xl flex items-center justify-center mx-auto mb-6">
          <i class="ri-advertisement-line text-[#eb7641] text-3xl"></i>
        </div>
        <h3 class="text-2xl font-bold text-gray-800 mb-3">No Campaigns Found</h3>
        <p class="text-gray-500">There are no campaigns matching your criteria</p>
      </div>
    </div>
  </div>

<!-- Rejection Modal -->
<div *ngIf="showRejectionModal" 
     class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
  <div class="bg-white rounded-xl p-6 w-full max-w-md mx-4">
    <div class="flex justify-between items-center mb-4">
      <h3 class="text-xl font-bold">Reject Campaign</h3>
      <button (click)="closeRejectionModal()" class="text-gray-400 hover:text-gray-600">
        <i class="ri-close-line text-xl"></i>
      </button>
    </div>
    
    <div class="mb-4">
      <p class="text-sm text-gray-500 mb-2">Campaign: {{selectedCampaign?.campaignName}}</p>
      <textarea 
        [(ngModel)]="rejectionReason"
        name="rejectionReason"
        placeholder="Please provide a reason for rejection..."
        class="w-full p-3 border rounded-lg mb-4 h-32 resize-none focus:ring-2 focus:ring-[#eb7641] focus:border-transparent">
      </textarea>
    </div>

    <div class="flex justify-end gap-3">
      <button 
        (click)="closeRejectionModal()"
        [disabled]="rejectingCampaign"
        class="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed">
        Cancel
      </button>
      <button 
        (click)="rejectCampaign()"
        [disabled]="rejectingCampaign"
        class="px-4 py-2 bg-[#eb7641] text-white rounded-lg hover:bg-[#d66937] transition-colors flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed">
        <i class="ri-loader-4-line animate-spin" *ngIf="rejectingCampaign"></i>
        {{ rejectingCampaign ? 'Rejecting...' : 'Confirm Rejection' }}
      </button>
    </div>
  </div>
</div>