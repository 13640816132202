<div class="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-3 lg:gap-4 mb-4 lg:mb-6">
  <div class="flex items-center space-x-3 lg:space-x-5 w-full lg:w-auto">
    <!-- Enhanced Text Content with Animation -->
    <div class="relative w-full lg:w-auto">
      <div class="space-y-0.5 sm:space-y-1">
        <div class="flex items-center space-x-3">
          <h1 class="text-xl sm:text-2xl font-bold bg-gradient-to-r from-gray-800 to-gray-600 bg-clip-text text-transparent truncate">
            {{title}}
          </h1>
        </div>
        <div class="flex items-center">
          <p class="text-xs sm:text-sm text-gray-500 font-medium truncate">
            {{subtitle || ''}}
          </p>
        </div>
      </div>
      
      <!-- Decorative Background Element -->
      <div class="absolute -inset-1 bg-gradient-to-r from-[#eb7641]/5 to-[#d66937]/5 rounded-lg blur-lg -z-10"></div>
    </div>
  </div>

  <!-- New Notifications Section -->
  <div class="flex items-center space-x-3 sm:space-x-6 w-full sm:w-auto justify-end">
    <!-- Search Bar -->
    <div class="relative flex-1 sm:flex-none">
      <input type="text" 
             placeholder="Search..." 
             class="w-full sm:w-64 px-3 sm:px-4 py-2 bg-white rounded-xl border border-gray-200 
                    focus:border-[#eb7641]/30 focus:outline-none focus:ring-2 focus:ring-[#eb7641]/20 
                    transition-all duration-300 text-sm">
      <i class="ri-search-line absolute right-3 top-1/2 -translate-y-1/2 text-gray-400"></i>
    </div>

    <!-- Notifications Dropdown -->
    <div class="relative">
      <button (click)="toggleNotifications($event)" 
              class="relative p-2 hover:bg-white rounded-xl transition-all duration-300 group">
        <i class="ri-notification-3-line text-lg sm:text-xl text-gray-600 group-hover:text-[#eb7641]"></i>
        <!-- Notification Badge -->
        <span *ngIf="unreadCount > 0" 
              class="absolute -top-1 -right-1 w-4 sm:w-5 h-4 sm:h-5 bg-[#eb7641] text-white text-[10px] sm:text-xs 
                     flex items-center justify-center rounded-full border-2 border-white">
          {{unreadCount}}
        </span>
      </button>
      
      <!-- Notifications Dropdown Panel -->
      <div *ngIf="isNotificationsOpen"
           class="absolute right-0 mt-2 w-[calc(100vw-2rem)] sm:w-80 bg-white rounded-2xl shadow-xl border border-gray-100 
                  z-50 transform origin-top transition-all duration-300 animate-fadeIn">
        <!-- Notifications Header -->
        <div class="px-3 sm:px-4 py-2.5 sm:py-3 border-b border-gray-100 flex items-center justify-between">
          <h3 class="font-semibold text-gray-800 text-sm sm:text-base">Notifications</h3>
          <button *ngIf="unreadCount > 0" 
                  (click)="markAllAsRead()"
                  class="text-[10px] sm:text-xs text-[#eb7641] hover:text-[#d66937] font-medium">
            Mark all as read
          </button>
        </div>

        <!-- Notifications List -->
        <div class="max-h-[280px] sm:max-h-[320px] overflow-y-auto">
          <div *ngFor="let notification of notifications"
               class="px-3 sm:px-4 py-2.5 sm:py-3 hover:bg-gray-50 flex items-start space-x-2 sm:space-x-3 cursor-pointer"
               [class.border-l-4]="!notification.read"
               [routerLink]="notification.link"
               (click)="markAsRead(notification._id)"
               [class.border-[#eb7641]]="!notification.read">
            <div class="w-7 sm:w-8 h-7 sm:h-8 rounded-lg flex items-center justify-center flex-shrink-0"
                 [class.bg-[#eb7641]]="!notification.read"
                 [class.bg-gray-100]="notification.read">
              <i [class]="getNotificationIcon(notification.type)"
                 [class.text-[#eb7641]]="!notification.read"
                 [class.text-gray-600]="notification.read"></i>
            </div>
            <div class="flex-1 min-w-0">
              <p class="text-xs sm:text-sm" 
                 [class.text-gray-800]="!notification.read" 
                 [class.font-medium]="!notification.read" 
                 [class.text-gray-600]="notification.read">
                {{notification.title}}
              </p>
              <p class="text-[10px] sm:text-xs text-gray-500 mt-0.5">{{notification.message}}</p>
              <span class="text-[10px] sm:text-xs text-gray-400 mt-1">{{getTimeAgo(notification.createdAt)}}</span>
            </div>
          </div>

          <div *ngIf="notifications.length === 0" class="px-4 py-6 text-center text-gray-500 text-xs sm:text-sm">
            No notifications yet
          </div>
        </div>
      </div>
    </div>

    <!-- User Profile -->
    <div class="relative">
      <button (click)="toggleProfileDropdown($event)" 
              class="flex items-center space-x-2 sm:space-x-3 p-2 hover:bg-white rounded-xl transition-all duration-300 group">
        <div class="w-8 sm:w-10 h-8 sm:h-10 rounded-xl bg-gradient-to-br from-[#eb7641]/10 to-[#d66937]/10 flex items-center justify-center">
          <span class="text-[#eb7641] font-semibold text-sm sm:text-base">{{user?.firstName?.charAt(0) || ''}}{{user?.lastName?.charAt(0) || ''}}</span>
        </div>
        <div class="text-left hidden sm:block">
          <p class="text-sm font-medium text-gray-700 group-hover:text-[#eb7641] truncate max-w-[120px]">{{user?.firstName}} {{user?.lastName}}</p>
          <p class="text-xs text-gray-500 truncate max-w-[120px]">{{user?.email}}</p>
        </div>
        <i class="ri-arrow-down-s-line text-gray-400 group-hover:text-[#eb7641]"
           [class.rotate-180]="isProfileDropdownOpen"
           class="transition-transform duration-300"></i>
      </button>

      <!-- Profile Dropdown Menu -->
      <div *ngIf="isProfileDropdownOpen"
           class="absolute right-0 mt-2 w-[calc(100vw-2rem)] sm:w-64 bg-white rounded-2xl shadow-xl border border-gray-100 
                  z-50 transform origin-top transition-all duration-300 animate-fadeIn">
        <div class="p-3 sm:p-4 border-b border-gray-100">
          <p class="font-medium text-gray-800 text-sm sm:text-base truncate">{{user?.firstName}} {{user?.lastName}}</p>
          <p class="text-xs sm:text-sm text-gray-500 truncate">{{user?.email}}</p>
        </div>
        
        <div class="py-1 sm:py-2">
          <a routerLink="/dashboard/profile" 
             class="flex items-center space-x-3 px-3 sm:px-4 py-2.5 sm:py-3 hover:bg-gray-50 transition-colors">
            <i class="ri-user-settings-line text-gray-400"></i>
            <span class="text-xs sm:text-sm text-gray-700">Account Settings</span>
          </a>
          <button type="button" 
                  (click)="logout()" 
                  class="flex w-full items-center space-x-3 px-3 sm:px-4 py-2.5 sm:py-3 hover:bg-gray-50 transition-colors cursor-pointer">
            <i class="ri-logout-box-line text-gray-400"></i>
            <span class="text-xs sm:text-sm text-gray-700">Logout</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>