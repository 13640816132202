  <div class="w-full min-h-screen">
    <app-goback></app-goback>

    <div class="mt-2" *ngIf="!loading && campaign" @fadeSlide>
      <!-- Header Section -->

      
      <!-- Media Preview Card -->
      <div class="bg-white rounded-2xl shadow-sm overflow-hidden mb-8 group">
        <div class="relative aspect-video">
          <img *ngIf="campaign.mediaType === 'image'" 
               [src]="campaign.mediaUrl" 
               [alt]="campaign.campaignName"
               class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
               appImageNotFound>
          <video *ngIf="campaign.mediaType === 'video'" 
                 [src]="campaign.mediaUrl" 
                 class="w-full h-full object-cover"
                 controls></video>
          
          <div class="absolute top-4 left-4 px-4 py-2 rounded-full text-sm font-medium bg-black/50 text-white backdrop-blur-sm flex items-center gap-2">
            <i [class]="campaign.mediaType === 'image' ? 'ri-image-line' : 'ri-video-line'"></i>
            {{campaign.mediaType | titlecase}}
          </div>
        </div>
      </div>

      <!-- Info Grid -->
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        <!-- Campaign Details -->
        <div class="bg-white p-6 rounded-2xl shadow-sm hover:shadow-md transition-all duration-300">
          <div class="flex items-center gap-3 mb-6">
            <div class="w-10 h-10 rounded-full bg-[#eb7641]/10 flex items-center justify-center">
              <i class="ri-advertisement-line text-[#eb7641] text-xl"></i>
            </div>
            <h3 class="text-lg font-semibold">Campaign Info</h3>
          </div>
          
          <div class="space-y-4">
            <div class="p-4 bg-gray-50 rounded-xl">
              <p class="text-sm text-gray-500 mb-1">Campaign Name</p>
              <p class="font-medium text-gray-800">{{campaign.campaignName}}</p>
            </div>
            <div class="p-4 bg-gray-50 rounded-xl">
              <p class="text-sm text-gray-500 mb-1">Duration</p>
              <div class="flex items-center gap-2 text-gray-800">
                <i class="ri-calendar-line text-[#eb7641]"></i>
                <p class="font-medium">{{campaign.startDateTime | date}} - {{campaign.endDateTime | date}}</p>
              </div>
            </div>
            <div class="p-4 bg-gray-50 rounded-xl">
              <p class="text-sm text-gray-500 mb-1">Run Cycle Count</p>
              <p class="font-medium text-gray-800">{{campaign.runCycleCount}}</p>
            </div>
          </div>
        </div>

        <!-- Customer Information -->
        <div class="bg-white p-6 rounded-2xl shadow-sm hover:shadow-md transition-all duration-300">
          <div class="flex items-center gap-3 mb-6">
            <div class="w-10 h-10 rounded-full bg-[#eb7641]/10 flex items-center justify-center">
              <i class="ri-user-line text-[#eb7641] text-xl"></i>
            </div>
            <h3 class="text-lg font-semibold">Customer Details</h3>
          </div>

          <div class="flex items-center gap-4 p-4 bg-gray-50 rounded-xl mb-4">
            <div class="w-12 h-12 bg-gradient-to-br from-[#eb7641] to-[#d66937] rounded-full flex items-center justify-center text-white font-semibold text-lg shadow-sm">
              {{campaign.userId?.firstName?.charAt(0)}}{{campaign.userId?.lastName?.charAt(0)}}
            </div>
            <div>
              <p class="font-medium text-gray-800">{{campaign.userId?.firstName}} {{campaign.userId?.lastName}}</p>
              <p class="text-sm text-gray-500">{{campaign.userId?.email}}</p>
            </div>
          </div>

          <div class="space-y-4">
            <div class="p-4 bg-gray-50 rounded-xl">
              <p class="text-sm text-gray-500 mb-1">Phone</p>
              <p class="font-medium text-gray-800">{{campaign.userId?.phoneNumber}}</p>
            </div>
            <div class="p-4 bg-gray-50 rounded-xl">
              <p class="text-sm text-gray-500 mb-1">Subscription Status</p>
              <div class="flex items-center gap-2">
                <span class="w-2 h-2 rounded-full" 
                      [ngClass]="campaign.userId?.subscriptionStatus === 'active' ? 'bg-green-400' : 'bg-red-400'">
                </span>
                <p class="font-medium text-gray-800 capitalize">{{campaign.userId?.subscriptionStatus}}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Location Information -->
        <div class="bg-white p-6 rounded-2xl shadow-sm hover:shadow-md transition-all duration-300">
          <div class="flex items-center gap-3 mb-6">
            <div class="w-10 h-10 rounded-full bg-[#eb7641]/10 flex items-center justify-center">
              <i class="ri-map-pin-line text-[#eb7641] text-xl"></i>
            </div>
            <h3 class="text-lg font-semibold">Location Details</h3>
          </div>

          <div class="space-y-4">
            <div *ngFor="let location of campaign.selectedLocations" 
                 class="p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors">
              <div class="flex items-center justify-between mb-3">
                <p class="font-medium text-gray-800">{{location.locationName}}</p>
                <span class="text-sm text-[#eb7641] font-medium">{{location.state}}</span>
              </div>
              <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
                <div class="text-sm">
                  <p class="text-gray-500">Latitude</p>
                  <p class="font-medium text-gray-700 break-all">{{location.latitude}}</p>
                </div>
                <div class="text-sm">
                  <p class="text-gray-500">Longitude</p>
                  <p class="font-medium text-gray-700 break-all">{{location.longitude}}</p>
                </div>
                <div class="text-sm">
                  <p class="text-gray-500">Radius</p>
                  <p class="font-medium text-gray-700">{{location.radius}}m</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Campaign Locations Map -->
      <div class="bg-white p-6 rounded-2xl shadow-sm mb-8">
        <div class="flex items-center justify-between mb-6">
          <div class="flex items-center gap-3">
            <div class="w-10 h-10 rounded-full bg-[#eb7641]/10 flex items-center justify-center">
              <i class="ri-map-2-line text-[#eb7641] text-xl"></i>
            </div>
            <h3 class="text-lg font-semibold">Campaign Location Map</h3>
          </div>
          <div class="flex items-center gap-4 text-sm">
            <div class="flex items-center gap-2">
              <span class="w-3 h-3 rounded-full bg-[#eb7641]"></span>
              <span>Selected Location</span>
            </div>
            <div class="flex items-center gap-2">
              <span class="w-3 h-3 rounded-full bg-black"></span>
              <span>Played Location</span>
            </div>
          </div>
        </div>
        
        <div class="w-full h-[400px] rounded-xl overflow-hidden">
          <google-map
            [center]="center"
            [zoom]="zoom"
            [options]="mapOptions"
            height="100%"
            width="100%"
            [@pulseAnimation]="locationUpdateCount">
            <!-- Selected Locations -->
            <map-marker
              *ngFor="let location of campaign.selectedLocations"
              [position]="getPosition(location)"
              [options]="selectedLocationMarkerOptions"
              [title]="location.locationName">
            </map-marker>
            
            <!-- Played Locations -->
            <map-marker
              *ngFor="let location of campaign.playedLocations"
              [@markerAnimation]
              [position]="{lat: location.latitude, lng: location.longitude}"
              [options]="getPlayedLocationMarkerOptions(location)"
              [title]="'Played at: ' + formatPlayedAt(location.playedAt)">
            </map-marker>
          </google-map>
        </div>
      </div>

      <!-- Add a floating notification for new locations -->
      <div *ngIf="recentlyUpdatedLocations.length > 0"
           class="fixed bottom-8 right-8 bg-[#eb7641] text-white px-6 py-4 rounded-lg shadow-lg"
           @fadeSlide>
        <div class="flex items-center gap-3">
          <i class="ri-map-pin-line text-xl"></i>
          <div>
            <p class="font-medium">New Location Update!</p>
            <p class="text-sm opacity-90">
              {{recentlyUpdatedLocations.length}} new location{{recentlyUpdatedLocations.length > 1 ? 's' : ''}} added
            </p>
          </div>
        </div>
      </div>

      <!-- Action Buttons -->
      <div class="flex flex-col sm:flex-row gap-4 justify-end" *ngIf="campaign.status === 'pending'">
        <button 
          (click)="openRejectionModal(campaign)"
          class="px-6 py-3.5 border-2 border-red-500 text-red-500 rounded-xl hover:bg-red-50 transition-all duration-300 flex items-center justify-center gap-2 font-medium">
          <i class="ri-close-line text-lg"></i>
          Reject Campaign
        </button>
        <button 
          (click)="approveCampaign(campaign._id)"
          class="px-6 py-3.5 bg-gradient-to-r from-[#eb7641] to-[#d66937] text-white rounded-xl hover:shadow-lg transition-all duration-300 flex items-center justify-center gap-2 font-medium">
          <i class="ri-check-line text-lg"></i>
          Approve Campaign
        </button>
      </div>
    </div>
  </div>

<!-- Rejection Modal -->
<div *ngIf="showRejectionModal" 
     class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
  <div class="bg-white rounded-xl p-6 w-full max-w-md mx-4">
    <div class="flex justify-between items-center mb-4">
      <h3 class="text-xl font-bold">Reject Campaign</h3>
      <button (click)="closeRejectionModal()" class="text-gray-400 hover:text-gray-600">
        <i class="ri-close-line text-xl"></i>
      </button>
    </div>
    
    <div class="mb-4">
      <p class="text-sm text-gray-500 mb-2">Campaign: {{selectedCampaign?.campaignName}}</p>
      <textarea 
        name="rejectionReason"
        [ngModel]="rejectionReason"
        (ngModelChange)="rejectionReason = $event"
        placeholder="Please provide a reason for rejection..."
        class="w-full p-3 border rounded-lg mb-4 h-32 resize-none focus:ring-2 focus:ring-[#eb7641] focus:border-transparent"></textarea>
    </div>

    <div class="flex justify-end gap-3">
      <button 
        (click)="closeRejectionModal()"
        class="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors">
        Cancel
      </button>
      <button 
        (click)="rejectCampaign()"
        class="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors">
        Confirm Rejection
      </button>
    </div>
  </div>
</div>