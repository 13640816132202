<div id="main" class="w-100 min-h-screen  ">

  <app-web-nav></app-web-nav>

  <div class="relative hero-section max-w-[1400px] mx-auto px-5 lg:px-10">
    <div class="my-12 hidden md:block">
      <h1 id="Bringing"
        class="text-[30px] sm:text-[40px] md:text-[50px] lg:text-[60px] xl:text-[70px] font-[700] leading-[1.1] sm:leading-[1.2] md:leading-[1.2] lg:leading-[1.2] xl:leading-[70px] text-[rgb(72,68,82)] font2 text-center tracking-[1px] sm:tracking-[2px] md:tracking-[3px]">
        Bringing Your Brand <br>to Real People</h1>
    </div>
    <!-- For Desktop -->
    <div class="hidden relative md:flex justify-between items-center">
      <!-- Spinner -->
      <div class="flex justify-start items-start absolute top-[20%] right-0 lg:relative lg:top-0 lg:right-0 opacity-0"
        id="heroSpinner">
        <img src="assets/images/spinner.svg" alt="" srcset=""
          class="w-[70px] h-[70px] lg:w-[150px] lg:h-[150px] object-contain"
          style="animation: spin 3s linear infinite;" appImageNotFound>
      </div>

      <!-- Van Image -->
      <div class="flex justify-center items-center mt-[200px] lg:mt-0 translate-x-[-100vw]" id="heroVan">
        <img src="assets/images/Van.png" alt="" srcset="" class="w-[90%]" appImageNotFound>
      </div>

      <!-- Video Container -->
      <div
        class="absolute top-0 left-0 lg:relative w-[194px] h-[269px] lg:w-[380px] lg:h-[419px] overflow-hidden scale-0"
        id="videoContainer">
        <img src="assets/images/videoBanner.jpg" alt="" class="w-full h-full object-cover absolute top-0 right-0"
          id="banner" appImageNotFound>
        <video src="assets/images/video.mp4" class="w-full h-full object-cover" id="video" muted></video>
      </div>
    </div>
    <!-- For Mobile -->
    <div class="md:hidden grid grid-cols-1 sm:grid-cols-2 gap-4 mt-12 mt-sm:24">
      <div class="col-span-1">
        <div class="w-full h-[150px] sm:h-[200px] hidden sm:flex overflow-hidden flex justify-center"
          id="mobileVideoContainer">
          <img src="assets/images/videoBanner.jpg" alt="" class="max-w-[150px] sm:max-w-[200px] object-cover absolute"
            id="mobileBanner" appImageNotFound>
          <video src="assets/images/video.mp4" class="max-w-[150px] sm:max-w-[200px] object-cover" id="mobileVideo"
            muted></video>
        </div>
      </div>
      <div class="col-span-1 flex flex-col items-center justify-center">
        <img src="assets/images/spinner.svg" alt=""
          class="animate-spin w-[40px] sm:w-[50px] h-[40px] sm:h-[50px] object-contain my-5 sm:my-0"
          style="animation: spin 3s linear infinite;" appImageNotFound>
        <h1 class="text-[34px] sm:text-[50px] font-[700] text-[rgb(72,68,82)] font2 text-center tracking-[1px] mb-4">
          Bringing Your Brand to Real People</h1>
      </div>
    </div>
    <div>
      <div class="flex md:hidden justify-center items-center  lg:mt-0">
        <img src="assets/images/Van.png" alt="" srcset="" class="w-[100%]" appImageNotFound>

      </div>

    </div>

    <div class="max-w-[800px] mx-auto text-center mt-16 mb-8">
      <p class="font2 text-[28px] leading-[1.4] text-gray-700 font-light mb-8">
        Advertising is not about reaching millions, it's about reaching the right people at the right time
      </p>
      <button class="px-8 py-3 bg-[#eb7641] text-white font1 text-lg tracking-wider
              transition-all duration-300 shadow-lg hover:shadow-xl
              hover:bg-transparent hover:text-[#eb7641] hover:scale-105 
              border-2 border-[#eb7641] hover:border-[#eb7641]">
        Pricing Plans
      </button>
    </div>
  </div>

  <!-- Section 2 -->
  <div class="py-10 max-w-[1400px] mx-auto">
    <h2 id="brands-title"
      class="font-normal text-center font-2 text-[30px] sm:text-[40px] md:text-[60px] lg:text-[80px] leading-[1.4] md:leading-[112px] text-[rgb(235,118,65)]">
      BRANDS WE WORKED WITH</h2>
    <h3 id="brands-list"
      class="font-normal text-[16px] sm:text-[24px] md:text-[32px] lg:text-[40px] leading-[1.4] md:leading-[56px] text-[rgb(49,48,47)] text-center px-4">
      Calumet City School District SD161 * DNC &nbsp;* Lux Listenings * Your Voice Illinois* * Fioretti Campaign * Wax
      Bar * Days of House *</h3>
  </div>

  <!-- About Section -->
  <div
    class="py-8 sm:py-12 md:py-16 my-6 sm:my-8 md:my-10 px-4 md:px-10 border-t border-b border-[#000000] border-1 border-x-0 max-w-[1400px] mx-auto">
      <div class=" flex flex-col md:flex-row items-start md:items-center gap-4 sm:gap-6 md:gap-24">
    <h2 class="font2 text-[16px] sm:text-[17px] md:text-[19px] font-medium">About Us</h2>
    <div class="flex-1">
      <h3
        class="font3 text-[28px] sm:text-[36px] md:text-[44px] font-normal text-black leading-[34px] sm:leading-[44px] md:leading-[53px] mb-4">
        Welcome to <span class="text-[#eb7641]">ShowYourAdz</span>, real-time advertising for real-world results.

        We don't just display ads; we deliver impact—one street, one block, one impression at a time.
      </h3>
    </div>
  </div>
</div>
<!-- Cutting Edge Features Section -->
<div class="py-16 px-4 md:px-10 max-w-[1400px] mx-auto">
          <!-- Header -->
          <div class=" text-center mb-16">
  <h2
    class="text-[28px] sm:text-[36px] md:text-[44px] font-normal text-black leading-[34px] sm:leading-[44px] md:leading-[58px] mb-4 font-3">
    Cutting Edge Features</h2>
  <p class="text-gray-600 max-w-3xl mx-auto">
    With ShowYourAdz, your brand takes center stage—experience the power of truck advertising and reach millions
    effortlessly.
  </p>
</div>

<!-- Features Grid -->
<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
  <!-- Premium Sound -->
  <div id="feature-box-1" class="feature-box bg-[#E1DEDE] p-8 rounded-lg">
    <div class="flex justify-center mb-6">
      <svg preserveAspectRatio="xMidYMid meet" data-bbox="46.5 36.5 107 127" viewBox="46.5 36.5 107 127" height="50"
        width="50" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true"
        aria-label="">
        <g>
          <path fill="#000001"
            d="M57.9 100.7c0 3.203-2.552 5.8-5.7 5.8s-5.7-2.597-5.7-5.8 2.552-5.8 5.7-5.8 5.7 2.597 5.7 5.8z"
            data-color="1"></path>
          <path
            d="M77.8 141.2c-2.5 0-4.5-2-4.5-4.5V63.2c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5v73.5c-.1 2.5-2.1 4.5-4.5 4.5z"
            fill="#000001" data-color="1"></path>
          <path d="M101.5 130c-2.5 0-4.5-2-4.5-4.5v-51c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5v51c0 2.5-2 4.5-4.5 4.5z"
            fill="#000001" data-color="1"></path>
          <path d="M125.3 163.5c-2.5 0-4.5-2-4.5-4.5V41c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5v118c-.1 2.5-2.1 4.5-4.5 4.5z"
            fill="#000001" data-color="1"></path>
          <path d="M149 117.8c-2.5 0-4.5-2-4.5-4.5V86.8c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5v26.5c0 2.4-2 4.5-4.5 4.5z"
            fill="#000001" data-color="1"></path>
        </g>
      </svg>
    </div>
    <h3 class="text-xl font-semibold text-center mb-4">Premium Sound</h3>
    <p class="text-[rgb(49,48,47)] text-center font-light text-[15px] leading-[24px] font-3">
      With our premium sound system, your event will be the talk of the town. Our Mobile Advertising Truck features JBL
      party box speakers that provide unbeatable sound quality. We also offer a mic set up for speeches or any other
      announcements you may have. Whether it's a corporate event or festival, our premium sound technology is sure to
      impress.
    </p>
  </div>

  <!-- Live Streaming -->
  <div id="feature-box-2" class="feature-box bg-[#E1DEDE] p-8 rounded-lg">
    <div class="flex justify-center mb-6">
      <svg preserveAspectRatio="xMidYMid meet" data-bbox="20 20 160 160" viewBox="20 20 160 160" height="50" width="50"
        xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-label="">
        <g>
          <path fill="#010203" d="M180 100c0 44.183-35.817 80-80 80s-80-35.817-80-80 35.817-80 80-80 80 35.817 80 80"
            data-color="1"></path>
          <path fill="#df7162" d="M160 100c0 33.137-26.863 60-60 60s-60-26.863-60-60 26.863-60 60-60 60 26.863 60 60"
            data-color="2"></path>
        </g>
      </svg>
    </div>
    <h3 class="text-xl font-semibold text-center mb-4">Live Streaming</h3>
    <p class="text-[rgb(49,48,47)] text-center font-light text-[15px] leading-[24px] font-3">
      Live streaming is the newest and most exciting way to engage with your audience. Our mobile advertising truck
      allows us to stream events live to our screens, where people can watch right outside the venue. This is perfect
      for tailgating parties, concerts, and any other event where the action is happening inside. With our high-quality
      equipment, we guarantee a seamless and entertaining experience for all viewers.
    </p>
  </div>

  <!-- Last Minute remedies -->
  <div id="feature-box-3" class="feature-box bg-[#E1DEDE] p-8 rounded-lg">
    <div class="flex justify-center mb-6">
      <svg preserveAspectRatio="xMidYMid meet" data-bbox="30.5 52 139.001 96" viewBox="30.5 52 139.001 96" height="50"
        width="50" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true"
        aria-label="">
        <defs>
          <style>
            #comp-m18z462r__item-j9plerjk svg [data-color="1"] {
              fill: #000000;
            }
          </style>
        </defs>
        <g>
          <path
            d="M120.271 121.818c-19.161 0-34.75-15.659-34.75-34.909S101.11 52 120.271 52s34.75 15.659 34.75 34.909-15.589 34.909-34.75 34.909zm0-64c-15.968 0-28.958 13.051-28.958 29.091S104.303 116 120.271 116s28.958-13.051 28.958-29.091-12.99-29.091-28.958-29.091z"
            fill="#AF1B08" data-color="1"></path>
          <path
            d="M166.604 136.364H33.396a2.902 2.902 0 0 1-2.896-2.909V78.182a2.902 2.902 0 0 1 2.896-2.909h55.019v5.818H36.292v49.455h127.417V81.091h-11.583v-5.818h14.479a2.902 2.902 0 0 1 2.896 2.909v55.273a2.904 2.904 0 0 1-2.897 2.909z"
            fill="#AF1B08" data-color="1"></path>
          <path fill="#AF1B08" d="M71.041 92.727v5.818H44.979v-5.818h26.062z" data-color="1"></path>
          <path fill="#AF1B08" d="M50.771 104.364v5.818h-5.792v-5.818h5.792z" data-color="1"></path>
          <path fill="#AF1B08" d="M62.354 104.364v5.818h-5.792v-5.818h5.792z" data-color="1"></path>
          <path
            d="M137.161 98.17l-4.822-3.222a14.53 14.53 0 0 0 2.411-8.04c0-8.02-6.496-14.545-14.479-14.545-1.007 0-2.029.111-3.041.327l-1.21-5.688a20.19 20.19 0 0 1 4.252-.457c11.178 0 20.271 9.136 20.271 20.364a20.35 20.35 0 0 1-3.382 11.261z"
            fill="#AF1B08" data-color="1"></path>
          <path
            d="M120.271 107.273c-11.178 0-20.271-9.136-20.271-20.364 0-1.42.153-2.858.454-4.273l5.664 1.216a14.746 14.746 0 0 0-.327 3.057c0 8.02 6.496 14.545 14.479 14.545 2.547 0 5.051-.673 7.238-1.943l2.901 5.034a20.18 20.18 0 0 1-10.138 2.728z"
            fill="#AF1B08" data-color="1"></path>
          <path
            d="M65.25 148H42.083a2.89 2.89 0 0 1-2.047-.852l-2.896-2.909 4.095-4.114 2.047 2.057h20.769l2.047-2.057 4.095 4.114-2.896 2.909a2.89 2.89 0 0 1-2.047.852z"
            fill="#AF1B08" data-color="1"></path>
          <path
            d="M157.917 148H134.75a2.89 2.89 0 0 1-2.047-.852l-2.896-2.909 4.095-4.114 2.047 2.057h20.769l2.047-2.057 4.095 4.114-2.896 2.909a2.892 2.892 0 0 1-2.047.852z"
            fill="#AF1B08" data-color="1"></path>
        </g>
      </svg>
    </div>
    <h3 class="text-xl font-semibold text-center mb-4">Last Minute remedies</h3>
    <p class="text-[rgb(49,48,47)] text-center font-light text-[15px] leading-[24px] font-3">
      Don't let last-minute advertising needs stress you out. Our mobile advertising trucks are ready to hit the
      pavement when you need them most. Our team of experts can help you develop a message on the fly, ensuring your
      message reaches your target audience at the right moment.
    </p>
  </div>

  <!-- Video & Static Display -->
  <div id="feature-box-4" class="feature-box bg-[#E1DEDE] p-8 rounded-lg">
    <div class="flex justify-center mb-6">
      <svg preserveAspectRatio="xMidYMid meet" data-bbox="30.5 45 139 110" viewBox="30.5 45 139 110" height="50"
        width="50" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true"
        aria-label="">
        <defs>
          <style>
            #comp-m18yzjbq__item1 svg [data-color="1"] {
              fill: #000000;
            }
          </style>
        </defs>
        <g>
          <path
            d="M166.604 128.947H33.396a2.894 2.894 0 0 1-2.896-2.895V47.895A2.894 2.894 0 0 1 33.396 45h133.208a2.894 2.894 0 0 1 2.896 2.895v78.158a2.894 2.894 0 0 1-2.896 2.894zm-130.312-5.789h127.417V50.789H36.292v72.369z"
            fill="#AF1B08" data-color="1"></path>
          <path
            d="M88.417 108.684a2.892 2.892 0 0 1-2.896-2.895V71.053c0-1.043.56-2.006 1.468-2.519a2.888 2.888 0 0 1 2.918.037l28.958 17.368a2.896 2.896 0 0 1 0 4.964l-28.958 17.368a2.889 2.889 0 0 1-1.49.413zm2.895-32.519v24.512l20.435-12.256-20.435-12.256z"
            fill="#AF1B08" data-color="1"></path>
          <path fill="#AF1B08" d="M169.5 140.526v5.789H82.625v-5.789H169.5z" data-color="1"></path>
          <path
            d="M68.146 155c-6.388 0-11.583-5.194-11.583-11.579s5.195-11.579 11.583-11.579 11.583 5.194 11.583 11.579S74.534 155 68.146 155zm0-17.368c-3.193 0-5.792 2.596-5.792 5.789s2.599 5.789 5.792 5.789c3.193 0 5.792-2.596 5.792-5.789s-2.599-5.789-5.792-5.789z"
            fill="#AF1B08" data-color="1"></path>
          <path fill="#AF1B08" d="M53.667 140.526v5.789H30.5v-5.789h23.167z" data-color="1"></path>
        </g>
      </svg>
    </div>
    <h3 class="text-xl font-semibold text-center mb-4">Video & Static Display</h3>
    <p class="text-[rgb(49,48,47)] text-center font-light text-[15px] leading-[24px] font-3">
      Our Mobile Advertising Truck with Video Display is the perfect solution for businesses that want to make a big
      impact. With our digital display capabilities, your campaign will pop and catch the attention of anyone who passes
      by. Say goodbye to static adtrucks and hello to a whole new level of marketing success.
    </p>
  </div>

  <!-- GPS Enabled -->
  <div id="feature-box-5" class="feature-box bg-[#E1DEDE] p-8 rounded-lg">
    <div class="flex justify-center mb-6">
      <svg preserveAspectRatio="xMidYMid meet" data-bbox="66.5 49.5 67 100.997" viewBox="66.5 49.5 67 100.997"
        height="50" width="50" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation"
        aria-hidden="true" aria-label="">
        <defs>
          <style>
            #comp-m18yzjbq__item-j9ples3e svg [data-color="1"] {
              fill: #000000;
            }

            #comp-m18yzjbq__item-j9ples3e svg [data-color="2"] {
              fill: #000000;
            }
          </style>
        </defs>
        <g>
          <path
            d="M133.5 83.041c0-18.495-15.028-33.541-33.5-33.541S66.5 64.546 66.5 83.041a33.49 33.49 0 0 0 2.908 13.65l.008.02.001.002a33.472 33.472 0 0 0 2.092 3.939l25.522 48.062a3.363 3.363 0 0 0 5.938 0l25.807-48.563c.077-.104.161-.201.228-.316a33.567 33.567 0 0 0 4.496-16.794zm-33.498 56.921L77.945 98.427a3.386 3.386 0 0 0-.338-.686c-.074-.113-.137-.231-.209-.345l-1.923-3.621a26.768 26.768 0 0 1-2.249-10.734c0-14.781 12.011-26.806 26.774-26.806s26.773 12.025 26.773 26.806c0 4.031-.913 8.001-2.64 11.603-.085.119-.175.235-.245.368l-23.886 44.95z"
            fill="#0E4828" data-color="1"></path>
          <path
            d="M100 70.204c-7.517 0-13.633 6.123-13.633 13.65s6.116 13.65 13.633 13.65 13.633-6.124 13.633-13.65-6.115-13.65-13.633-13.65z"
            fill="#E98866" data-color="2"></path>
        </g>
      </svg>
    </div>
    <h3 class="text-xl font-semibold text-center mb-4">GPS Enabled</h3>
    <p class="text-[rgb(49,48,47)] text-center font-light text-[15px] leading-[24px] font-3">
      Our mobile advertising trucks are fitted with GPS technology, so you can monitor the progress of your campaign in
      real time. Our team is dedicated to providing you with the best possible service, and we're constantly innovating
      to help you get the results you need. See the impact of your advertising efforts with GPS Enabled.
    </p>
  </div>

  <!-- In House Graphic Designer -->
  <div id="feature-box-6" class="feature-box bg-[#E1DEDE] p-8 rounded-lg">
    <div class="flex justify-center mb-6">
      <svg preserveAspectRatio="xMidYMid meet" data-bbox="43 36 114 128" viewBox="43 36 114 128" height="50" width="50"
        xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-label="">
        <g>
          <path
            d="M157 146.667V160a4 4 0 0 1-8 0v-13.333C149 134.168 138.906 124 126.5 124h-53C61.094 124 51 134.168 51 146.667V160a4 4 0 0 1-8 0v-13.333C43 129.757 56.683 116 73.5 116h53c16.817 0 30.5 13.757 30.5 30.667zm-87.5-80C69.5 49.757 83.183 36 100 36s30.5 13.757 30.5 30.667c0 16.909-13.683 30.666-30.5 30.666S69.5 83.576 69.5 66.667zm8 0c0 12.498 10.094 22.666 22.5 22.666s22.5-10.168 22.5-22.666C122.5 54.168 112.406 44 100 44S77.5 54.168 77.5 66.667z"
            fill="#000001" data-color="1"></path>
        </g>
      </svg>
    </div>
    <h3 class="text-xl font-semibold text-center mb-4">In House Graphic Designer</h3>
    <p class="text-[rgb(49,48,47)] text-center font-light text-[15px] leading-[24px] font-3">
      Incorporating our in-house graphic designers into the Mobile Advertising Truck experience can bring your marketing
      campaign to the next level. Our team of experts will work closely with you to develop a custom design that
      perfectly showcases your product and message. You'll be blown away by the level of detail and attention that we
      put into our displays.
    </p>
  </div>
</div>
</div>

<!-- Newsletter Section -->
<div class="w-full bg-[#3c3c47] py-16 relative overflow-hidden">
  <!-- Van Image -->
  <div class="w-full max-w-[1400px] mx-auto px-4 md:px-10 flex flex-col md:flex-row items-center justify-center gap-8">
    <div class="w-full md:w-1/2 flex justify-center">
      <img id="newsletter-truck" src="assets/images/truck.png" alt="Digital Advertisement Van"
        class="w-full max-w-[600px]" appImageNotFound>
    </div>

    <!-- Newsletter Form -->
    <div class="w-full md:w-1/2 max-w-[600px] bg-[#4a4a56] p-8 rounded-lg">
      <h3 class="text-white mb-4 text-xl">Subscribe to Newsletter</h3>
      <div class="flex flex-col gap-4">
        <input type="email" placeholder="Email"
          class="w-full px-4 py-2 rounded border border-gray-300 focus:outline-none focus:border-[#eb7641]"
          style="font-family: Arial;">
        <button class="w-full bg-[#eb7641] text-white py-2 rounded hover:bg-[#d66937] transition-colors">
          Subscribe
        </button>
      </div>
    </div>
  </div>

  <!-- Decorative Star -->
  <div class="absolute top-8 right-8">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 0L14.59 9.41L24 12L14.59 14.59L12 24L9.41 14.59L0 12L9.41 9.41L12 0Z" fill="#eb7641" />
    </svg>
  </div>
</div>

<!-- FAQ Section -->
<div class="relative max-w-[1100px] mx-auto px-4 md:px-10 py-16 flex flex-col md:flex-row gap-8">
  <!-- Left Side -->
  <div class="w-full md:w-1/3">
    <h2 class="text-4xl font-normal mb-4">FAQs</h2>
    <p class="text-gray-600">Here are some of the frequently asked questions about our service.</p>

    <!-- Decorative Star -->
    <div class="absolute top-12 right-12 md:relative md:top-0 md:right-0 md:mt-16">
      <svg preserveAspectRatio="xMidYMid meet" data-bbox="25 25 150 150" xmlns="http://www.w3.org/2000/svg" width="50"
        height="50" viewBox="25 25 150 150" data-type="shape" role="presentation" aria-hidden="true" aria-label=""
        style="animation: spin 10s linear infinite;">
        <g>
          <path
            d="M102.727 61.923L81.363 25l-5 44.77-41.818-8.308 26.363 33.23L25 121h46.818l-8.182 43.846L95.454 133l26.819 42v-43.384l45.454 3.692-30.454-28.154L175 78.539h-42.273l1.364-44.77-31.364 28.154z">
          </path>
        </g>
      </svg>
    </div>
  </div>

  <!-- Right Side - FAQ Questions -->
  <div class="w-full md:w-2/3">
    <!-- FAQ Items -->
    <div class="space-y-4">
      <div class="border border-gray-800 ">
        <button class="faq-btn w-full px-6 py-4 text-left flex justify-between items-center">
          <span>What is Haul Adz?</span>
          <svg class="w-6 h-6 transform transition-transform duration-300" fill="none" stroke="currentColor"
            viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        </button>
        <div class="faq-content overflow-hidden max-h-0 transition-all duration-300 ease-in-out">
          <div class="px-6 py-4 border-t border-gray-800">
            <p>Haul Adz is a mobile advertising truck that is equipped with a high-quality LED screen that can display
              dynamic content. We offer a unique and eye-catching way to advertise your business or event.</p>
          </div>
        </div>
      </div>

      <div class="border border-gray-800 ">
        <button class="faq-btn w-full px-6 py-4 text-left flex justify-between items-center">
          <span>Why is mobile advertising more effective?</span>
          <svg class="w-6 h-6 transform transition-transform duration-300" fill="none" stroke="currentColor"
            viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        </button>
        <div class="faq-content overflow-hidden max-h-0 transition-all duration-300 ease-in-out">
          <div class="px-6 py-4 border-t border-gray-800">
            <p>A: Mobile advertising is more effective because it allows you to reach your target audience directly and
              can be more memorable than traditional advertising. With Haul Adz, you can take your message to your
              customers anywhere they are.</p>
          </div>
        </div>
      </div>

      <div class="border border-gray-800 ">
        <button class="faq-btn w-full px-6 py-4 text-left flex justify-between items-center">
          <span>Why is an ad truck better than a billboard?</span>
          <svg class="w-6 h-6 transform transition-transform duration-300" fill="none" stroke="currentColor"
            viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        </button>
        <div class="faq-content overflow-hidden max-h-0 transition-all duration-300 ease-in-out">
          <div class="px-6 py-4 border-t border-gray-800">
            <p>An ad truck is better than a billboard because it is more dynamic and can reach a larger audience. Our
              LED screen is high-quality and can display a variety of content, making it more engaging and effective
              than a traditional billboard.</p>
          </div>
        </div>
      </div>

      <div class="border border-gray-800 ">
        <button class="faq-btn w-full px-6 py-4 text-left flex justify-between items-center">
          <span>Why is an ad truck better than social media advertising?</span>
          <svg class="w-6 h-6 transform transition-transform duration-300" fill="none" stroke="currentColor"
            viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        </button>
        <div class="faq-content overflow-hidden max-h-0 transition-all duration-300 ease-in-out">
          <div class="px-6 py-4 border-t border-gray-800">
            <p>A: An ad truck is better than social media advertising because it can reach a specific audience and is
              more memorable. Our LED screen is eye-catching and can display targeted content to your specific audience,
              making it more effective than social media advertising.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Let's Talk Banner -->
<div class="w-full py-16 overflow-hidden mt-12 mb-24 -rotate-3">
  <div class="max-w-[1400px] ">
    <div class="marquee-container">
      <div class="marquee-content">
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <!-- Reduce repeated content to about 10-15 items -->
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <!-- ... add more as needed ... -->
      </div>
      <div class="marquee-content" aria-hidden="true">
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <!-- Duplicate the same number of items as above -->
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <!-- ... add more as needed ... -->
      </div>
      <div class="marquee-content">
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <!-- Reduce repeated content to about 10-15 items -->
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <!-- ... add more as needed ... -->
      </div>
      <div class="marquee-content" aria-hidden="true">
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <!-- Duplicate the same number of items as above -->
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <!-- ... add more as needed ... -->
      </div>
      <div class="marquee-content">
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <!-- Reduce repeated content to about 10-15 items -->
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <!-- ... add more as needed ... -->
      </div>
      <div class="marquee-content" aria-hidden="true">
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <!-- Duplicate the same number of items as above -->
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <!-- ... add more as needed ... -->
      </div>
      <div class="marquee-content">
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <!-- Reduce repeated content to about 10-15 items -->
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <!-- ... add more as needed ... -->
      </div>
      <div class="marquee-content" aria-hidden="true">
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <!-- Duplicate the same number of items as above -->
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <span class="text-4xl inline-block mr-4">Let's Talk 🙂</span>
        <!-- ... add more as needed ... -->
      </div>
    </div>
  </div>
</div>
<app-web-footer></app-web-footer>
</div>