import { Component, OnInit, OnDestroy, ViewChildren, QueryList, ElementRef, AfterViewInit } from '@angular/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SubscriptionsService } from 'src/app/shared/service/subscriptions/subscriptions.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/service/Auth/Auth.service';
import { SocketService } from 'src/app/shared/service/socket/socket.service';
import { NavService } from 'src/app/shared/service/navbar/nav.service';

@Component({
  selector: 'app-user-subscriptions',
  templateUrl: './user-subscriptions.component.html',
  styleUrls: ['./user-subscriptions.component.scss']
})
export class UserSubscriptionsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('descriptionText') descriptionElements!: QueryList<ElementRef>;
  @ViewChildren('currentDescriptionText') currentDescriptionElement!: QueryList<ElementRef>;
  selectedTab: string = 'monthly';
  userDetail:any = {
    currentSubscription: {
      needsExpansion: false,
      isDescriptionExpanded: false
    }
  };
  sessionId: string | null = null;
  sessionDetails: any = null;
  private stripe!: Stripe | null;
  public allPlans:any = [];
  private STRIPE_PUBLIC_KEY = environment.STRIPE_PUBLIC_KEY;
  loading: boolean = true;
  loadingPurchase: { [key: string]: boolean } = {};
  
  constructor(
    private router: Router,
    private subscriptionService:SubscriptionsService ,
    private route: ActivatedRoute,
    private toastr:ToastrService,
    private authService:AuthService,
    private socketService: SocketService,
    private navService: NavService
  ) {
    this.socketService.getConnectionStatus().subscribe(
      isConnected => {
        if (!isConnected) {
          console.log('Socket disconnected, attempting to reconnect...');
          this.socketService.reconnect();
        }
      }
    );
  }

  async ngOnInit() {
    this.navService.setTitle('Subscriptions Overview');
    this.navService.setSubtitle('Manage your ShowYourAdz subscriptions');
    this.stripe = await loadStripe(this.STRIPE_PUBLIC_KEY);
    console.log(`${this.STRIPE_PUBLIC_KEY} ${(this.stripe)}`)
    this.authService.getUserDetails().subscribe({
      next: (res: any) => {
        if (res) {
          if (res.currentSubscription) {
            res.currentSubscription.needsExpansion = false;
            res.currentSubscription.isDescriptionExpanded = false;
          }
          this.userDetail = res;
        }
      }
    });
    this.getAllSubscriptions()
    this.socketService.getSubscriptionEvents().subscribe({
      next: (event) => {
        if (event?.type === 'subscription_purchased') {
          setTimeout(() => {
            this.toastr.success('Subscription purchased successfully!');
          }, 2000);
          this.getAllSubscriptions();
        }
      }
    });
  }

  ngOnDestroy() {
    this.socketService.disconnect();
  }

  ngAfterViewInit() {
    this.checkDescriptionLengths();
    // Re-check when plans change
    this.descriptionElements.changes.subscribe(() => {
      this.checkDescriptionLengths();
    });
    // Check current subscription description
    this.currentDescriptionElement.changes.subscribe(() => {
      this.checkCurrentDescriptionLength();
    });
  }

  checkDescriptionLengths() {
    setTimeout(() => {
      this.descriptionElements.forEach((el: ElementRef, index: number) => {
        if (this.allPlans[index]) {
          const element = el.nativeElement;
          // Check if content height is greater than 2 lines (assuming line-height is about 1.5)
          const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
          const maxHeight = lineHeight * 2;
          this.allPlans[index].needsExpansion = element.scrollHeight > maxHeight;
        }
      });
    });
  }

  checkCurrentDescriptionLength() {
    setTimeout(() => {
      const currentElement = this.currentDescriptionElement.first;
      if (currentElement && this.userDetail?.currentSubscription) {
        const element = currentElement.nativeElement;
        const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
        const maxHeight = lineHeight * 2;
        this.userDetail.currentSubscription.needsExpansion = element.scrollHeight > maxHeight;
      }
    });
  }

  toggleDescription(plan: any, event: Event) {
    event.stopPropagation();
    plan.isDescriptionExpanded = !plan.isDescriptionExpanded;
  }

  getAllSubscriptions() {
    this.loading = true;
    this.subscriptionService.getSubscriptions().subscribe({
      next: ((res:any) => {
        this.allPlans = res.data;
        this.loading = false;
      }),
      error: (error: any) => {
        this.loading = false;
        this.toastr.error('Failed to load subscriptions');
      }
    })
  }
  async purchasePlan(stripePlanId: string) {
    this.loadingPurchase[stripePlanId] = true;
    try {
      const data = {
        planId: stripePlanId
      }
      console.log('>>>>>>>>>>>>>data', data)
      this.subscriptionService.createCheckoutSession(data).subscribe({
        next: async (res: any) => {
          if (this.stripe && res.data) {
            console.log("redirecting to checkout", {sessionId: res.data.id})
            await this.stripe.redirectToCheckout({ sessionId: res.data.id });
            this.toastr.success(res.message)
          }
        },
        error: (err:any) => {
          console.log('>>>>>>>>>>>>>err', err)
          this.toastr.error(err.error.message || "some thing went wrong")
          this.loadingPurchase[stripePlanId] = false;
        },
        complete: () => {
          this.loadingPurchase[stripePlanId] = false;
        }
      });
    } catch (error) {
      console.error('Error creating checkout session:', error);
      this.loadingPurchase[stripePlanId] = false;
    }
  }
}