  <!-- Enhanced Main Chat Container -->
  <div class="bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100">
    <!-- Enhanced Chat Header -->
    <div class="p-6 bg-white border-b border-gray-100">
      <div class="flex items-center justify-between max-w-4xl mx-auto">
        <div class="flex items-center space-x-4">
          <div class="relative">
            <div class="w-14 h-14 bg-gradient-to-br from-[#eb7641] to-[#d66937] rounded-xl flex items-center justify-center shadow-lg transform hover:scale-105 transition-all duration-300">
              <i class="ri-customer-service-2-line text-white text-xl"></i>
            </div>
          </div>
          <div>
            <h3 class="font-bold text-gray-800 text-lg">Support Team</h3>
            <div class="flex items-center space-x-2">
              <!-- Online Status -->
              <span *ngIf="isAdminOnline" class="w-2 h-2 bg-green rounded-full animate-pulse"></span>
              <p *ngIf="isAdminOnline" class="text-sm text-green-600">Available Now</p>
              
              <!-- Offline Status -->
              <span *ngIf="!isAdminOnline" class="w-2 h-2 bg-gray-400 rounded-full"></span>
              <p *ngIf="!isAdminOnline" class="text-sm text-gray-500">Offline - We'll respond when available</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Enhanced Messages Container -->
    <div #messageContainer class="h-[calc(100vh-380px)] overflow-y-auto py-8 px-6 bg-gray-50">
      <!-- Stylish Preloader -->
      <div *ngIf="loading" class="flex flex-col items-center justify-center h-full space-y-8">
        <div class="relative">
          <div class="w-20 h-20 bg-gradient-to-br from-[#eb7641] to-[#d66937] rounded-2xl flex items-center justify-center shadow-xl animate-pulse">
            <i class="ri-chat-3-line text-white text-3xl"></i>
          </div>
          <div class="absolute -top-3 -right-3 w-8 h-8 bg-white rounded-full flex items-center justify-center shadow-lg animate-spin">
            <div class="w-5 h-5 border-4 border-[#eb7641] border-t-transparent rounded-full"></div>
          </div>
        </div>
        <div class="text-center space-y-3">
          <h3 class="text-xl font-bold text-gray-800">Loading your conversation</h3>
          <div class="flex justify-center space-x-2">
            <div class="w-3 h-3 bg-[#eb7641] rounded-full animate-bounce"></div>
            <div class="w-3 h-3 bg-[#eb7641] rounded-full animate-bounce" style="animation-delay: 0.2s"></div>
            <div class="w-3 h-3 bg-[#eb7641] rounded-full animate-bounce" style="animation-delay: 0.4s"></div>
          </div>
        </div>
      </div>

      <div *ngIf="!loading" class="max-w-3xl mx-auto space-y-6">
        <!-- Enhanced Welcome Message -->
        <div *ngIf="messages?.length === 0" class="flex flex-col items-center justify-center h-full space-y-8">
          <div class="relative">
            <div class="w-24 h-24 bg-gradient-to-br from-[#eb7641] to-[#d66937] rounded-2xl flex items-center justify-center shadow-xl transform hover:rotate-12 transition-all duration-300">
              <i class="ri-customer-service-2-line text-white text-4xl"></i>
            </div>
            <div class="absolute -right-2 -bottom-2 w-8 h-8 bg-green-400 rounded-xl flex items-center justify-center shadow-lg">
              <i class="ri-chat-smile-2-line text-white"></i>
            </div>
          </div>
          <div class="text-center space-y-4 max-w-md">
            <h3 class="text-2xl font-bold text-gray-800">Welcome to ShowYourAdz Support</h3>
            <p class="text-gray-500 leading-relaxed">Our team is here to help you with your advertising campaigns. Feel free to ask any questions about:</p>
            <div class="grid grid-cols-2 gap-3 mt-4">
              <div class="bg-[#eb7641]/10 p-3 rounded-xl">
                <i class="ri-advertisement-line text-[#eb7641] mb-2"></i>
                <p class="text-sm text-gray-700">Campaign Management</p>
              </div>
              <div class="bg-[#eb7641]/10 p-3 rounded-xl">
                <i class="ri-map-pin-line text-[#eb7641] mb-2"></i>
                <p class="text-sm text-gray-700">Location Targeting</p>
              </div>
              <div class="bg-[#eb7641]/10 p-3 rounded-xl">
                <i class="ri-money-dollar-circle-line text-[#eb7641] mb-2"></i>
                <p class="text-sm text-gray-700">Billing & Subscriptions</p>
              </div>
              <div class="bg-[#eb7641]/10 p-3 rounded-xl">
                <i class="ri-settings-line text-[#eb7641] mb-2"></i>
                <p class="text-sm text-gray-700">Technical Support</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Enhanced Chat Messages -->
        <ng-container *ngFor="let message of messages">
          <div class="flex" [ngClass]="{'justify-end': !message.isAdmin}">
            <div class="flex flex-col max-w-[80%] space-y-2">
              <div class="flex items-start space-x-3" [ngClass]="{'flex-row-reverse': !message.isAdmin, 'space-x-reverse': !message.isAdmin}">
                <!-- Enhanced Avatar -->
                <div *ngIf="message.isAdmin" class="relative">
                  <div class="w-10 h-10 bg-gradient-to-br from-[#eb7641] to-[#d66937] rounded-xl flex items-center justify-center shadow-md">
                    <i class="ri-customer-service-2-line text-white text-sm"></i>
                  </div>
                  <span class="absolute -bottom-1 -right-1 w-3 h-3 bg-green-400 border-2 border-white rounded-full"></span>
                </div>
                
                <!-- Enhanced Message Bubbles -->
                <div [ngClass]="{
                  'bg-gradient-to-br from-[#eb7641] to-[#d66937] text-white shadow-lg': !message.isAdmin,
                  'bg-white text-gray-800 shadow-md border border-gray-100': message.isAdmin
                }" class="p-4 rounded-2xl transform hover:scale-[1.02] transition-all duration-300">
                  <p class="text-sm whitespace-pre-wrap leading-relaxed">{{message.message}}</p>
                </div>
              </div>
              
              <!-- Enhanced Timestamp & Status -->
              <div class="flex items-center space-x-2 px-2" [ngClass]="{'justify-end': !message.isAdmin}">
                <span class="text-xs text-gray-400">{{message.createdAt | date:'shortTime'}}</span>
                <ng-container *ngIf="!message.isAdmin">
                  <!-- Failed Status -->
                  <i *ngIf="message.status === 'failed'" class="ri-error-warning-line text-red-500 text-sm" title="Failed to send"></i>
                  <!-- Sent Status (Single Tick) -->
                  <i *ngIf="message.status === 'sent'" class="ri-check-line text-gray-400 text-sm"></i>
                  <!-- Delivered Status (Double Tick) -->
                  <i *ngIf="message.status === 'delivered'" class="ri-check-double-line text-gray-400 text-sm"></i>
                  <!-- Read Status (Colored Double Tick) -->
                  <i *ngIf="message.status === 'read'" class="ri-check-double-line text-black text-sm"></i>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- Enhanced Typing Indicator -->
        <div *ngIf="isTyping" class="flex items-center space-x-3">
          <div class="relative">
            <div class="w-10 h-10 bg-gradient-to-br from-[#eb7641] to-[#d66937] rounded-xl flex items-center justify-center shadow-md">
              <i class="ri-customer-service-2-line text-white text-sm"></i>
            </div>
            <span class="absolute -bottom-1 -right-1 w-3 h-3 bg-green-400 border-2 border-white rounded-full"></span>
          </div>
          <div class="bg-white rounded-2xl shadow-md border border-gray-100 p-4">
            <div class="flex space-x-2">
              <div class="w-2.5 h-2.5 bg-gradient-to-r from-[#eb7641] to-[#d66937] rounded-full animate-bounce"></div>
              <div class="w-2.5 h-2.5 bg-gradient-to-r from-[#eb7641] to-[#d66937] rounded-full animate-bounce" style="animation-delay: 0.2s"></div>
              <div class="w-2.5 h-2.5 bg-gradient-to-r from-[#eb7641] to-[#d66937] rounded-full animate-bounce" style="animation-delay: 0.4s"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Enhanced Message Input -->
    <div class="border-t border-gray-100 p-6 bg-gradient-to-r from-white to-gray-50">
      <div class="max-w-3xl mx-auto">
        <div class="flex items-center space-x-4">
          <div class="flex-1 bg-gray-50/80 rounded-xl shadow-inner group focus-within:bg-white transition-all duration-300">
            <textarea
              #messageInput
              [(ngModel)]="newMessage"
              (keydown)="onKeyPress($event)"
              placeholder="Type your message..."
              rows="1"
              class="w-full bg-transparent px-4 py-3 focus:outline-none text-gray-700 resize-none rounded-xl placeholder:text-gray-400 min-h-[48px] max-h-[120px]"
            ></textarea>
          </div>
          <button 
            (click)="sendMessage()"
            [disabled]="!newMessage.trim() || loading"
            class="h-12 w-12 flex items-center justify-center rounded-xl bg-gradient-to-br from-[#eb7641] to-[#d66937] text-white shadow-lg disabled:opacity-50 disabled:cursor-not-allowed hover:shadow-xl transition-all hover:scale-105 active:scale-95 disabled:hover:scale-100">
            <i class="ri-send-plane-fill text-lg"></i>
          </button>
        </div>
        <div class="flex items-center justify-between mt-2 px-4">
          <span class="text-xs text-gray-400">Press Enter to send, Shift + Enter for new line</span>
          <span class="text-xs text-gray-400">{{ newMessage.length || 0 }}/500</span>
        </div>
      </div>
    </div>
  </div>
