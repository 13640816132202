import { Component, OnInit } from '@angular/core';
import { CampaignService } from 'src/app/shared/service/campaign/campaign.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NavService } from 'src/app/shared/service/navbar/nav.service';

@Component({
  selector: 'app-campaigns-list',
  templateUrl: './campaigns-list.component.html',
  styleUrls: ['./campaigns-list.component.scss']
})
export class CampaignsListComponent implements OnInit {
  campaigns: any[] = [];
  loading: boolean = false;
  searchTerm: string = '';
  filterStatus: string = 'all';

  constructor(
    private campaignService: CampaignService,
    private toastr: ToastrService,
    private router: Router,
    private navService: NavService
  ) {}

  ngOnInit() {
    this.getCampaigns();
    this.navService.setTitle('Campaigns');
    this.navService.setSubtitle('Manage campaigns');
  }

  getCampaigns() {
    this.loading = true;
    this.campaignService.getCampaigns().subscribe({
      next: (response) => {
        this.campaigns = response.data;
        this.loading = false;
      },
      error: (error) => {
        this.toastr.error('Failed to load campaigns');
        this.loading = false;
      }
    });
  }

  deleteCampaign(campaignId: string) {
    if (confirm('Are you sure you want to delete this campaign?')) {
      this.campaignService.deleteCampaign(campaignId).subscribe({
        next: () => {
          this.toastr.success('Campaign deleted successfully');
          this.getCampaigns();
        },
        error: (error) => {
          this.toastr.error('Failed to delete campaign');
        }
      });
    }
  }

  getStatusClass(status: string): string {

    const classes: { [key: string]: string } = {
      pending: 'bg-yellow-100 text-yellow-800',
      active: 'bg-green-100 text-green-800',
      scheduled: 'bg-blue-100 text-blue-800',
      completed: 'bg-gray-100 text-gray-800',
      rejected: 'bg-red-100 text-red-800'
    };
    return classes[status] || 'bg-gray-100 text-gray-800';
  }

  filterCampaigns() {
    if (this.searchTerm.trim() || this.filterStatus !== 'all') {
      return this.campaigns.filter(campaign => {
        const matchesSearch = campaign.campaignName.toLowerCase().includes(this.searchTerm.toLowerCase());
        const matchesStatus = this.filterStatus === 'all' || campaign.status === this.filterStatus;
        return matchesSearch && matchesStatus;
      });
    }
    return this.campaigns;
  }
}
