    <div
      class="w-full min-h-screen flex-col flex lg:flex-row justify-between gap-12 my-5"
    >
      <div class="static-left  flex-2">
  
        <app-spinner *ngIf="loading"></app-spinner>
  
        <div class="flex justify-end items-center mt-10">
          <button (click)="showAddStateModal = true" 
                  class="px-4 py-2 bg-[#eb7641] text-white rounded-lg hover:bg-[#d66937] transition-colors duration-300 flex items-center gap-2">
            <i class="ri-add-line"></i>
            Add State
          </button>
        </div>
        <!-- No States Message -->
        <div *ngIf="!loading && states.length === 0" 
             class="bg-gradient-to-br from-white to-gray-50 rounded-xl shadow-lg p-8 text-center mt-8">
          <div class="max-w-md mx-auto">
            <div class="bg-[#eb764115] p-4 rounded-full w-16 h-16 mx-auto mb-6 flex items-center justify-center">
              <i class="ri-map-pin-line text-[#eb7641] text-3xl"></i>
            </div>
            <h3 class="text-xl font-semibold text-gray-800 mb-2">No States Available</h3>
            <p class="text-gray-600 mb-6">Start by adding your first state to manage locations.</p>
            <button (click)="showAddStateModal = true"
                    class="px-6 py-3 bg-[#eb7641] text-white rounded-lg hover:bg-[#d66937] transition-colors duration-300 flex items-center gap-2 mx-auto">
              <i class="ri-add-line"></i>
              Add Your First State
            </button>
          </div>
        </div>
  
        <!-- States List -->
        <div *ngIf="!loading && states.length > 0" class="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div *ngFor="let state of states" 
               class="bg-gradient-to-br from-white to-gray-50 rounded-xl shadow-lg p-4 border-l-4 border-[#eb7641]">
            <div class="flex justify-between items-center p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
              <div class="flex items-center gap-3">
                <i class="ri-map-pin-line text-[#eb7641]"></i>
                <span>{{state.name}}</span>
              </div>
              <div class="flex items-center gap-2">
                
                <button (click)="deleteState(state)" class="text-gray-500 hover:text-red">
                  <i class="ri-delete-bin-line"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Add State Modal -->
        <div *ngIf="showAddStateModal" class="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div @modalAnimation class="relative w-full max-w-2xl mx-auto bg-white rounded-lg shadow">
                <!-- Modal Header -->
                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                    <h3 class="text-xl font-medium text-gray-900">
                        Add New States
                    </h3>
                    <button type="button"
                        class="text-black bg-transparent border border-black rounded-full hover:bg-gray-200 hover:text-gray-900 text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                        (click)="showAddStateModal = false">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#000"
                            viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                    </button>
                </div>

                <!-- Modal Body -->
                <div class="p-4 md:p-5 space-y-4">
                    <form [formGroup]="stateForm">
                        <div class="space-y-4">
                            <div>
                                <label for="stateSelect" class="block mb-2 text-sm font-medium text-gray-900">
                                    Select States
                                </label>
                                <select id="stateSelect" formControlName="statesList"
                                    (change)="onStateSelect($event)"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#eb7641] focus:border-[#eb7641] block w-full p-2.5">
                                    <option value="">Select a state</option>
                                    <option *ngFor="let state of usStatesList" [value]="state.name">
                                        {{state.name}} ({{state.code}})
                                    </option>
                                </select>
                            </div>

                            <!-- Preview States -->
                            <div *ngIf="selectedStates.length > 0" 
                                 class="bg-gray-50 p-4 rounded-lg border border-gray-200">
                                <div class="flex justify-between items-center mb-3">
                                    <label class="text-sm font-medium text-gray-900">
                                        States to be added ({{selectedStates.length}})
                                    </label>
                                    <button type="button" 
                                            (click)="selectedStates = []"
                                            class="text-sm text-red hover:text-red-700">
                                        Clear All
                                    </button>
                                </div>
                                <div class="flex flex-wrap gap-2">
                                    <div *ngFor="let state of selectedStates" 
                                         class="bg-white px-3 py-1.5 rounded-lg border border-gray-200 text-sm flex items-center gap-2">
                                        <span>{{state.name}} ({{state.code}})</span>
                                        <button type="button" 
                                                (click)="removeState(state)"
                                                class="text-gray-400 hover:text-red">
                                            <i class="ri-close-line"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <!-- Modal Footer -->
                <div class="flex items-center justify-end p-4 md:p-5 space-x-3 rtl:space-x-reverse border-t">
                    <button type="button" (click)="showAddStateModal = false" 
                            class="px-4 py-2 text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100">
                        Cancel
                    </button>
                    <button type="submit" 
                            (click)="addState()" 
                            [disabled]="selectedStates.length === 0 || loading"
                            class="px-4 py-2 text-white bg-[#eb7641] rounded-lg hover:bg-[#d66937] disabled:opacity-50">
                        <ng-container *ngIf="loading; else addStateText">
                            <app-spinner></app-spinner>
                        </ng-container>
                        <ng-template #addStateText>
                            Add States
                        </ng-template>
                    </button>
                </div>
            </div>
        </div>
      </div>
     
    </div>
  