import { Component, OnInit, ViewChild } from '@angular/core';
import { CampaignService } from 'src/app/shared/service/campaign/campaign.service';
import { ToastrService } from 'ngx-toastr';
import { animate, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/navbar/nav.service';
import { GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'app-admin-campaign-details',
  templateUrl: './admin-campaign-details.component.html',
  styleUrls: ['./admin-campaign-details.component.scss'],
  animations: [
    trigger('fadeSlide', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('0.3s ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ])
  ]
})
export class AdminCampaignDetailsComponent implements OnInit {
  loading: boolean = false;
  searchTerm: string = '';
  selectedCampaign: any = null;
  rejectionReason: string = '';
  showRejectionModal: boolean = false;
  campaignId: string = '';
  campaign: any = null;
  campaignStats = {
    total: 0,
    pending: 0,
    active: 0,
    completed: 0,
    rejected: 0
  };

  @ViewChild(GoogleMap) map!: GoogleMap;
  
  // Google Maps properties
  center: google.maps.LatLngLiteral = { lat: 39.8283, lng: -98.5795 }; // Center of USA
  zoom = 4;
  selectedLocationMarkerOptions: google.maps.MarkerOptions = {
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 8,
      fillColor: '#eb7641',
      fillOpacity: 1,
      strokeColor: '#ffffff',
      strokeWeight: 2,
    }
  };
  playedLocationMarkerOptions: google.maps.MarkerOptions = {
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 6,
      fillColor: '#000000',
      fillOpacity: 1,
      strokeColor: '#ffffff',
      strokeWeight: 2,
    }
  };
  mapOptions: google.maps.MapOptions = {
    styles: [
      {
        featureType: 'all',
        elementType: 'all',
        stylers: [{ saturation: -20 }]
      }
    ]
  };

  constructor(
    private campaignService: CampaignService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private navService: NavService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.campaignId = params['id'];
      this.getCampaignDetails();
    });
    this.navService.setTitle('Campaign Details');
    this.navService.setSubtitle(this.campaign.campaignName);
  }

  getCampaignDetails() {
    this.loading = true;
    this.campaignService.getCampaignDetails(this.campaignId).subscribe({
      next: (response) => {
        this.campaign = response.data;
        
        // Center map on the first selected location if available
        if (this.campaign.selectedLocations?.length > 0) {
          this.center = {
            lat: Number(this.campaign.selectedLocations[0].latitude),
            lng: Number(this.campaign.selectedLocations[0].longitude)
          };
          this.zoom = 12; // Closer zoom to show the area better
        }
        
        this.loading = false;
      },
      error: (error) => {
        this.toastr.error('Failed to load campaigns');
        this.loading = false;
      }
    });
  }

  approveCampaign(campaignId: string) {
    this.campaignService.approveCampaign(campaignId).subscribe({
      next: () => {
        this.toastr.success('Campaign approved successfully');
        this.getCampaignDetails();
      },
      error: () => {
        this.toastr.error('Failed to approve campaign');
      }
    });
  }

  openRejectionModal(campaign: any) {
    this.selectedCampaign = campaign;
    this.showRejectionModal = true;
  }

  rejectCampaign() {
    if (!this.rejectionReason.trim()) {
      this.toastr.error('Please provide a rejection reason');
      return;
    }

    this.campaignService.rejectCampaign(
      this.selectedCampaign._id, 
      this.rejectionReason
    ).subscribe({
      next: () => {
        this.toastr.success('Campaign rejected');
        this.closeRejectionModal();
        this.getCampaignDetails();
      },
      error: () => {
        this.toastr.error('Failed to reject campaign');
      }
    });
  }

  closeRejectionModal() {
    this.showRejectionModal = false;
    this.selectedCampaign = null;
    this.rejectionReason = '';
  }

  getStatusClass(status: string): string {
    const classes: { [key: string]: string } = {
      pending: 'bg-yellow-100 text-yellow-800',
      active: 'bg-green-100 text-green-800',
      scheduled: 'bg-blue-100 text-blue-800',
      completed: 'bg-gray-100 text-gray-800',
      rejected: 'bg-red-100 text-red-800'
    };
    return classes[status] || 'bg-gray-100 text-gray-800';
  }

  // Add helper methods for the map
  getPosition(location: any): google.maps.LatLngLiteral {
    return {
      lat: Number(location.latitude),
      lng: Number(location.longitude)
    };
  }

  formatPlayedAt(playedAt: string): string {
    return new Date(playedAt).toLocaleString();
  }
}