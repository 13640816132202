<div id="main" class="w-100 min-h-screen  ">

    <app-web-nav></app-web-nav>

    <main class="max-w-[1420px] mx-auto px-4 py-16 md:py-24">
        <!-- Heading -->
        <h1 class="text-2xl md:text-4xl lg:text-5xl text-[#474452] text-center font-medium mb-16 md:mb-24">
            Choose Your Plan!
        </h1>

        <!-- Pricing Card -->
        <!-- Card Header -->
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-8">
            <div *ngFor="let plan of allPlans"
                class="relative transform hover:scale-105 transition-transform duration-300">
                <div
                    class="bg-gradient-to-br from-white to-gray-50 p-8 rounded-xl shadow-lg border-t-4 border-[#eb7641] hover:shadow-xl">
                    <div class="flex flex-col space-y-6">
                        <div class="flex justify-between items-start">
                            <h3 class="text-3xl font-bold text-[#eb7641]">{{plan.planName}}</h3>
                            <div class="text-right">
                                <span class="text-2xl font-bold text-gray-800">${{plan.price}}</span>
                                <p class="text-sm text-gray-500">per {{plan.duration}} months</p>
                            </div>
                        </div>

                        <p class="text-gray-600 text-lg">{{plan.description}}</p>

                        <div class="grid grid-cols-2 gap-4 mt-4">
                            <div class="bg-white p-4 rounded-lg shadow">
                                <p class="text-sm text-gray-500">Campaign Limit</p>
                                <p class="text-xl font-semibold text-[#eb7641]">{{plan.campaignLimit}}</p>
                            </div>
                            <div class="bg-white p-4 rounded-lg shadow">
                                <p class="text-sm text-gray-500">Location Limit</p>
                                <p class="text-xl font-semibold text-[#eb7641]">{{plan.locationLimit}}</p>
                            </div>
                        </div>

                        <div class="space-y-3">
                            <div class="flex items-center gap-2">
                                <i class="ri-time-line text-[#eb7641]"></i>
                                <span class="text-gray-600">Ad Video Time: {{plan.adVedioTimeLimit}} minutes</span>
                            </div>
                            <div class="flex items-center gap-2">
                                <i class="ri-calendar-line text-[#eb7641]"></i>
                                <span class="text-gray-600">Campaign Duration: {{plan.adCampaignTimeLimit}} days</span>
                            </div>
                            <div class="flex items-center gap-2">
                                <i class="ri-flag-line text-[#eb7641]"></i>
                                <span class="text-gray-600">Priority Level: {{plan.priority}}</span>
                            </div>
                        </div>

                        <button routerLink="/login"
                            class="w-full px-6 py-3 mt-4 text-white bg-[#eb7641] rounded-lg hover:bg-[#d66937] transition-colors duration-300 flex items-center justify-center gap-2">
                            <i class="ri-shopping-cart-line"></i>
                            Get Started
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Social Share Section -->
        <div class="text-center mt-16 md:mt-24">
            <h3 class="text-xl mb-2 text-[#474452]">LIKE,FOLLOW & SHARE the post below</h3>
            <p class="text-gray-600 mb-6">to waive fee</p>

            <!-- Social Icons -->
            <div class="flex justify-center gap-4">
                <a href="#" class="text-4xl text-[#1877f2] hover:opacity-80 transition-opacity">
                    <i class="ri-facebook-circle-fill"></i>
                </a>
                <a href="#" class="text-4xl text-[#e4405f] hover:opacity-80 transition-opacity">
                    <i class="ri-instagram-line"></i>
                </a>
            </div>
        </div>

        <!-- Testimonials Section -->
        <section class="py-20">
            <div class="max-w-[1420px] mx-auto px-4">
                <!-- Animated Header -->
                <div class="text-center mb-16">
                    <span class="text-[#eb7641] text-lg font-medium tracking-wider block mb-3">TESTIMONIALS</span>
                    <h2 class="text-4xl md:text-5xl lg:text-6xl font-bold text-[#474452]">
                        Satisfied Clients
                    </h2>
                    <div class="w-24 h-1 bg-[#eb7641] mx-auto mt-6 rounded-full"></div>
                </div>

                <!-- Loading State -->
                <div *ngIf="isLoading" class="relative">
                    <!-- Skeleton Grid -->
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        <!-- Skeleton Cards -->
                        <div *ngFor="let _ of [1,2,3,4,5,6]" class="bg-white p-8 rounded-lg shadow-lg relative animate-pulse">
                            <!-- Quote Icon Skeleton -->
                            <div class="absolute -top-4 right-8">
                                <div class="w-8 h-8 bg-gray-200 rounded-full"></div>
                            </div>
                            
                            <div class="mb-4">
                                <!-- Avatar and Name Area -->
                                <div class="flex items-center mb-2">
                                    <div class="w-12 h-12 rounded-full bg-gray-200"></div>
                                    <div class="ml-4 flex-1">
                                        <div class="h-4 bg-gray-200 rounded w-24 mb-2"></div>
                                        <div class="h-3 bg-gray-200 rounded w-32"></div>
                                    </div>
                                </div>
                                
                                <!-- Rating Stars Skeleton -->
                                <div class="flex gap-1 mb-2">
                                    <div class="w-4 h-4 bg-gray-200 rounded"></div>
                                    <div class="w-4 h-4 bg-gray-200 rounded"></div>
                                    <div class="w-4 h-4 bg-gray-200 rounded"></div>
                                    <div class="w-4 h-4 bg-gray-200 rounded"></div>
                                    <div class="w-4 h-4 bg-gray-200 rounded"></div>
                                </div>
                            </div>
                            
                            <!-- Comment Text Skeleton -->
                            <div class="space-y-2">
                                <div class="h-3 bg-gray-200 rounded w-full"></div>
                                <div class="h-3 bg-gray-200 rounded w-5/6"></div>
                                <div class="h-3 bg-gray-200 rounded w-4/6"></div>
                            </div>
                        </div>
                    </div>

                    <!-- Skeleton Pagination Dots -->
                    <div class="flex justify-center mt-8 gap-3">
                        <div class="w-4 h-4 rounded-full bg-gray-200"></div>
                        <div class="w-4 h-4 rounded-full bg-gray-200"></div>
                        <div class="w-4 h-4 rounded-full bg-gray-200"></div>
                    </div>
                </div>

                <!-- Reviews Grid with Navigation -->
                <div *ngIf="!isLoading && reviews.length > 0" class="relative">
                    <!-- Navigation Buttons -->
                    <div class="absolute left-[-40px] right-[-40px] top-1/2 -translate-y-1/2 flex justify-between items-center pointer-events-none z-10">
                        <!-- Previous Button -->
                        <div [class.invisible]="currentPage === 1">
                            <button 
                                *ngIf="currentPage > 1"
                                (click)="previousPage()" 
                                class="pointer-events-auto w-12 h-12 flex items-center justify-center rounded-full bg-white shadow-xl hover:shadow-2xl transition-all text-[#eb7641] border-2 border-[#eb7641] hover:bg-[#eb7641] hover:text-white transform hover:scale-110">
                                <i class="ri-arrow-left-s-line text-3xl"></i>
                            </button>
                        </div>
                        
                        <!-- Next Button -->
                        <div [class.ml-auto]="currentPage === 1">
                            <button 
                                *ngIf="currentPage < totalPages"
                                (click)="nextPage()" 
                                class="pointer-events-auto w-12 h-12 flex items-center justify-center rounded-full bg-white shadow-xl hover:shadow-2xl transition-all text-[#eb7641] border-2 border-[#eb7641] hover:bg-[#eb7641] hover:text-white transform hover:scale-110">
                                <i class="ri-arrow-right-s-line text-3xl"></i>
                            </button>
                        </div>
                    </div>

                    <!-- Reviews Grid -->
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 transition-all duration-300">
                        <!-- Review Card -->
                        <div *ngFor="let review of reviews" class="bg-white p-8 rounded-lg shadow-lg relative transform transition-all duration-300 hover:scale-105">
                            <div class="absolute -top-4 right-8">
                                <svg class="w-8 h-8 text-[#eb7641]" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z"/>
                                </svg>
                            </div>
                            <div class="mb-4">
                                <div class="flex items-center mb-2">
                                    <div class="w-12 h-12 rounded-full bg-[#eb7641] flex items-center justify-center text-white text-lg font-semibold">
                                        {{ getInitials(review.name) }}
                                    </div>
                                    <div class="ml-4">
                                        <h4 class="font-semibold">{{ truncateName(review.name) }}</h4>
                                        <p class="text-sm text-gray-600">{{ review.duration }}{{ review.date ? ' • ' + formatDate(review.date) : '' }}</p>
                                    </div>
                                </div>
                                <div class="flex justify-between items-center mb-2">
                                    <div class="flex text-[#eb7641]">
                                        {{ getRatingStars(review.rating) }}
                                    </div>
                                </div>
                            </div>
                            <p class="text-gray-700">"{{ review.comment }}"</p>
                        </div>
                    </div>

                    <!-- Pagination Dots -->
                    <div class="flex justify-center mt-8 gap-3">
                        <button 
                            *ngFor="let _ of [].constructor(totalPages); let i = index"
                            (click)="currentPage = i + 1; loadReviews()"
                            class="w-4 h-4 rounded-full transition-all duration-300 border-2 border-[#eb7641]"
                            [class.bg-[#eb7641]]="currentPage === i + 1"
                            [class.bg-white]="currentPage !== i + 1">
                        </button>
                    </div>
                </div>

                <!-- Empty State -->
                <div *ngIf="!isLoading && reviews.length === 0" class="text-center py-12">
                    <svg class="w-16 h-16 text-gray-400 mx-auto mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M12 12h.01M12 12h.01M12 12h.01M12 12h.01M12 12h.01M12 12h.01M12 12h.01M12 12h.01"></path>
                    </svg>
                    <h3 class="text-lg font-medium text-gray-900 mb-2">No Reviews Yet</h3>
                    <p class="text-gray-500">Be the first to share your experience with our services.</p>
                </div>

                <!-- Review Stats -->
                <div class="grid grid-cols-2 md:grid-cols-4 gap-8 mt-16 text-center">
                    <div *ngFor="let stat of stats" class="p-6 bg-white rounded-lg shadow-lg">
                        <h3 class="text-[#eb7641] text-4xl font-bold mb-2">{{ stat.value }}</h3>
                        <p class="text-gray-600">{{ stat.label }}</p>
                    </div>
                </div>
            </div>
        </section>



    </main>
    <app-web-footer></app-web-footer>

</div>