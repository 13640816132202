<app-sidebar [isSidebarOpen]="isSidebarOpen"></app-sidebar>

<div class="page-body bg-gray-100 min-h-screen">
    <div *ngIf="isFilter" id="backdrop" (click)="closeSidebar()"
        class="fixed top-0 left-0 z-30 w-full h-full bg-gray-900 bg-opacity-50 "></div>
    <button (click)="openSidebar()" type="button"
        class="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-[#eb764121] focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
        <span class="sr-only">Open sidebar</span>
        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path clip-rule="evenodd" fill-rule="evenodd"
                d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z">
            </path>
        </svg>
    </button>
    <app-upgrade-notification></app-upgrade-notification>
<div class="px-5 md:px-10 py-5 md:ml-64">
    <app-page-header></app-page-header>
    <router-outlet #outlet="outlet"></router-outlet>
    </div>
</div>
